import { useEffect, useState } from "react";
import { Modal, Form } from "antd";
import { MaskedInput } from "antd-mask-input";

import useFormErrors from "hooks/useFormErrors";
import { useAuth } from "context/providers/auth";

const CreateUserPinModal = ({ is_open, handleClose }) => {
  const [is_loading, setLoading] = useState(false);
  const { updatePin } = useAuth();

  const [form] = Form.useForm();

  const { pristine, has_error, onFieldsChange } =
    useFormErrors();

  const handleOk = () => form.submit();

  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line
  }, [is_open]);

  return (
    <Modal
      maskClosable={false}
      title="Zmień pin"
      open={is_open}
      onOk={handleOk}
      onCancel={handleClose}
      cancelText="Anuluj"
      okText="Zmień"
      okButtonProps={{
        disabled: pristine || has_error,
        loading: is_loading,
      }}
    >
      <Form
        form={form}
        layout="vertical"
        onFieldsChange={onFieldsChange}
        initialValues={{
          old_pin: "",
          pin: "",
        }}
        onFinish={async ({ old_pin, pin }) => {
          try {
            setLoading(true);
            await updatePin({ old_pin, pin });
            handleClose();
          } catch (error) {
          } finally {
            setLoading(false);
          }
        }}
      >
        <Form.Item
          label="Stary pin"
          name="old_pin"
          required
          hasFeedback
          rules={[
            () => ({
              validator(_, value) {
                if (!value) {
                  return Promise.reject(
                    new Error("Pole wymagane")
                  );
                }
                if (value.includes("_")) {
                  return Promise.reject(
                    new Error("Niepoprawy pin")
                  );
                }

                return Promise.resolve();
              },
            }),
          ]}
        >
          <MaskedInput mask="000000" />
        </Form.Item>
        <Form.Item
          label="Nowy pin"
          name="pin"
          required
          hasFeedback
          rules={[
            () => ({
              validator(_, value) {
                if (!value) {
                  return Promise.reject(
                    new Error("Pole wymagane")
                  );
                }

                if (value.includes("_")) {
                  return Promise.reject(
                    new Error("Niepoprawy pin")
                  );
                }

                return Promise.resolve();
              },
            }),
          ]}
        >
          <MaskedInput mask="000000" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateUserPinModal;
