import { Card, List } from "antd";

import Item from "./components/Item";

const WorkerPasswordResets = ({ data, refetch, refs }) => {
  return (
    <Card
      style={{ marginTop: 24 }}
      title="Prośby o reset hasła"
      bordered={false}
      bodyStyle={{
        maxHeight: 372,
        overflowY: "auto",
      }}
    >
      <List
        dataSource={data}
        renderItem={(props) => (
          <Item {...props} refetch={refetch} refs={refs} />
        )}
      />
    </Card>
  );
};

export default WorkerPasswordResets;
