import React from "react";
import {
  Card,
  Col,
  Row,
  Form,
  Input,
  Button,
  Space,
  ColorPicker,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";

import MainLayout from "components/MainLayout";

import useGet from "hooks/useGet";
import useUpdate from "hooks/useUpdate";
import useDelete from "hooks/useDelete";
import PageFailure from "components/PageFailure";
import { name_regexp } from "helpers/regexp";
import ModalDelete from "components/ModalDelete";
import { MaskedInput } from "antd-mask-input";

const WorkerInvitationUpdate = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [form] = Form.useForm();

  const path = `/worker_invitations/${id}`;

  const { is_loading, is_error, data } = useGet({
    path,
    query: ["worker_invitations", id],
  });

  const { is_saving, update } = useUpdate({
    path,
    form,
  });

  const { is_deleting, _delete } = useDelete({
    path,
    onSuccess: () => navigate("/worker_invitations"),
  });

  if (is_error) {
    return (
      <PageFailure
        title="Nie znaleziono zaproszenia"
        button_text="Wróć do listy"
        path="/worker_invitations"
      />
    );
  }
  return (
    <MainLayout
      back_href="/worker_invitations"
      breadcrumbs={[
        {
          name: "Zaproszenia",
          path: "/worker_invitations",
        },
        { name: data?.email || "Trwa pobieranie..." },
      ]}
    >
      <Row>
        <Col xs={24} md={24} lg={12} xl={8}>
          <Card
            title="Edytuj zaproszenie"
            loading={is_loading}
          >
            <Form
              form={form}
              layout="vertical"
              preserve={true}
              initialValues={{
                first_name: data?.first_name || "",
                last_name: data?.last_name || "",
                email: data?.email || "",
                phone: data?.phone || "",
                color: data?.color || "",
              }}
              onFinish={(values) => update(values)}
            >
              <Form.Item
                label="Imię"
                name="first_name"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Imię jest wymagane!",
                  },
                  {
                    pattern: name_regexp,
                    message:
                      "Pole zawiera niedozwolone znaki!!",
                  },
                ]}
              >
                <Input placeholder="Imię" maxLength={60} />
              </Form.Item>
              <Form.Item
                label="Nazwisko"
                name="last_name"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Nazwisko jest wymagane!",
                  },
                  {
                    pattern: name_regexp,
                    message:
                      "Pole zawiera niedozwolone znaki!!",
                  },
                ]}
              >
                <Input
                  placeholder="Nazwisko"
                  maxLength={60}
                />
              </Form.Item>
              <Form.Item
                label="E-mail"
                name="email"
                hasFeedback
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "E-mail jest niepoprawny!",
                  },
                ]}
              >
                <Input
                  placeholder="E-mail"
                  maxLength={50}
                />
              </Form.Item>
              <Form.Item
                label="Telefon"
                name="phone"
                required
                hasFeedback
                rules={[
                  () => ({
                    validator(_, value) {
                      if (!value) {
                        return Promise.reject(
                          new Error(
                            "Numer telefonu wymagany!"
                          )
                        );
                      }
                      if (value.includes("_")) {
                        return Promise.reject(
                          new Error(
                            "Numer telefonu niepoprawny!"
                          )
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <MaskedInput mask="000000000" />
              </Form.Item>
              <Form.Item name="color" label="Wybierz kolor">
                <ColorPicker
                  onChangeComplete={(value) =>
                    form.setFieldValue(
                      "color",
                      value.toHexString()
                    )
                  }
                />
              </Form.Item>
              <Row justify="end">
                <Space>
                  <ModalDelete
                    loading={is_deleting}
                    text="zaproszenie pracownika"
                    handleDelete={_delete}
                  />
                  <Button
                    loading={
                      is_deleting || is_loading || is_saving
                    }
                    type="primary"
                    htmlType="submit"
                  >
                    Zapisz
                  </Button>
                </Space>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </MainLayout>
  );
};

export default WorkerInvitationUpdate;
