const handleFormError = (errors = []) => {
  if (errors?.length) {
    return errors.map(({ field, message }) => ({
      name: field,
      errors: [message],
    }));
  }

  return [];
};

export default handleFormError;
