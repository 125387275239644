import { Col, Row } from "antd";
import MainLayout from "components/MainLayout";
import SettlementProvider from "context/providers/settlement";
import IssuesList from "./components/IssuesList";
import IssueForm from "./components/IssueForm";

const IssuesSettlement = () => {
  return (
    <SettlementProvider>
      <MainLayout breadcrumbs={[{ name: "Rozliczenia" }]}>
        <Row gutter={[12, 12]} align="top">
          <Col xs={24} md={24} lg={12} xl={12} style={{position: 'sticky', top: 20}}>
            <IssuesList />
          </Col>
          <Col xs={24} md={24} lg={12} xl={12}>
            <IssueForm />
          </Col>
        </Row>
      </MainLayout>
    </SettlementProvider>
  );
};

export default IssuesSettlement;
