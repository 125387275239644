import { Card, Row, Col, List, Select, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import InfiniteScroll from "react-infinite-scroll-component";
import { useEffect } from "react";
import { api } from "helpers/api";
import { useWorkers } from "context/providers/workers";
import { Link } from "react-router-dom";
import {
  getAdministratorBillingStatus,
  getWorkerBillingStatus,
} from "helpers/options/billing_statuses";
import { useSettlement } from "context/providers/settlement";
import { getIssueStatusLabel } from "helpers/options/issue_statuses";

const IssuesList = () => {
  const { workers_list_options } = useWorkers();
  const {
    page,
    worker_id,
    issue_id,
    has_more_issues,
    data,
    is_loading,
    setPage,
    setWorkerId,
    setHasMoreIssues,
    setData,
    setLoading,
    setIssueId,
  } = useSettlement();

  useEffect(() => {
    if (worker_id) {
      loadMoreData(worker_id);
    }
  }, [worker_id]);

  const loadMoreData = async () => {
    if (is_loading) {
      return;
    }
    try {
      setLoading(true);
      const res = await api.get(
        `/issues/settlements?sort=created_at__desc&per_page=20&page=${page}${
          worker_id ? `&worker_id=${worker_id}` : ""
        }`
      );
      setPage(res.meta.next_page || 0);
      setHasMoreIssues(res.meta.next_page > 0);
      setData([...data, ...res.data]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  return (
    <Card
      bodyStyle={{ padding: 0 }}
      headStyle={{ textAlign: "center" }}
      title={
        <Select
          style={{ minWidth: "200px" }}
          size="large"
          placeholder="Wybierz pracownika"
          optionFilterProp="children"
          allowClear
          onChange={(id) => {
            setData([]);
            setPage(1);
            setHasMoreIssues(false);
            setIssueId(undefined);
            setWorkerId(id);
          }}
          options={workers_list_options || []}
        />
      }
    >
      <div className="settlements_list" id="worker_issues">
        <InfiniteScroll
          key={worker_id}
          dataLength={data?.length || 0}
          next={loadMoreData}
          hasMore={has_more_issues}
          loader={
            <div
              style={{ margin: 10, textAlign: "center" }}
            >
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{ fontSize: 48 }}
                    spin
                  />
                }
              />
            </div>
          }
          scrollableTarget="worker_issues"
        >
          <List
            dataSource={data}
            renderItem={({
              id,
              identifier,
              administration_billing_status,
              worker_billing_status,
              status,
            }) => (
              <List.Item
                key={id}
                style={
                  issue_id === id
                    ? {
                        backgroundColor:
                          "rgba(246, 164, 0,.2)",
                      }
                    : {}
                }
              >
                <Link
                  to={`?issue_id=${id}`}
                  className="ant-list-item__link"
                >
                  <Row gutter={[8, 8]} align="middle">
                    <Col flex="2">
                      <strong>{identifier}</strong>
                    </Col>
                    <Col flex="1">
                      {getWorkerBillingStatus(
                        worker_billing_status
                      )}
                    </Col>
                    <Col flex="1">
                      {getAdministratorBillingStatus(
                        administration_billing_status
                      )}
                    </Col>
                    <Col flex="1">
                      {getIssueStatusLabel(status)}
                    </Col>
                  </Row>
                </Link>
              </List.Item>
            )}
          />
        </InfiniteScroll>
      </div>
    </Card>
  );
};

export default IssuesList;
