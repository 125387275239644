import React, { useState } from "react";
import dayjs from "dayjs";
import { Modal, Calendar, theme, Col, Select, Row } from "antd";

import useCreate from "hooks/useCreate";

const MoveTaskModal = ({
  id,
  is_important,
  date: initial_date,
  refetch,
  handleClose,
}) => {
  const { token } = theme.useToken();
  const [date, setDate] = useState(dayjs(initial_date));

  const { is_saving, create } = useCreate({
    path: `/tasks/${id}/move_to_date`,
    success_message: "Przeniesiono pomyślnie",
    onSuccess: () => {
      refetch();
      handleClose();
    },
  });

  const handleOk = () =>
    create({
      date: dayjs(date).format("YYYY-MM-DD"),
    });

  return (
    <Modal
      maskClosable={false}
      width={500}
      title={`Przenieś ${is_important ? "priorytet" : "zadanie poboczne"
        }`}
      open={true}
      onOk={handleOk}
      onCancel={handleClose}
      cancelText="Anuluj"
      okText="Przenieś"
      okButtonProps={{
        disabled: !date,
        loading: is_saving,
      }}
    >
      <div className="m-t-20 m-b-30">
        <div style={{
          width: '100%',
          border: `1px solid ${token.colorBorderSecondary}`,
          borderRadius: token.borderRadiusLG,
        }}>
          <Calendar
            mode="month"
            headerRender={({ value, onChange }) => {
              const start = 0;
              const end = 12;
              const monthOptions = [];
              let current = value.clone();
              const localeData = value.localeData();
              const months = [];
              for (let i = 0; i < 12; i++) {
                current = current.month(i);
                months.push(localeData.months(current));
              }
              for (let i = start; i < end; i++) {
                monthOptions.push(
                  <Select.Option key={i} value={i}>
                    {months[i]}
                  </Select.Option>,
                );
              }
              const year = value.year();
              const month = value.month();
              const options = [];
              for (let i = year - 2; i < year + 10; i += 1) {
                options.push(
                  <Select.Option key={i} value={i}>
                    {i}
                  </Select.Option>,
                );
              }
              return (
                <Row gutter={8} style={{ padding: 8 }} justify="end">
                  <Col>
                    <Select
                      popupMatchSelectWidth={false}
                      value={month}
                      onChange={(newMonth) => {
                        onChange(value.clone().month(newMonth));
                      }}
                    >
                      {monthOptions}
                    </Select>
                  </Col>
                  <Col>
                    <Select
                      popupMatchSelectWidth={false}
                      value={year}
                      onChange={(newYear) => {
                        onChange(value.clone().year(newYear));
                      }}
                    >
                      {options}
                    </Select>
                  </Col>

                </Row>
              );
            }}
            value={date}
            fullscreen={false}
            onSelect={(val) => setDate(val)} />
        </div>

      </div>
    </Modal>
  );
};

export default MoveTaskModal;
