import React, { useState } from "react";
import {
  Card,
  Col,
  Row,
  Form,
  Input,
  Button,
  Space,
  Select,
  Switch,
  Modal,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";

import MainLayout from "components/MainLayout";

import useGet from "hooks/useGet";
import useUpdate from "hooks/useUpdate";
import useDelete from "hooks/useDelete";
import PageFailure from "components/PageFailure";
import ModalDelete from "components/ModalDelete";
import { useWorkers } from "context/providers/workers";
import useFormErrors from "hooks/useFormErrors";
import note_kinds from "helpers/options/note_kinds";
import dayjs from "dayjs";
import InputDate from "components/InputDate";
import { special_characters_regexp } from "helpers/regexp";
import disabled_note_datetime from "helpers/disabled_note_datetime";

const NoteUpdate = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user_list_options } = useWorkers();

  const [is_open_confirm_modal, setOpenConfirmModal] =
    useState(false);

  const [form] = Form.useForm();

  const path = `/notes/${id}`;

  const { is_loading, is_error, data } = useGet({
    path,
    query: ["notes", id],
  });

  const {
    pristine,
    has_error,
    onFieldsChange,
    afterSubmit,
  } = useFormErrors(data, form);

  const { is_saving, update } = useUpdate({
    path,
    form,
    onSuccess: afterSubmit,
  });

  const { is_deleting, _delete } = useDelete({
    path,
    onSuccess: () => navigate("/notes"),
  });

  if (is_error) {
    return (
      <PageFailure
        title="Nie znaleziono notatki"
        button_text="Wróć do listy"
        path="/notes"
      />
    );
  }
  return (
    <MainLayout
      back_href="/notes"
      breadcrumbs={[
        { name: "Notatki", path: "/notes" },
        { name: data?.id || "Trwa pobieranie..." },
      ]}
    >
      <Modal
        open={is_open_confirm_modal}
        onCancel={() => {
          form.setFieldValue("is_confirmed", false);
          setOpenConfirmModal(false);
        }}
        okText="Tak"
        cancelText="Nie"
        onOk={() => {
          form.setFieldValue("is_confirmed", true);
          setOpenConfirmModal(false);
        }}
      >
        Czy chcesz potwierdzić urlop?
      </Modal>
      <Row>
        <Col xs={24} md={24} lg={12} xl={8}>
          <Card title="Edytuj notatkę" loading={is_loading}>
            <Form
              form={form}
              layout="vertical"
              onFieldsChange={onFieldsChange}
              initialValues={{
                content: data?.content,
                kind: data?.kind,
                date_start: data?.date_start
                  ? dayjs(data.date_start)
                  : null,
                date_end: data?.date_end
                  ? dayjs(data.date_end)
                  : null,
                user_id: data?.user_id,
                is_confirmed: data?.is_confirmed,
              }}
              onFinish={(values) => update(values)}
            >
              {({ kind, is_confirmed }) => (
                <>
                  <Form.Item
                    label="Rodzaj"
                    name="kind"
                    hasFeedback
                  >
                    <Select
                      placeholder="Rodzaj"
                      options={note_kinds}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Pracownik"
                    name="user_id"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "Pracownik jest wymagany!",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Rodzaj"
                      options={user_list_options}
                      defaultValue={user_list_options?.find(
                        (id) => id === data?.user_id
                      )}
                    />
                  </Form.Item>
                  {kind === "holiday" ? (
                    <Row gutter={[12, 12]}>
                      <Col xs={24} md={24} lg={12} xl={12}>
                        <Form.Item
                          label="Data rozpoczęcia"
                          name="date_start"
                          rules={[
                            {
                              required: true,
                              message:
                                "Data rozpoczęcia jest wymagana!",
                            },
                          ]}
                        >
                          <InputDate
                            format="DD-MM-YYYY HH:mm"
                            disabledTime={
                              disabled_note_datetime
                            }
                            showTime={{
                              hideDisabledOptions: true,
                              format: "HH:mm",
                              defaultValue: dayjs(
                                "08:00",
                                "HH:mm"
                              ),
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={24} lg={12} xl={12}>
                        <Form.Item
                          label="Data zakończenia"
                          name="date_end"
                          rules={[
                            {
                              required: true,
                              message:
                                "Data zakończenia jest wymagana!",
                            },
                            {
                              validator: (
                                _,
                                value,
                                callback
                              ) => {
                                const date_start =
                                  form.getFieldValue(
                                    "date_start"
                                  );
                                const date_end = value;
                                date_start &&
                                date_end &&
                                date_end - date_start < 0
                                  ? callback(
                                      "Data zakończenia nie może być mniejsza niż data rozpoczecia!"
                                    )
                                  : callback();
                              },
                            },
                          ]}
                        >
                          <InputDate
                            format="DD-MM-YYYY HH:mm"
                            disabledDate={(current) =>
                              current &&
                              current <
                                form.getFieldValue(
                                  "date_start"
                                )
                            }
                            disabledTime={
                              disabled_note_datetime
                            }
                            showTime={{
                              hideDisabledOptions: true,
                              format: "HH:mm",
                              defaultValue: dayjs(
                                "16:00",
                                "HH:mm"
                              ),
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={24} lg={12} xl={12}>
                        <Form.Item
                          label="Potwierdź urlop"
                          name="is_confirmed"
                        >
                          <Switch
                            disabled={is_confirmed}
                            checked={is_confirmed}
                            checkedChildren="Tak"
                            unCheckedChildren="Nie"
                            onChange={() =>
                              setOpenConfirmModal(true)
                            }
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  ) : (
                    <Form.Item
                      label="Treść"
                      name="content"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: "Treść jest wymagana!",
                        },
                        {
                          pattern:
                            special_characters_regexp,
                          message:
                            "Pole zawiera niedozwolone znaki!!",
                        },
                      ]}
                    >
                      <Input.TextArea
                        style={{ marginBottom: 10 }}
                        placeholder="Treść"
                        maxLength={400}
                        showCount={true}
                        rows={7}
                      />
                    </Form.Item>
                  )}
                  <Row justify="end">
                    <Space>
                      <ModalDelete
                        loading={is_deleting}
                        text="notatkę"
                        handleDelete={_delete}
                      />
                      <Button
                        disabled={pristine || has_error}
                        loading={
                          is_deleting ||
                          is_loading ||
                          is_saving
                        }
                        type="primary"
                        htmlType="submit"
                      >
                        Zapisz
                      </Button>
                    </Space>
                  </Row>
                </>
              )}
            </Form>
          </Card>
        </Col>
      </Row>
    </MainLayout>
  );
};

export default NoteUpdate;
