import { useState } from "react";
import { Button, List } from "antd";
import { EditOutlined } from "@ant-design/icons";
import useDelete from "hooks/useDelete";
import EditCommentModal from "../EditCommentModal";
import ModalDelete from "components/ModalDelete";
import dayjs from "dayjs";
import EllipsisText from "components/EllipsisText";

const Item = ({
  id,
  user,
  content,
  created_at,
  refetch,
}) => {
  const [
    is_open_edit_comment_modal,
    setIsOpenEditCommentModal,
  ] = useState(false);

  const { is_deleting, _delete } = useDelete({
    path: `/issue_comments/${id}`,
    onSuccess: refetch,
  });

  return (
    <List.Item
      actions={[
        <Button
          onClick={() => setIsOpenEditCommentModal(true)}
          type="primary"
          ghost
          icon={<EditOutlined />}
        />,
        <ModalDelete
          loading={is_deleting}
          only_icon={true}
          text={`komentarz`}
          handleDelete={_delete}
        />,
      ]}
    >
      <List.Item.Meta
        title={<EllipsisText text={content} limit={400} />}
        description={
          <>
            <EllipsisText
              text={user?.full_name}
              limit={30}
            />{" "}
            - {dayjs(created_at).format("DD-MM-YYYY HH:mm")}
          </>
        }
      />
      {is_open_edit_comment_modal && (
        <EditCommentModal
          refetch={refetch}
          issue_comment_id={id}
          default_content={content}
          handleClose={() =>
            setIsOpenEditCommentModal(false)
          }
        />
      )}
    </List.Item>
  );
};

export default Item;
