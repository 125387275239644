const issue_kinds = [
  { label: "Antena zbiorcza", value: "antenna" },
  { label: "Domofon", value: "intercom" },
  { label: "Alarmy", value: "alarms" },
  { label: "Kontrola dostępu", value: "access" },
  { label: "Monitoring", value: "monitoring" },
  { label: "Automatyka bramowa", value: "gate_automation" },
];

export const filter_issue_kinds = [
  { label: "Antena zbiorcza", value: 0 },
  { label: "Domofon", value: 1 },
  { label: "Alarmy", value: 2 },
  { label: "Kontrola dostępu", value: 3 },
  { label: "Monitoring", value: 4 },
  { label: "Automatyka bramowa", value: 5 },
];

export const getIssueKind = (val) =>
  issue_kinds.find(({ value }) => value === val)?.label ||
  "-";

export default issue_kinds;
