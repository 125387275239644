import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import { useState } from "react";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

import service_date_delays from "helpers/options/service_date_delays";
import range from "helpers/range";
import useUpdate from "hooks/useUpdate";
import InputDate from "components/InputDate";
import { useWorkers } from "context/providers/workers";
import { useIssueCalendar } from "../../../../issue_calendar_context";

const disabledDateTime = () => ({
  disabledMinutes: () => [
    ...range(1, 15),
    ...range(16, 30),
    ...range(31, 45),
    ...range(46, 60),
  ],
});

const CustomEditor = ({
  issue_id,
  issue_data,
  handleConfirm,
  scheduler: { state, edited, close },
}) => {
  const { workers_list_options } = useWorkers();
  const { calendar_worker_id } = useIssueCalendar();
  const navigate = useNavigate();
  const [worker_id, setWorkerId] = useState(
    edited?.worker?.id || calendar_worker_id
  );
  const { is_saving, update } = useUpdate({
    path: `/issues/${
      edited?.event_id || issue_id
    }/set_service_date`,
    onSuccess: () => {
      handleConfirm();
      navigate("/issues_manage");
    },
  });

  const handleSubmit = async (values) => {
    const service_date = dayjs(
      values.service_date
    ).format();
    update({
      worker_id,
      service_date,
      service_date_delay: values.service_date_delay,
    });
  };

  return (
    <Form
      id="day_modal"
      layout="vertical"
      initialValues={{
        service_date: dayjs(state.start?.value),
        service_date_delay: edited?.service_date_delay || 0,
      }}
      onFinish={handleSubmit}
    >
      <Card
        title={
          edited?.identifier
            ? "Edytuj zgłoszenie"
            : "Dodaj zgłoszenie do kalendarza"
        }
        width={1000}
      >
        <Space
          direction="vertical"
          style={{ marginBottom: 15, width: "100%" }}
        >
          <Alert
            style={{ marginBottom: 10 }}
            message={
              <>
                <p className="m-0">
                  Ulica:{" "}
                  <strong>
                    {(edited || issue_data)?.client_street}{" "}
                    {
                      (edited || issue_data)
                        ?.client_building_number
                    }
                    /
                    {
                      (edited || issue_data)
                        ?.client_apartment_number
                    }
                  </strong>
                </p>
                <p className="m-0">
                  Miejscowość:{" "}
                  <strong>
                    {(edited || issue_data)?.client_city}
                  </strong>
                </p>
                <p className="m-0">
                  Data utworzenia zgłoszenia:{" "}
                  <strong>
                    {" "}
                    {(edited || issue_data)?.created_at
                      ? dayjs(
                          (edited || issue_data).created_at
                        ).format("DD-MM-YYYY HH:mm")
                      : ""}
                  </strong>
                </p>
              </>
            }
          />
        </Space>

        <Row gutter={[12, 12]}>
          <Col xs={24} md={24} lg={12} xl={12}>
            <Form.Item
              label="Data umówienia"
              name="service_date"
            >
              <InputDate
                format="DD-MM-YYYY HH:mm"
                showTime={{ format: "HH:mm" }}
                disabledTime={disabledDateTime}
                getPopupContainer={() =>
                  document.querySelector("#day_modal")
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={24} lg={12} xl={12}>
            <Form.Item
              label="Data umówienia (opóźnienie)"
              name="service_date_delay"
            >
              <Select
                placeholder="Data umówienia (opóźnienie)"
                options={service_date_delays}
                defaultValue={service_date_delays[0]}
                getPopupContainer={() =>
                  document.querySelector("#day_modal")
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[12, 12]}>
          <Col xs={24} md={24} lg={12} xl={12}>
            <Typography className="m-b-5">
              <Typography.Text>Pracownicy</Typography.Text>
            </Typography>
            <Select
              style={{ width: "100%" }}
              value={worker_id}
              getPopupContainer={() =>
                document.querySelector("#day_modal")
              }
              placeholder="Wybierz pracownika"
              allowClear
              filterOption={false}
              onChange={(id) => setWorkerId(id || null)}
              options={workers_list_options || []}
            />
          </Col>
        </Row>
        <Row justify="end">
          <Space>
            <Button onClick={close}>Anuluj</Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={is_saving}
            >
              Zapisz
            </Button>
          </Space>
        </Row>
      </Card>
    </Form>
  );
};

export default CustomEditor;
