import React, { useState } from "react";
import { Modal, Input } from "antd";

import useCreate from "hooks/useCreate";

const AddCommentModal = ({
  handleClose,
  refetch,
  issue_id,
}) => {
  const [content, setContent] = useState("");

  const { is_saving, create } = useCreate({
    path: "/issue_comments",
    onSuccess: refetch,
  });

  const handleOk = async () => {
    try {
      await create({
        issue_id,
        content,
      });
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      maskClosable={false}
      width={700}
      title="Dodaj komentarz"
      open={true}
      onOk={handleOk}
      onCancel={handleClose}
      cancelText="Anuluj"
      okText="Dodaj"
      okButtonProps={{
        disabled: !content || is_saving,
        loading: is_saving,
      }}
    >
      <Input.TextArea
        value={content}
        style={{ marginBottom: 20 }}
        onChange={({ target: { value } }) =>
          setContent(value)
        }
        rows={7}
        maxLength={400}
        showCount={true}
      />
    </Modal>
  );
};

export default AddCommentModal;
