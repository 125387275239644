import IssueDayCalendar from "./components/IssueDayCalendar";
import IssueMonthCalendar from "./components/IssueMonthCalendar";

const IssueCalendar = () => (
  <>
    <IssueMonthCalendar />
    <IssueDayCalendar />
  </>
);

export default IssueCalendar;
