import { Link } from "react-router-dom";
import EllipsisText from "components/EllipsisText";

const columns = [
  {
    title: "IMEI",
    dataIndex: "imei",
    sortable: true,
    searchable: true,
    search_method: "matches",
    render: (_, { id, imei }) => (
      <Link to={`/devices/${id}`}>
        <EllipsisText text={imei} limit={30} />
      </Link>
    ),
  },
  {
    title: "Nazwa",
    dataIndex: "name",
    sortable: true,
    searchable: true,
    search_method: "matches",
    render: (_, { id, name }) => (
      <Link to={`/devices/${id}`}>
        <EllipsisText text={name} limit={30} />
      </Link>
    ),
  },
];

export default columns;
