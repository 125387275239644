import "./styles/app.scss";

import { BrowserRouter } from "react-router-dom";
import {
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import dayjs from "dayjs";
import "dayjs/locale/pl";

import AuthProvider from "context/providers/auth";
import SettingsProvider from "context/providers/settings";

dayjs.locale("pl");
const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <QueryParamProvider adapter={ReactRouter6Adapter}>
          <SettingsProvider>
            <AuthProvider />
          </SettingsProvider>
        </QueryParamProvider>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
