export const first_name_last_name_regexp = new RegExp(
  /^[0-9A-Za-zĄĆĘŁŃÓŚŹŻąćęłńóśźż -]+$/
);

export const name_regexp = new RegExp(
  /^[A-Za-zĄĆĘŁŃÓŚŹŻąćęłńóśźż -]+$/
);

export const street_regexp = new RegExp(
  /^[0-9A-Za-zĄĆĘŁŃÓŚŹŻąćęłńóśźż .'-]+$/
);

export const building_number_regexp = new RegExp(
  // eslint-disable-next-line
  /^[0-9-A-Za-z\/-]+$/
);

export const special_characters_regexp = new RegExp(
  /^[^<>&'"~]*$/
);

export const ip_regexp = new RegExp(
  /(?:[0-9]{1,3}\.){3}[0-9]{1,3}/
);

export const post_code_regexp = new RegExp(/^\d{2}-\d{3}$/);
export const imei_regexp = new RegExp(/^[a-zA-Z0-9_-]+$/);

export const nip_regexp = new RegExp(/^\d{10}$/)