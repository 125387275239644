import { Badge } from "antd";

export const getTaskStatusColor = (status_color) => {
  switch (status_color) {
    case "black":
      return "#000000";
    case "red":
      return "#ff0000";
    case "orange":
      return "#ffae00";
    case "blue":
      return "#0089ff";
    default:
      return "#ffffff";
  }
};

export const task_status_color_options = [
  {
    label: (
      <>
        <Badge
          count={1}
          style={{
            backgroundColor: getTaskStatusColor("red"),
          }}
        />
        <span> - czerwony</span>
      </>
    ),
    value: "red",
  },
  {
    label: (
      <>
        <Badge
          count={2}
          style={{
            backgroundColor: getTaskStatusColor("orange"),
          }}
        />
        <span> - pomarańczowy</span>
      </>
    ),
    value: "orange",
  },
  {
    label: (
      <>
        <Badge
          count={3}
          style={{
            backgroundColor: getTaskStatusColor("blue"),
          }}
        />
        <span> - niebieski</span>
      </>
    ),
    value: "blue",
  },
  {
    label: (
      <>
        <Badge
          count={4}
          style={{
            backgroundColor: getTaskStatusColor("black"),
          }}
        />
        <span> - czarny</span>
      </>
    ),
    value: "black",
  },
];
