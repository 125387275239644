import React from "react";

import MainLayout from "components/MainLayout";
import MainList from "components/MainList";

import columns from "./columns";
import CreateClientModal from "components/create_modals/CreateClientModal";

const ClientsList = () => (
  <MainLayout
    breadcrumbs={[{ name: "Klienci" }]}
    buttons={<CreateClientModal />}
  >
    <MainList
      columns={columns}
      query_name="clients"
      query_path="/clients"
      table_path="/clients"
      query_context="index"
    />
  </MainLayout>
);
export default ClientsList;
