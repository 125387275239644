import { Tooltip } from "antd";

const EllipsisText = ({ text, limit = 100 }) => {
  if (!text) return "";
  const max_length_of_single_word = Math.max(
    ...text.split(" ").map((el) => el.length)
  );
  if (max_length_of_single_word > 60) {
    const short_text = text.slice(0, 40);
    const long_text = text.slice(0, 100);
    return (
      <Tooltip className="text-ellipsis" placement="topLeft" title={long_text}>
        {short_text}...
      </Tooltip>
    );
  }
  if (text.length > limit) {
    const short_text = text.slice(0, limit);
    const long_text = text.slice(0, 200);
    return (
      <Tooltip className="text-ellipsis" placement="topLeft" title={long_text}>
        {short_text}...
      </Tooltip>
    );
  }
  return text;
};

export default EllipsisText;
