const getIssueColorByStatus = (status) => {
  switch (status) {
    case "new":
      return "#0958d9";
    case "in_progress":
      return "#ffcc00";
    case "completed":
      return "#339900";
    case "cancelled":
      return "#cc3300";
    default:
      return "#0958d9";
  }
};

export default getIssueColorByStatus