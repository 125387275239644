import { DatePicker } from "antd";
import locale from "antd/es/date-picker/locale/pl_PL";

const ListDatePicker = ({ title, value, onChange }) => (
  <DatePicker
    locale={locale}
    inputReadOnly
    placeholder={title}
    format="DD-MM-YYYY"
    value={value}
    onChange={onChange}
    style={{
      display: "block",
    }}
  />
);

export default ListDatePicker;
