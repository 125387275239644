import React from "react";
import { Tag } from "antd";

const issue_statuses = [
  { label: <Tag color="blue">Nowe</Tag>, value: "new" },
  {
    label: <Tag color="orange">W trakcie</Tag>,
    value: "in_progress",
  },
  {
    label: <Tag color="green">Zakończone</Tag>,
    value: "completed",
  },
  {
    label: <Tag color="red">Anulowane</Tag>,
    value: "cancelled",
  },
];

export const filter_issue_statuses = [
  { label: "Nowe", value: 0 },
  { label: "W trakcie", value: 1 },
  { label: "Zakończone", value: 2 },
  { label: "Anulowane", value: 3 },
];

export const getIssueStatus = (val) =>
  issue_statuses.find(({ value }) => value === val)
    ?.label || "-";

export const getIssueStatusLabel = (val) => {
  switch (val) {
    case "new":
      return <Tag color="blue">Nowe</Tag>;
    case "in_progress":
      return <Tag color="orange">W trakcie</Tag>;
    case "completed":
      return <Tag color="green">Zakończone</Tag>;
    case "cancelled":
      return <Tag color="red">Anlulowane</Tag>;
    default:
      return <Tag>Nieznany status</Tag>;
  }
};

export default issue_statuses;
