import React, { useEffect } from "react";
import {
  Card,
  Col,
  Row,
  Form,
  Input,
  Empty,
  Button,
  Space,
  Select,
  Divider,
  Alert,
  Skeleton,
} from "antd";

import useUpdate from "hooks/useUpdate";
import { MaskedInput } from "antd-mask-input";
import useFormErrors from "hooks/useFormErrors";
import {
  building_number_regexp,
  first_name_last_name_regexp,
  name_regexp,
  nip_regexp,
  special_characters_regexp,
  street_regexp,
} from "helpers/regexp";
import issue_statuses from "helpers/options/issue_statuses";
import issue_kinds from "helpers/options/issue_kinds";
import dayjs from "dayjs";
import InputDate from "components/InputDate";
import service_date_delays from "helpers/options/service_date_delays";
import range from "helpers/range";
import {
  administrator_billing_status_options,
  worker_billing_status_options,
} from "helpers/options/billing_statuses";
import { useSettlement } from "context/providers/settlement";

const disabledDateTime = () => ({
  disabledMinutes: () => [
    ...range(1, 15),
    ...range(16, 30),
    ...range(31, 45),
    ...range(46, 60),
  ],
});

const IssueForm = () => {
  const {
    issue_id,
    data: issues_list,
    is_issue_loading: is_loading,
    is_issue_error,
    issue_data: data,
    refetchIssue,
    updateListIssue,
  } = useSettlement();

  const [form] = Form.useForm();
  const { pristine, has_error, onFieldsChange } =
    useFormErrors(data);

  const path = `/issues/${issue_id}`;

  const { is_saving, update } = useUpdate({
    path,
    form,
    onSuccess: refetchIssue,
  });

  const is_changed = !pristine;

  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line
  }, [data]);

  if (!issue_id || is_issue_error || !issues_list.length) {
    return (
      <Card>
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="Brak danych"
        />
      </Card>
    );
  }

  if (is_loading) {
    return (
      <Card title="Trwa pobieranie...">
        <Skeleton active />
      </Card>
    );
  }

  return (
    <Form
      form={form}
      layout="vertical"
      onFieldsChange={onFieldsChange}
      initialValues={{
        created_by: data?.created_by,
        status: data?.status,
        kind: data?.kind,
        service_date: data?.service_date
          ? dayjs(data.service_date)
          : null,
        service_date_delay: data?.service_date_delay,
        content: data?.content,
        client_first_name: data?.client_first_name,
        client_last_name: data?.client_last_name,
        client_street: data?.client_street,
        client_building_number:
          data?.client_building_number,
        client_apartment_number:
          data?.client_apartment_number,
        client_city: data?.client_city,
        client_post_code: data?.client_post_code,
        client_phone: data?.client_phone,
        client_email: data?.client_email,
        client_nip: data?.client_nip,
        order_number: data?.order_number,
        order_date: data?.order_date
          ? dayjs(data.order_date)
          : null,
        fv_number: data?.fv_number,
        fv_date: data?.fv_date ? dayjs(data.fv_date) : null,
        annotation: data?.annotation,
        administration_reported_at:
          data?.administration_reported_at
            ? dayjs(data.administration_reported_at)
            : null,
        worker_billing_status: data?.worker_billing_status,
        administration_billing_status:
          data?.administration_billing_status,
      }}
      onFinish={async (values) => {
        update({ ...values });
        updateListIssue(values);
      }}
    >
      <Card
        title={data?.identifier}
        loading={is_loading}
        extra={
          <Button
            disabled={has_error || !is_changed}
            loading={is_loading || is_saving}
            type="primary"
            htmlType="submit"
          >
            Zapisz
          </Button>
        }
      >
        {is_changed && (
          <Alert
            message="UWAGA! Zapisz zmiany przed wyjściem!"
            banner
          />
        )}
        <Divider
          orientation="center"
          style={{ margin: "30px 0 40px" }}
        >
          Stan rozliczenia
        </Divider>
        <Row gutter={[12, 12]}>
          <Col xs={24} md={24} lg={12} xl={12}>
            <Form.Item
              label="Pracownik"
              name="worker_billing_status"
              hasFeedback
            >
              <Select
                placeholder="Wybierz stan"
                options={worker_billing_status_options}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={24} lg={12} xl={12}>
            <Form.Item
              label="Administrator"
              name="administration_billing_status"
              hasFeedback
            >
              <Select
                placeholder="Wybierz stan"
                options={
                  administrator_billing_status_options
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Divider
          orientation="center"
          style={{ margin: "30px 0 40px" }}
        >
          Informacje o zleceniu
        </Divider>
        <Row gutter={[12, 12]}>
          <Col xs={24} md={24} lg={12} xl={12}>
            <Form.Item
              label="Adnotacja"
              name="annotation"
              hasFeedback
              rules={[
                {
                  pattern: special_characters_regexp,
                  message:
                    "Pole zawiera niedozwolone znaki!!",
                },
              ]}
            >
              <Input
                placeholder="Adnotacja"
                maxLength={128}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={24} lg={12} xl={12}>
            <Form.Item
              label="Data zgłoszenia do administracji"
              name="administration_reported_at"
            >
              <InputDate />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[12, 12]}>
          <Col xs={24} md={24} lg={12} xl={12}>
            <Form.Item
              label="Numer zlecenia"
              name="order_number"
              hasFeedback
              rules={[
                {
                  pattern: special_characters_regexp,
                  message:
                    "Pole zawiera niedozwolone znaki!!",
                },
              ]}
            >
              <Input
                placeholder="Numer zlecenia"
                maxLength={128}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={24} lg={12} xl={12}>
            <Form.Item
              label="Data wystawionego zlecenia"
              name="order_date"
            >
              <InputDate />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[12, 12]}>
          <Col xs={24} md={24} lg={12} xl={12}>
            <Form.Item
              label="Numer FV"
              name="fv_number"
              hasFeedback
              rules={[
                {
                  pattern: special_characters_regexp,
                  message:
                    "Pole zawiera niedozwolone znaki!!",
                },
              ]}
            >
              <Input
                placeholder="Numer FV"
                maxLength={128}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={24} lg={12} xl={12}>
            <Form.Item
              label="Data wystawienia FV"
              name="fv_date"
            >
              <InputDate />
            </Form.Item>
          </Col>
        </Row>
        <Divider
          orientation="center"
          style={{ margin: "40px 0" }}
        >
          Informacje o kliencie
        </Divider>
        <Row gutter={[12, 12]}>
          <Col xs={24} md={24} lg={12} xl={12}>
            <Form.Item
              label="Imię"
              name="client_first_name"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Imię jest wymagane!",
                },
                {
                  pattern: first_name_last_name_regexp,
                  message:
                    "Pole zawiera niedozwolone znaki!!",
                },
              ]}
            >
              <Input placeholder="Imię" maxLength={128} />
            </Form.Item>
          </Col>
          <Col xs={24} md={24} lg={12} xl={12}>
            <Form.Item
              label="Nazwisko"
              name="client_last_name"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Nazwisko jest wymagane!",
                },
                {
                  pattern: first_name_last_name_regexp,
                  message:
                    "Pole zawiera niedozwolone znaki!!",
                },
              ]}
            >
              <Input
                placeholder="Nazwisko"
                maxLength={128}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[12, 12]}>
          <Col xs={24} md={24} lg={12} xl={12}>
            <Form.Item
              label="Telefon"
              name="client_phone"
              required
              hasFeedback
              rules={[
                () => ({
                  validator(_, value) {
                    if (!value) {
                      return Promise.reject(
                        new Error(
                          "Numer telefonu wymagany!"
                        )
                      );
                    }
                    if (value.includes("_")) {
                      return Promise.reject(
                        new Error(
                          "Numer telefonu niepoprawny!"
                        )
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <MaskedInput mask="000000000" />
            </Form.Item>
          </Col>
          <Col xs={24} md={24} lg={12} xl={12}>
            <Form.Item
              label="E-mail"
              name="client_email"
              hasFeedback
              rules={[
                {
                  type: "email",
                  message: "Niepoprawny e-mail",
                },
              ]}
            >
              <Input placeholder="E-mail" maxLength={128} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={12}>
            <Form.Item
              label="Ulica"
              name="client_street"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Ulica jest wymagane!",
                },
                {
                  pattern: street_regexp,
                  message:
                    "Pole zawiera niedozwolone znaki!!",
                },
              ]}
            >
              <Input placeholder="Ulica" maxLength={128} />
            </Form.Item>
          </Col>
          <Col xs={6}>
            <Form.Item
              label="Nr bud."
              name="client_building_number"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Numer budynku jest wymagany!",
                },
                {
                  pattern: building_number_regexp,
                  message:
                    "Pole zawiera niedozwolone znaki!!",
                },
              ]}
            >
              <Input placeholder="Nr bud." maxLength={10} />
            </Form.Item>
          </Col>
          <Col xs={6}>
            <Form.Item
              label="Nr miesz."
              name="client_apartment_number"
              hasFeedback
              rules={[
                {
                  required: true,
                  message:
                    "Numer mieszkania jest wymagany, w przypadku domu jednorodzinnego proszę wpisać numer budynku",
                },
                {
                  pattern: building_number_regexp,
                  message:
                    "Pole zawiera niedozwolone znaki!!",
                },
              ]}
            >
              <Input
                placeholder="Nr miesz."
                maxLength={10}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={12}>
            <Form.Item
              label="Miasto"
              name="client_city"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Miasto jest wymagane!",
                },
                {
                  pattern: name_regexp,
                  message:
                    "Pole zawiera niedozwolone znaki!!",
                },
              ]}
            >
              <Input placeholder="Miasto" maxLength={128} />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              label="Kod pocztowy"
              name="client_post_code"
              required
              hasFeedback
              rules={[
                () => ({
                  validator(_, value) {
                    if (!value) {
                      return Promise.reject(
                        new Error("Kod pocztowy wymagany!")
                      );
                    }
                    if (value.includes("_")) {
                      return Promise.reject(
                        new Error(
                          "Kod pocztowy niepoprawny!"
                        )
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <MaskedInput mask="00-000" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={12}>
            <Form.Item
              label="NIP"
              name="client_nip"
              hasFeedback
              rules={[
                {
                  pattern: nip_regexp,
                  message: "Niepoprawny NIP",
                },
              ]}
            >
              <MaskedInput mask="0000000000" />
            </Form.Item>
          </Col>
        </Row>
        <Divider
          orientation="center"
          style={{ margin: "30px 0 40px" }}
        >
          Informacje o zgłoszeniu
        </Divider>
        <Row gutter={[12, 12]}>
          <Col xs={24} md={24} lg={12} xl={12}>
            <Form.Item
              label="Status"
              name="status"
              hasFeedback
            >
              <Select
                placeholder="Status"
                options={issue_statuses}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={24} lg={12} xl={12}>
            <Form.Item
              label="Rodzaj"
              name="kind"
              hasFeedback
            >
              <Select
                placeholder="Rodzaj"
                options={issue_kinds}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[12, 12]}>
          <Col xs={24} md={24} lg={12} xl={12}>
            <Row gutter={[12, 12]} align="middle">
              <Col flex="auto">
                <Form.Item
                  label="Data umówienia"
                  name="service_date"
                >
                  <InputDate
                    format="DD-MM-YYYY HH:mm"
                    showTime={{ format: "HH:mm" }}
                    disabledTime={disabledDateTime}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col xs={24} md={24} lg={12} xl={12}>
            <Form.Item
              label="Data umówienia (opóźnienie)"
              name="service_date_delay"
            >
              <Select
                placeholder="Data umówienia (opóźnienie)"
                options={service_date_delays}
                defaultValue={service_date_delays[0]}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label="Opis zgłoszenia"
          name="content"
          hasFeedback
          rules={[
            {
              required: true,
              message: "Opis zgłoszenia jest wymagany!",
            },
            {
              pattern: special_characters_regexp,
              message: "Pole zawiera niedozwolone znaki!!",
            },
          ]}
        >
          <Input.TextArea
            placeholder="Opis zgłoszenia"
            maxLength={400}
            showCount={true}
            rows={7}
          />
        </Form.Item>

        <Divider />
        <Row justify="end">
          <Space>
            <Button
              disabled={has_error || !is_changed}
              loading={is_loading || is_saving}
              type="primary"
              htmlType="submit"
            >
              Zapisz
            </Button>
          </Space>
        </Row>
      </Card>
    </Form>
  );
};

export default IssueForm;
