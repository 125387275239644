import { Button, Modal, Tooltip } from "antd";
import { useState } from "react";

const ModalConfirm = ({
  button_text,
  button_style = {},
  button_size = "default",
  button_tooltip = null,
  button_type = "primary",
  button_props = {},
  button_icon,
  loading,
  text,
  handleConfirm,
  disabled,
  onOpen,
}) => {
  const [is_open, setOpen] = useState(false);

  const onConfirm = async () => {
    await handleConfirm();
    setOpen(false);
  };

  return (
    <>
      {button_tooltip ? (
        <Tooltip title={button_tooltip}>
          <Button
            icon={button_icon}
            disabled={disabled}
            onClick={() => setOpen(true)}
            loading={loading}
            type={button_type}
            htmlType="button"
            size={button_size}
            style={button_style}
            {...button_props}
          >
            {button_text}
          </Button>
        </Tooltip>
      ) : (
        <Button
          icon={button_icon}
          disabled={disabled}
          size={button_size}
          onClick={() => {
            onOpen?.();
            setOpen(true);
          }}
          loading={loading}
          type={button_type}
          htmlType="button"
          style={button_style}
          {...button_props}
        >
          {button_text}
        </Button>
      )}
      {is_open && (
        <Modal
          title="Potwierdzenie"
          open={is_open}
          onOk={onConfirm}
          onCancel={() => setOpen(false)}
          cancelText="Anuluj"
          okText="Potwierdzam"
        >
          {text}
        </Modal>
      )}
    </>
  );
};

export default ModalConfirm;
