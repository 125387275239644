import React, { useState } from "react";
import { Modal, Input, Typography, Select } from "antd";

import useCreate from "hooks/useCreate";
import { task_status_color_options } from "helpers/getTaskStatusColor";

const AddTask = ({
  is_important,
  date,
  handleClose,
  refetch,
}) => {
  const [content, setContent] = useState("");
  const [status_color, setStatusColor] = useState("red");

  const { is_saving, create } = useCreate({
    path: "/tasks",
    onSuccess: refetch,
  });

  const handleOk = async () => {
    try {
      await create({
        status_color,
        content,
        date,
        is_important,
      });
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      maskClosable={false}
      width={700}
      title={`Dodaj ${
        is_important ? "priorytet" : "zadanie poboczne"
      }`}
      open={true}
      onOk={handleOk}
      onCancel={handleClose}
      cancelText="Anuluj"
      okText="Dodaj"
      okButtonProps={{
        disabled: !content || is_saving,
        loading: is_saving,
      }}
    >
      <Input.TextArea
        value={content}
        style={{ marginBottom: 20 }}
        onChange={({ target: { value } }) =>
          setContent(value)
        }
        rows={7}
        maxLength={400}
        showCount={true}
      />
      <Typography style={{ marginBottom: 5 }}>
        <Typography.Text>Wybierz kolor</Typography.Text>
      </Typography>
      <Select
        options={task_status_color_options}
        value={status_color}
        onChange={(a) => setStatusColor(a)}
        style={{ width: 200 }}
      />
    </Modal>
  );
};

export default AddTask;
