import { useMutation } from "@tanstack/react-query";
import { notification } from "antd";

import { api, handleErrors } from "helpers/api";
import handleFormError from "helpers/handleFormError";

const useCreate = ({ path, onSuccess, form, success_message = "Utworzono pomyślnie" }) => {
  const { isLoading: is_saving, mutateAsync: create } =
    useMutation((values) => api.post(path, values), {
      onSuccess: ({ data }) => {
        notification.success({
          message: success_message,
        });
        onSuccess?.(data);
      },
      onError: (err) => {
        notification.error({ message: handleErrors(err) });
        if (form) {
          const form_errors = handleFormError(
            err?.response?.data?.errors
          );
          form.setFields(form_errors);
        }
      },
    });
  return { is_saving, create };
};

export default useCreate;
