import "./styles.scss";

import React, { useState } from "react";
import {
  LockOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Card,
  Button,
  Form,
  Input,
  Statistic,
  notification,
} from "antd";
import { useAuth } from "context/providers/auth";
import { handleErrors } from "helpers/api";
import dayjs from "dayjs";

const LoginPanel = () => {
  const [account_blocked_to, setAccountBlockedTo] =
    useState(
      localStorage.getItem("account_blocked_to" || null)
    );
  const [is_loading, setLoading] = useState(false);
  const { handleLogin } = useAuth();

  const onCountdownFinish = () => {
    setAccountBlockedTo(null);
    localStorage.removeItem("account_blocked_to");
  };
  const onFinish = async (values) => {
    try {
      if (!!account_blocked_to) {
        return;
      }
      setLoading(true);
      await handleLogin(values);
    } catch (error) {
      if (
        error?.response?.data?.error_type ===
        "account_temporary_blocked"
      ) {
        const { date } = error.response.data;

        localStorage.setItem("account_blocked_to", date);
        setAccountBlockedTo(date);
      }
      notification.error({ message: handleErrors(error) });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-panel">
      <Card title="Logowanie" style={{ width: 540 }}>
        <Form
          name="basic"
          initialValues={{
            email: "",
            password: "",
          }}
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
        >
          <Form.Item
            label="E-mail"
            name="email"
            hasFeedback
            rules={[
              {
                type: "email",
                message: "Niepoprawny e-mail",
              },
              {
                required: true,
                message: "Email jest wymagany!",
              },
              {
                max: 128,
                message:
                  "Przekroczono maksymalną ilość znaków!",
              },
            ]}
          >
            <Input
              prefix={
                <UserOutlined className="site-form-item-icon" />
              }
              placeholder="E-mail"
              maxLength={128}
            />
          </Form.Item>

          <Form.Item
            label="Hasło"
            name="password"
            hasFeedback
            rules={[
              {
                required: true,
                message: "Hasło jest wymagane!",
              },
              {
                max: 128,
                message:
                  "Przekroczono maksymalną ilość znaków!",
              },
            ]}
          >
            <Input.Password
              maxLength={128}
              prefix={
                <LockOutlined className="site-form-item-icon" />
              }
            />
          </Form.Item>

          <Button
            loading={is_loading}
            disabled={is_loading || !!account_blocked_to}
            type="primary"
            htmlType="submit"
          >
            {!!account_blocked_to ? (
              <Statistic.Countdown
                value={dayjs(account_blocked_to)}
                format="mm:ss"
                onFinish={onCountdownFinish}
              />
            ) : (
              "Zaloguj"
            )}
          </Button>
        </Form>
      </Card>
    </div>
  );
};

export default LoginPanel;
