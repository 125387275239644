import { useState } from "react";
import { Button, Card, List } from "antd";
import AddTaskModal from "./components/AddTaskModal";
import Item from "./components/Item";
import { PlusOutlined } from "@ant-design/icons";

const Tasks = ({ is_important, date, data, refetch }) => {
  const [is_open_add_task_modal, setIsOpenAddTaskModal] =
    useState(false);

  const filtered_data = data.filter(
    (item) => item.is_important === is_important
  );

  return (
    <Card
      className="checklist"
      title={`${is_important ? "PRIORYTETY" : "CHECKLISTA"
        } ${filtered_data?.length > 0
          ? `(${filtered_data.length})`
          : ""
        }`}
      style={{
        marginBottom: 24,
      }}
      headStyle={{
        backgroundColor: "rgba(0, 0, 0, 0.1)",
      }}
      bodyStyle={{
        padding: "0 24px",
        maxHeight: 320,
        overflowY: "scroll",
      }}
      extra={
        <Button
          type="primary"
          onClick={() => setIsOpenAddTaskModal(true)}
          icon={<PlusOutlined />}
        />
      }
    >
      {is_open_add_task_modal && (
        <AddTaskModal
          is_important={is_important}
          date={date}
          refetch={refetch}
          handleClose={() => setIsOpenAddTaskModal(false)}
        />
      )}
      <List
        itemLayout="horizontal"
        dataSource={filtered_data}
        renderItem={(item, index) => (
          <Item
            {...item}
            key={item.id}
            refetch={refetch}
            lp={index + 1}
          />
        )}
      />
    </Card>
  );
};

export default Tasks;
