export const SET_LOGGED = "SET_LOGGED";
export const SET_LOADING = "SET_LOADING";
export const SET_USER = "SET_USER";
export const SET_OTP_AUTH_NEEDED = "SET_OTP_AUTH_NEEDED";

export const initialState = {
  is_logged: false,
  is_loading: true,
  otp_auth_needed: false,
  user: null,
};

const authReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case SET_LOGGED: {
      return {
        ...state,
        is_logged: payload.is_logged,
      };
    }
    case SET_LOADING: {
      return {
        ...state,
        is_loading: payload.is_loading,
      };
    }
    case SET_OTP_AUTH_NEEDED: {
      return {
        ...state,
        is_loading: false,
        is_logged: false,
        otp_auth_needed: true,
      };
    }
    case SET_USER: {
      return {
        ...state,
        user: payload.user,
        otp_auth_needed: false,
      };
    }
    default:
      return state;
  }
};

export default authReducer;
