import React, {
  useMemo,
  useContext,
  useState,
  useEffect,
} from "react";
import { useCookies } from "react-cookie";
import { ConfigProvider, theme } from "antd";
import plPL from "antd/locale/pl_PL";

const SettingsContext = React.createContext();

const { defaultAlgorithm, darkAlgorithm } = theme;

function SettingsProvider({ children }) {
  const [{ theme_mode }, setCookie] = useCookies([
    "theme_mode",
  ]);

  const [mode, setMode] = useState(theme_mode || "light");

  const handleSetMode = (new_mode) => {
    setCookie("theme_mode", new_mode);
    setMode(new_mode);
  };

  useEffect(() => {
    document.querySelector(
      "body"
    ).classList = `${mode}-mode`;
  }, [mode]);

  const value = useMemo(() => {
    return { mode, handleSetMode };
    // eslint-disable-next-line
  }, [mode]);

  return (
    <SettingsContext.Provider value={value}>
      <ConfigProvider
        locale={plPL}
        theme={{
          token: {
            colorPrimary: "#f6a400",
            colorText:
              mode === "dark" ? "#ffffff" : "#000000",
          },
          algorithm:
            mode === "dark"
              ? darkAlgorithm
              : defaultAlgorithm,
        }}
      >
        {children}
      </ConfigProvider>
    </SettingsContext.Provider>
  );
}

const useSettings = () => useContext(SettingsContext);
export { useSettings };
export default SettingsProvider;
