import { Button, List, Tag } from "antd";
import dayjs from "dayjs";
import useCreate from "hooks/useCreate";

const Item = ({
  id,
  status,
  created_at,
  valid_to,
  refetch,
}) => {
  const {
    is_saving: is_confirming_worker_password_reset,
    create: confirmWorkerPasswordReset,
  } = useCreate({
    path: `/worker_password_resets/${id}/confirm`,
    onSuccess: refetch,
    success_message:
      "Zaakceptowano prośbę o resetowanie hasła.",
  });

  const {
    is_saving: is_rejecting_worker_password_reset,
    create: rejectWorkerPasswordReset,
  } = useCreate({
    path: `/worker_password_resets/${id}/reject`,
    onSuccess: refetch,
    success_message:
      "Odrzucono prośbę o resetowanie hasła.",
  });

  return (
    <List.Item
      style={{ padding: "12px 24px" }}
      actions={
        status === "new"
          ? [
              <Button
                type="primary"
                loading={is_rejecting_worker_password_reset}
                danger
                onClick={() => rejectWorkerPasswordReset()}
              >
                Odrzuć
              </Button>,
              <Button
                type="primary"
                loading={
                  is_confirming_worker_password_reset
                }
                onClick={() => confirmWorkerPasswordReset()}
              >
                Akceptuj
              </Button>,
            ]
          : [
              status === "rejected" && (
                <Tag color="red">Odrzucone</Tag>
              ),
              status === "confirmed" && (
                <Tag color="green">Zaakceptowane</Tag>
              ),
              status === "completed" && (
                <Tag color="green">Zakończone</Tag>
              ),
              status === "cancelled" && (
                <Tag color="red">Anulowane</Tag>
              ),
              status === "cancelled_wrong_code" && (
                <Tag color="red">
                  Anulowane (Niepoprawny kod)
                </Tag>
              ),
              status === "cancelled_overtime" && (
                <Tag color="red">
                  Anulowane (Kod wygasł)
                </Tag>
              ),
            ].filter(Boolean)
      }
    >
      <List.Item.Meta
        title={
          created_at
            ? dayjs(created_at).format("DD.MM.YYYY HH:mm")
            : "-"
        }
        description={`Ważna do: ${
          valid_to
            ? dayjs(valid_to).format("DD.MM.YYYY HH:mm")
            : "-"
        }`}
      />
    </List.Item>
  );
};

export default Item;
