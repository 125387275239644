import { Avatar, Space, Typography } from "antd";
import { Link } from "react-router-dom";
import {
  filter_issue_kinds,
  getIssueKind,
} from "helpers/options/issue_kinds";
import {
  filter_issue_statuses,
  getIssueStatusLabel,
} from "helpers/options/issue_statuses";
import EllipsisText from "components/EllipsisText";
import formatPhone from "helpers/phone_formatter";
import {
  worker_billing_status_filter_options,
  administration_billing_status_filter_options,
  getAdministratorBillingStatus,
  getWorkerBillingStatus,
} from "helpers/options/billing_statuses";
import dayjs from "dayjs";

const columns = (workers_options) => [
  {
    title: "ID",
    dataIndex: "id",
    sortable: true,
    searchable: true,
    search_method: "eq",
    search_type: "number",
    width: 100,
    render: (_, { id }) => (
      <Link to={`/issues/${id}`}>{id}</Link>
    ),
  },
  {
    title: "Nazwa ulicy",
    dataIndex: "client_street",
    sortable: true,
    searchable: true,
    search_method: "matches",
    render: (_, { id, client_street }) => (
      <Link to={`/issues/${id}`}>
        <EllipsisText text={client_street} limit={50} />
      </Link>
    ),
  },
  {
    title: "Numer budynku",
    dataIndex: "client_building_number",
    sortable: true,
    searchable: true,
    width: 130,
    search_method: "matches",
  },
  {
    title: "Numer mieszkania",
    dataIndex: "client_apartment_number",
    sortable: true,
    searchable: true,
    width: 130,
    search_method: "matches",
  },
  {
    title: "Telefon",
    dataIndex: "client_phone",
    sortable: true,
    searchable: true,
    search_method: "matches",
    search_type: "number",
    render: (_, { client_phone }) =>
      formatPhone(client_phone),
  },
  {
    title: "Data umówienia",
    dataIndex: "service_date",
    sortable: true,
    searchable: true,
    search_method: "date",
    search_component: "date-picker",
    width: 250,
    render: (_, { service_date }) =>
      service_date
        ? dayjs(service_date).format("DD-MM-YYYY HH:mm")
        : "-",
  },
  {
    title: "Status",
    dataIndex: "status",
    sortable: true,
    searchable: true,
    search_method: "in",
    search_component: "select",
    options: filter_issue_statuses,
    render: (_, { status }) => getIssueStatusLabel(status),
    width: 150,
  },
  {
    title: "Pracownik",
    dataIndex: "worker_id",
    sortable: true,
    searchable: true,
    search_method: "in",
    search_component: "select",
    options: workers_options,
    render: (_, { worker }) =>
      worker?.id ? (
        <Space>
          <Avatar
            style={{ backgroundColor: worker?.color }}
          >
            {worker.initials}
          </Avatar>
          <Typography.Text delete={worker?.is_deleted}>
            <EllipsisText
              text={worker.full_name}
              limit={30}
            />
          </Typography.Text>
        </Space>
      ) : (
        "-"
      ),
    width: 200,
  },
  {
    title: "Stan roz. pracownik",
    dataIndex: "worker_billing_status",
    sortable: false,
    searchable: true,
    search_method: "in",
    search_component: "select",
    options: worker_billing_status_filter_options,
    render: (_, { worker_billing_status }) =>
      getWorkerBillingStatus(worker_billing_status),
  },
  {
    title: "Stan roz. administracja",
    dataIndex: "administration_billing_status",
    sortable: false,
    searchable: true,
    search_method: "in",
    search_component: "select",
    options: administration_billing_status_filter_options,
    render: (_, { administration_billing_status }) =>
      getAdministratorBillingStatus(
        administration_billing_status
      ),
  },
  {
    title: "Rodzaj",
    dataIndex: "kind",
    sortable: true,
    searchable: true,
    search_method: "in",
    search_component: "select",
    options: filter_issue_kinds,
    width: 150,
    render: (_, { kind }) => getIssueKind(kind),
  },
];

export default columns;
