import { DatePicker } from "antd";
import locale from "antd/es/date-picker/locale/pl_PL";

const InputDate = ({
  placeholder = "Wybierz datę",
  format = "DD-MM-YYYY",
  getPopupContainer,
  ...rest
}) => (
  <DatePicker
    locale={locale}
    inputReadOnly
    format={format}
    style={{ width: "100%" }}
    placeholder={placeholder}
    getPopupContainer={getPopupContainer}
    {...rest}
  />
);

export default InputDate;
