import { Avatar, Space } from "antd";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import {
  filter_note_kinds,
  getNoteKind,
} from "helpers/options/note_kinds";
import EllipsisText from "components/EllipsisText";
const columns = (workers_options = []) => [
  {
    title: "Pracownik",
    dataIndex: "user_id",
    sortable: true,
    searchable: true,
    search_method: "in",
    search_component: "select",
    options: workers_options,
    render: (_, { user }) =>
      user?.id ? (
        <Space>
          <Avatar style={{ backgroundColor: user?.color }}>
            {user?.initials}
          </Avatar>
          <EllipsisText text={user?.full_name} limit={20} />
        </Space>
      ) : (
        "-"
      ),
  },
  {
    title: "Rodzaj",
    dataIndex: "kind",
    sortable: true,
    searchable: true,
    search_method: "in",
    search_component: "select",
    options: filter_note_kinds,
    render: (_, { id, kind }) => (
      <Link to={`/notes/${id}`}>{getNoteKind(kind)}</Link>
    ),
  },
  {
    title: "Treść",
    dataIndex: "content",
    sortable: true,
    searchable: true,
    search_method: "matches",
    render: (_, { content }) => (
      <EllipsisText text={content} limit={50} />
    ),
  },
  {
    title: "Data rozpoczęcia",
    dataIndex: "date_start",
    sortable: true,
    searchable: true,
    search_method: "date",
    search_component: "date-picker",
    render: (_, { date_start }) =>
      date_start
        ? dayjs(date_start).format("DD-MM-YYYY HH:mm")
        : "-",
  },
  {
    title: "Data zakończenia",
    dataIndex: "date_end",
    sortable: true,
    searchable: true,
    search_method: "date",
    search_component: "date-picker",
    render: (_, { date_end }) =>
      date_end ? dayjs(date_end).format("DD-MM-YYYY HH:mm") : "-",
  },
  {
    title: "Ilość dni",
    dataIndex: "day_diff",
    search_method: "eq",
    sortable: true,
    searchable: true,
    search_type: "number",
    render: (_, { day_diff }) => day_diff || "-",
  },
  {
    title: "Ilość godzin",
    dataIndex: "hours_diff",
    search_method: "eq",
    sortable: false,
    searchable: false,
    search_type: "number",
    render: (_, { hours_diff }) => hours_diff || "-",
  },
  {
    title: "Potwierdzone",
    dataIndex: "is_confirmed",
    sortable: true,
    searchable: true,
    search_method: "in",
    search_component: "select",
    options: [
      { label: "Tak", value: true },
      { label: "Nie", value: false },
    ],
    render: (_, { is_confirmed }) =>
      is_confirmed ? "Tak" : "Nie",
  },
];

export default columns;
