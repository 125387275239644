import { notification } from "antd";
import { ActionCableConsumer } from "react-actioncable-provider";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";

const NotificationsChannel = () => {
  const queryClient = useQueryClient();
  const refetch = useCallback(
    ({ message, kind }) => {
      queryClient.refetchQueries({
        queryKey: ["user_notifications"],
      });
      switch (kind) {
        case "danger":
          notification.warning({
            message,
          });
          break;
        case "info":
          notification.info({
            message,
          });
          break;
        case "success":
          notification.success({
            message,
          });
          break;

        default:
          break;
      }
    },
    [queryClient]
  );

  return (
    <ActionCableConsumer
      channel={{
        channel: "NotificationChannel",
      }}
      onReceived={refetch}
    />
  );
};

export default NotificationsChannel;
