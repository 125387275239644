import { useQueryClient } from "@tanstack/react-query";
import { ActionCableConsumer } from "react-actioncable-provider";

import IssuesProvider from "context/providers/issues";
import MainLayout from "components/MainLayout";
import UnassignedList from "./components/UnassignedList";
import WorkerList from "./components/WorkerList";
import CreateIssueModal from "components/create_modals/CreateIssueModal";
import IssuePreviewModal from "./components/IssuePreviewModal";

const IssuesManagement = () => {
  const queryClient = useQueryClient();

  const refetchUnassignedIssues = () =>
    queryClient.refetchQueries({
      queryKey: ["unassigned_issues", "worker_issues"],
    });

  return (
    <MainLayout
      breadcrumbs={[{ name: "Przypisz zgłoszenia" }]}
      buttons={
        <CreateIssueModal
          onSuccess={refetchUnassignedIssues}
        />
      }
    >
      <IssuesProvider>
        <ActionCableConsumer
          channel={{
            channel: "IssueChannel",
          }}
          onReceived={refetchUnassignedIssues}
        />
        <IssuePreviewModal />
        <div className="issue-management">
          <UnassignedList />
          <WorkerList />
        </div>
      </IssuesProvider>
    </MainLayout>
  );
};

export default IssuesManagement;
