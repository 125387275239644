import React from "react";
import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";

const Breadcrumbs = ({ data = [] }) => {
  return (
    <Breadcrumb
      style={{ margin: "16px 0" }}
      items={[
        { title: <Link to="/">Home</Link> },
        ...data.map(({ name, path }) => ({
          title: path ? (
            <Link to={path} key={name}>
              {name}
            </Link>
          ) : (
            name
          ),
        })),
      ]}
    />
  );
};

export default Breadcrumbs;
