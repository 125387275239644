import React from "react";

import { Menu } from "antd";
import { Link } from "react-router-dom";

const items = [
  {
    label: <Link to="/workers">Pracownicy</Link>,
    key: "workers",
  },
  {
    label: (
      <Link to="/worker_invitations">Zaproszenia</Link>
    ),
    key: "worker_invitations",
  },
  {
    label: <Link to="/devices">Urządzenia</Link>,
    key: "devices",
  },
];

const WorkerTabs = ({ current }) => (
  <Menu
    selectedKeys={[current]}
    mode="horizontal"
    items={items}
    style={{ marginBottom: 20, justifyContent: "center" }}
  />
);

export default WorkerTabs;
