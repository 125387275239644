import "./styles.scss";

import React, { useState } from "react";
import OtpInput from "react-otp-input";

import {
  Card,
  Button,
  notification,
  Space,
  Divider,
} from "antd";
import { useAuth } from "context/providers/auth";
import {
  handleErrors,
  removeAxiosAuthorization,
} from "helpers/api";
import { useCookies } from "react-cookie";

const OtpAuthPanel = () => {
  const removeCookie = useCookies([
    "access_token",
    "refresh_token",
  ])[2];

  const [is_loading, setLoading] = useState(false);
  const [is_loading_resend, setLoadingResend] =
    useState(false);
  const [code, setCode] = useState("");
  const { verifyOtp, cancelOtp } = useAuth();

  const handleVerify = async () => {
    try {
      setLoading(true);
      await verifyOtp(code);
    } catch (error) {
      if (
        error?.response?.data?.error_type ===
        "code_verification_failed"
      ) {
        removeAxiosAuthorization();
        removeCookie("access_token");
        removeCookie("refresh_token");

        setTimeout(() => {
          window.location.pathname = "/";
        }, 1000);
      }

      notification.error({ message: handleErrors(error) });
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = async () => {
    try {
      setLoadingResend(true);
      await cancelOtp();
    } catch (error) {
      notification.error({ message: handleErrors(error) });
    } finally {
      setLoadingResend(false);
    }
  };

  return (
    <div className="otp-panel">
      <Card
        title="Weryfikacja 2-etapowa"
        style={{ width: 440, textAlign: "center" }}
      >
        <Space direction="vertical" size="large">
          <OtpInput
            value={code}
            onChange={setCode}
            numInputs={6}
            onPaste={() => {
              document
                .querySelector(
                  ".ant-space-item input:first-child"
                )
                .focus();
              setCode("");
            }}
            inputStyle={{
              margin: "0 10px",
              width: 40,
              height: 40,
              borderRadius: 5,
              border: "1px solid #d9d9d9",
              textAlign: "center",
              fontSize: 20,
              lineHeight: "30px",
            }}
            inputType="number"
            renderSeparator={<span>-</span>}
            renderInput={(props) => <input {...props} />}
          />
          <Button
            disabled={code.length !== 6}
            loading={is_loading}
            type="primary"
            htmlType="button"
            onClick={handleVerify}
          >
            Zweryfikuj kod
          </Button>
        </Space>
        <Divider />
        <Space direction="vertical">
          <Button
            loading={is_loading_resend}
            type="primary"
            htmlType="button"
            onClick={handleCancel}
          >
            Wróć do logowania
          </Button>
        </Space>
      </Card>
    </div>
  );
};

export default OtpAuthPanel;
