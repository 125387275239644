const formatPhone = (phone) => {
  if (!phone) return "-";

  return [
    phone.slice(0, 3),
    phone.slice(3, 6),
    phone.slice(6, 9),
  ].join("-");
};

export default formatPhone;
