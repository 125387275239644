import { useState } from "react";
import { Card, Modal, Space, Switch } from "antd";

const WorkerPermissions = ({ data, update }) => {
  const [is_open_confirm_modal, setOpenConfirmModal] =
    useState();

  return (
    <Card
      style={{ margin: "24px 0" }}
      title="Uprawnienia"
      bordered={false}
      bodyStyle={{ padding: 20 }}
    >
      <Modal
        title="Potwierdzenie"
        open={is_open_confirm_modal}
        onCancel={() => setOpenConfirmModal(false)}
        okText="Tak"
        cancelText="Nie"
        onOk={async () => {
          await update({
            has_access_to_tasks: !data?.has_access_to_tasks,
          });
          setOpenConfirmModal(false);
        }}
      >
        <p>
          Czy chcesz{" "}
          {data?.has_access_to_tasks ? "odebrać" : "nadać"}{" "}
          pracownikowi{" "}
          <strong>
            {data?.first_name} {data?.last_name} dostęp do
            priorytetów i checklisty
          </strong>
          ?
        </p>
      </Modal>
      <Space>
        <span>Aplikacja mobilna - Priorytety i checklista</span>
        <Switch
          checkedChildren="Dostęp aktywny"
          unCheckedChildren="Brak dostępu"
          checked={data?.has_access_to_tasks}
          onChange={() => setOpenConfirmModal(true)}
        />
      </Space>
    </Card>
  );
};

export default WorkerPermissions;
