import { useState } from "react";

const useFormErrors = (initial_data, form) => {
  const [has_error, setHasErrors] = useState(false);
  const [pristine, setPristine] = useState(true);

  const onFieldsChange = (changedFields, allFields) => {
    changedFields.forEach((el) => {
      let changed_fields_count = 0;
      const name = el.name[0];
      const value = el.value;

      if (initial_data?.[name] !== value) {
        changed_fields_count++;
      }
      setPristine(changed_fields_count === 0);
    });
    const has_error =
      allFields.find(({ errors }) => errors.length > 0) !==
      undefined;
    setHasErrors(has_error);
  };

  const afterSubmit = () => {
    form?.resetFields();
    setPristine(true);
  };

  return {
    pristine,
    has_error,
    onFieldsChange,
    afterSubmit,
  };
};

export default useFormErrors;
