import dayjs from "dayjs";
import { Typography } from "antd";
import {
  filter_alert_kinds,
  getAlertKind,
} from "helpers/options/alert_kinds";
import EllipsisText from "components/EllipsisText";

const columns = [
  {
    title: "Rodzaj",
    dataIndex: "kind",
    sortable: true,
    searchable: true,
    search_method: "in",
    search_component: "select",
    options: filter_alert_kinds,
    render: (_, { kind, is_important }) => (
      <Typography.Text
        type={is_important ? "danger" : "success"}
      >
        {getAlertKind(kind)}
      </Typography.Text>
    ),
  },
  {
    title: "E-mail",
    dataIndex: "email",
    sortable: true,
    searchable: true,
    search_method: "matches",
    render: (_, { email }) => (
      <EllipsisText text={email} limit={40} />
    ),
  },
  {
    title: "Adres IP",
    dataIndex: "ip_address",
    sortable: true,
    searchable: true,
    search_method: "matches",
    render: (_, { ip_address }) => (
      <EllipsisText text={ip_address} limit={20} />
    ),
  },
  {
    title: "Data utworzenia",
    dataIndex: "created_at",
    sortable: true,
    searchable: true,
    search_method: "date",
    search_component: "date-picker",
    render: (_, { created_at }) =>
      dayjs(created_at).format("DD-MM-YYYY HH:mm"),
  },
];

export default columns;
