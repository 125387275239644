import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import { useState } from "react";
import dayjs from "dayjs";
import range from "helpers/range";

import service_date_delays from "helpers/options/service_date_delays";
import { getIssueStatusLabel } from "helpers/options/issue_statuses";
import useUpdate from "hooks/useUpdate";
import InputDate from "components/InputDate";
import { useWorkers } from "context/providers/workers";
import { useCalendar } from "context/providers/calendar";

const disabledDateTime = () => ({
  disabledMinutes: () => [
    ...range(1, 15),
    ...range(16, 30),
    ...range(31, 45),
    ...range(46, 60),
  ],
});

let timeout;

const CustomEditor = ({
  handleConfirm,
  refetch,
  searchIssues,
  scheduler: { state, edited, close },
}) => {
  const { workers_list_options } = useWorkers();
  const { calendar_worker_id } = useCalendar();
  const [is_searching_issues, setSearchingIssues] =
    useState(false);
  const [issue_options, setIssueOptions] = useState([]);
  const [issue_id, setIssueId] = useState(edited?.event_id);
  const [worker_id, setWorkerId] = useState(
    edited?.worker?.id || calendar_worker_id
  );
  const show_issue_select = !edited?.event_id;

  const { is_saving, update } = useUpdate({
    path: `/issues/${issue_id}/set_service_date`,
    onSuccess: () => {
      close();
      refetch();
      handleConfirm();
    },
  });

  const handleSubmit = async (values) => {
    const service_date = dayjs(
      values.service_date
    ).format();

    update({
      worker_id,
      service_date,
      service_date_delay: values.service_date_delay,
    });
  };

  const handleChange = (id) => setIssueId(id || null);

  const handleAsyncSearch = async (q) => {
    try {
      setSearchingIssues(true);
      const data = await searchIssues(q);
      setIssueOptions(
        data.map(({ id, identifier, status }) => ({
          label: (
            <>
              {getIssueStatusLabel(status)} {identifier}
            </>
          ),
          value: id,
        }))
      );
    } catch (error) {
      setIssueOptions([]);
    } finally {
      setSearchingIssues(false);
    }
  };

  const onSearch = async (q) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      handleAsyncSearch(q);
    }, 300);
  };

  return (
    <Form
      id="day_modal"
      layout="vertical"
      initialValues={{
        service_date: dayjs(state.start?.value),
        service_date_delay: edited?.service_date_delay || 0,
        worker_id: null,
      }}
      onFinish={handleSubmit}
    >
      <Card
        title={
          edited?.title
            ? "Edytuj zgłoszenie"
            : "Dodaj zgłoszenie do kalendarza"
        }
      >
        <Space
          direction="vertical"
          style={{ marginBottom: 15, width: "100%" }}
        >
          {show_issue_select ? (
            <>
              <Typography.Text>
                Szukaj zgłoszenia
              </Typography.Text>
              <Select
                showSearch
                value={issue_id}
                placeholder="Szukaj..."
                style={{
                  width: "100%",
                }}
                getPopupContainer={() =>
                  document.querySelector("#day_modal")
                }
                defaultActiveFirstOption={false}
                suffixIcon={
                  is_searching_issues
                    ? "Proszę czekać..."
                    : null
                }
                filterOption={false}
                onSearch={onSearch}
                onChange={handleChange}
                notFoundContent={null}
                options={issue_options}
              />
            </>
          ) : (
            <Typography>
              <Typography.Text>
                Zgłoszenie:{" "}
              </Typography.Text>
              <br />
              <Typography.Text>
                <strong>{edited?.client_address}</strong>
              </Typography.Text>

              <Typography.Text
                style={{ display: "block", marginTop: 10 }}
              >
                Data utworzenia:{" "}
              </Typography.Text>
              <Typography.Text>
                <strong>
                  {dayjs(edited?.created_at).format(
                    "DD-MM-YYYY HH:mm"
                  )}
                </strong>
              </Typography.Text>
            </Typography>
          )}
        </Space>
        <Row gutter={[12, 12]}>
          <Col xs={24} md={24} lg={12} xl={12}>
            <Form.Item
              label="Data umówienia"
              name="service_date"
              readOnly
            >
              <InputDate
                format="DD-MM-YYYY HH:mm"
                showTime={{ format: "HH:mm" }}
                disabledTime={disabledDateTime}
                getPopupContainer={() =>
                  document.querySelector("#day_modal")
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={24} lg={12} xl={12}>
            <Form.Item
              label="Data umówienia (opóźnienie)"
              name="service_date_delay"
            >
              <Select
                placeholder="Data umówienia (opóźnienie)"
                options={service_date_delays}
                defaultValue={service_date_delays[0]}
                getPopupContainer={() =>
                  document.querySelector("#day_modal")
                }
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[12, 12]}>
          <Col xs={24} md={24} lg={12} xl={12}>
            <Typography className="m-b-5">
              <Typography.Text>Pracownicy</Typography.Text>
            </Typography>
            <Select
              style={{ width: "100%" }}
              value={worker_id}
              getPopupContainer={() =>
                document.querySelector("#day_modal")
              }
              placeholder="Wybierz pracownika"
              allowClear
              filterOption={false}
              onChange={(id) => setWorkerId(id || null)}
              options={workers_list_options || []}
            />
          </Col>
        </Row>

        <Row justify="end">
          <Space>
            <Button onClick={close}>Anuluj</Button>
            <Button
              type="primary"
              htmlType="submit"
              disabled={!issue_id}
              loading={is_saving}
            >
              Zapisz
            </Button>
          </Space>
        </Row>
      </Card>
    </Form>
  );
};

export default CustomEditor;
