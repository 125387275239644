import React, { useState } from "react";
import { Card, Col, Row, Steps } from "antd";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";

import MainLayout from "components/MainLayout";

import useGet from "hooks/useGet";
import PageFailure from "components/PageFailure";
import Logs from "components/Logs";
import Comments from "components/Comments";
import IssueEditForm from "./components/Form";
import IssueInfo from "./components/Info";
import IssueWorker from "./components/Worker";
import IssueCalendarProvider from "./components/IssueCalendar/issue_calendar_context";

const getCurrentStep = ({ status, service_date }) => {
  switch (status) {
    case "cancelled":
    case "completed":
      return 3;
    case "in_progress":
      return 2;
    case "new":
      return service_date ? 1 : 0;
    default:
      return 0;
  }
};

const IssueUpdate = () => {
  const { id } = useParams();

  const [urgent, setUrgent] = useState(false);

  const path = `/issues/${id}`;

  const { is_loading, is_error, data, refetch } = useGet({
    path,
    query: ["issues", id],
    onSuccess: (res) => setUrgent(res?.data?.urgent),
    options: {
      refetchOnWindowFocus: false,
    },
  });

  if (is_error) {
    return (
      <PageFailure
        title="Nie znaleziono zgłoszenia"
        button_text="Wróć do listy"
        path="/issues"
      />
    );
  }

  const current_step = getCurrentStep({
    status: data?.status,
    service_date: data?.service_date,
  });

  return (
    <IssueCalendarProvider
      issue_id={data?.id}
      issue_data={data}
      refetch={refetch}
    >
      <MainLayout
        back_href="/issues"
        breadcrumbs={[
          { name: "Zgłoszenia", path: "/issues" },
          { name: data?.id || "Trwa pobieranie..." },
        ]}
      >
        <Steps
          style={{ margin: "20px auto", maxWidth: 1000 }}
          current={current_step}
          items={[
            {
              title: "Nowe",
              className: "status_new",
              description: data?.created_at
                ? dayjs(data.created_at).format(
                    "DD-MM-YYYY HH:mm"
                  )
                : "-",
            },
            {
              title: "Umówione",
              className: "status_service",
              description: data?.service_date
                ? `${dayjs(data.service_date).format(
                    "DD-MM-YYYY HH:mm"
                  )} - ${dayjs(
                    data.service_date_delayed
                  ).format("HH:mm")}`
                : "-",
            },
            {
              title: "W trakcie",
              className: "status_in_progress",
              description: data?.started_at
                ? dayjs(data.started_at).format(
                    "DD-MM-YYYY HH:mm"
                  )
                : "-",
            },
            data?.status === "cancelled"
              ? {
                  title: "Anulowane",
                  className: "status_cancelled",
                  description: data?.cancelled_at
                    ? dayjs(data.cancelled_at).format(
                        "DD-MM-YYYY HH:mm"
                      )
                    : "-",
                }
              : {
                  title: "Zakończone",
                  className: "status_completed",
                  description: data?.completed_at
                    ? dayjs(data.completed_at).format(
                        "DD-MM-YYYY HH:mm"
                      )
                    : "-",
                },
          ]}
        />
        <IssueInfo data={data} />
        <Row gutter={[24, 24]}>
          <Col xs={24} md={24} lg={14} xl={14}>
            <IssueEditForm
              data={data}
              urgent={urgent}
              is_loading={is_loading}
              setUrgent={setUrgent}
              refetch={refetch}
            />
          </Col>
          <Col xs={24} md={24} lg={10} xl={10}>
            <IssueWorker data={data} refetch={refetch} />
            <Comments
              issue_id={id}
              refetch={refetch}
              data={data?.issue_comments || []}
            />
            <Card
              title="Logi zgłoszenia"
              loading={is_loading}
              bodyStyle={{
                maxHeight: 600,
                overflowY: "auto",
              }}
            >
              <Logs data={data?.logs || []} />
            </Card>
          </Col>
        </Row>
      </MainLayout>
    </IssueCalendarProvider>
  );
};

export default IssueUpdate;
