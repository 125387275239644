import { useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  Col,
  Divider,
  Dropdown,
  Empty,
  Input,
  Row,
  Select,
  Space,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import {
  CommentOutlined,
  SettingOutlined,
  FileSearchOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import { useIssues } from "context/providers/issues";
import { getIssueKind } from "helpers/options/issue_kinds";
import formatPhone from "helpers/phone_formatter";
import ModalConfirm from "components/ModalConfirm";

import EllipsisText from "components/EllipsisText";
import TimeAgo from "react-timeago";
import polishStrings from "react-timeago/lib/language-strings/pl";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";

const formatter = buildFormatter(polishStrings);

const WorkerList = () => {
  const {
    is_assigning,
    toggleAssignedIssuesIds,
    setWorkerId,
    workers_list_options,
    worker_issues,
    assigned_issues_ids,
    unassigned_issues_ids,
    unassignIssuesFromWorker,
    active_worker,
    setPreviewId,
    assigned_search_type,
    setAssignedSearchType,
    setAssignedSearch,
  } = useIssues();

  const [search_value, setSearchValue] = useState("");

  const new_worker_issues = worker_issues.filter(
    ({ status }) => status === "new"
  );
  const in_progress_worker_issues = worker_issues.filter(
    ({ status }) => status === "in_progress"
  );

  const is_empty =
    new_worker_issues.length === 0 &&
    in_progress_worker_issues.length === 0;

  const data = [
    {
      title: "Nowe",
      items: new_worker_issues.sort(
        (a, b) =>
          new Date(b.assigned_at).getTime() -
          new Date(a.assigned_at).getTime()
      ),
    },
    {
      title: "W trakcie",
      items: in_progress_worker_issues.sort(
        (a, b) =>
          new Date(b.started_at).getTime() -
          new Date(a.started_at).getTime()
      ),
    },
  ];

  const getIssuesIdentifiers = () =>
    [...new_worker_issues, ...in_progress_worker_issues]
      .filter(Boolean)
      .filter(({ id }) => assigned_issues_ids.includes(id))
      ?.map(({ identifier }) => (
        <p className="m-0">
          <strong>{identifier}</strong>
        </p>
      ));

  return (
    <div className="issue-management__col">
      <Card
        bodyStyle={{
          minHeight: 226,
          maxHeight: 1925,
          overflowY: "scroll",
        }}
        headStyle={{ padding: "15px 25px" }}
        title={
          <Space>
            <Select
              style={{ minWidth: "200px" }}
              size="large"
              placeholder="Wybierz pracownika"
              optionFilterProp="children"
              allowClear
              onChange={(id) => setWorkerId(id)}
              options={workers_list_options || []}
            />
            {active_worker?.holiday && (
              <Tag color="error">
                Urlop ({active_worker.holiday.diff} dni){" "}
                <br />
                {dayjs(active_worker.holiday.from).format(
                  "DD.MM.YYYY"
                )}
                {"-"}
                {dayjs(active_worker.holiday.to).format(
                  "DD.MM.YYYY"
                )}
              </Tag>
            )}
          </Space>
        }
        extra={
          <ModalConfirm
            button_text="Odpisz od pracownika"
            handleConfirm={unassignIssuesFromWorker}
            disabled={!assigned_issues_ids.length}
            loading={is_assigning}
            text={
              <>
                <p className="m-0 m-b-5">
                  Czy na pewno chcesz odpisać zgłoszenia:
                </p>
                {getIssuesIdentifiers()}
                <p className="m-0 m-b-5">od pracownika</p>
                <p className="m-0">
                  <strong>
                    {active_worker?.full_name}?
                  </strong>
                </p>
              </>
            }
          />
        }
      >
        <>
          <Space.Compact
            style={{ width: "100%", marginBottom: 20 }}
          >
            <Select
              style={{ width: "200px" }}
              onChange={setAssignedSearchType}
              value={assigned_search_type}
            >
              <Select.Option value={null}>
                Wszystkie
              </Select.Option>
              <Select.Option value="identifier">
                Identyfikator
              </Select.Option>
              <Select.Option value="client_phone">
                Telefon
              </Select.Option>
              <Select.Option value="client_first_name">
                Imię
              </Select.Option>
              <Select.Option value="client_last_name">
                Nazwisko
              </Select.Option>
              <Select.Option value="client_street">
                Ulica
              </Select.Option>
              <Select.Option value="client_city">
                Miasto
              </Select.Option>
              <Select.Option value="client_post_code">
                Kod pocztowy
              </Select.Option>
            </Select>
            <Input.Search
              style={{ width: "100%" }}
              value={search_value}
              placeholder="Szukaj..."
              onChange={({ target: { value } }) =>
                setSearchValue(value)
              }
              onSearch={(value) => [
                setAssignedSearch(value),
              ]}
              maxLength={128}
            />
            <Button
              icon={<CloseOutlined />}
              onClick={() => {
                setAssignedSearch("");
                setSearchValue("");
              }}
            />
          </Space.Compact>
        </>
        {is_empty ? (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="Brak danych"
          />
        ) : (
          <Row gutter={[12, 12]}>
            {data.map(({ title, items }) => (
              <Col
                xs={24}
                md={12}
                lg={12}
                xl={12}
                key={title}
              >
                <Divider>
                  {title} ({items.length})
                </Divider>
                {items.map((issue) => {
                  const date_now = dayjs();
                  const date_assigned = dayjs(
                    issue.assigned_at
                  );
                  const diff = date_now.diff(
                    date_assigned,
                    "second",
                    true
                  );
                  const is_selected =
                    assigned_issues_ids.includes(issue.id);
                  const icon_color =
                    issue?.status === "in_progress" ||
                    is_selected
                      ? "#000"
                      : "#fff";
                  return (
                    <Card
                      key={issue.id}
                      onClick={
                        issue.status === "new" &&
                        unassigned_issues_ids.length === 0
                          ? () =>
                              toggleAssignedIssuesIds(
                                issue.id
                              )
                          : null
                      }
                      style={{ marginBottom: 12 }}
                      className={`issue ${issue.status} ${
                        is_selected ? "active" : ""
                      }`}
                      extra={
                        <Space size={1}>
                          {issue?.urgent && (
                            <Tag color="#cd201f">Pilne</Tag>
                          )}
                          {diff < 11 && (
                            <Tag color="#f50">Nowe</Tag>
                          )}
                          {issue?.issue_comments_count >
                            0 && (
                            <Tooltip
                              title={`${issue.issue_comments_count} komentarze`}
                            >
                              <CommentOutlined
                                style={{
                                  marginTop: 7,
                                  color: icon_color,
                                }}
                              />{" "}
                              <Typography.Text
                                style={{
                                  color: icon_color,
                                }}
                              >
                                (
                                {issue.issue_comments_count}
                                )
                              </Typography.Text>
                            </Tooltip>
                          )}
                        </Space>
                      }
                      size="small"
                      hoverable={issue.status === "new"}
                      type="inner"
                      title={
                        <EllipsisText
                          text={issue?.identifier}
                          limit={50}
                        />
                      }
                    >
                      <p
                        style={{
                          textTransform: "uppercase",
                        }}
                      >
                        <strong>
                          <EllipsisText
                            text={issue?.client_address}
                            limit={100}
                          />
                        </strong>
                      </p>
                      <p>
                        Tel:{" "}
                        <strong>
                          {formatPhone(issue?.client_phone)}
                        </strong>
                      </p>
                      <p>
                        <strong>
                          <EllipsisText
                            text={issue?.client_name}
                            limit={50}
                          />
                        </strong>
                      </p>
                      <p>
                        Data umówienia:{" "}
                        {issue?.service_date ? (
                          <strong>
                            {`${dayjs(
                              issue.service_date
                            ).format(
                              "DD-MM-YYYY HH:mm"
                            )} - ${dayjs(
                              issue.service_date_delayed
                            ).format("HH:mm")}`}
                            {" - "}

                            <TimeAgo
                              date={issue.service_date}
                              formatter={formatter}
                            />
                          </strong>
                        ) : (
                          "-"
                        )}
                      </p>
                      <p>
                        Data zgłoszania:{" "}
                        <strong>
                          {issue.created_at
                            ? dayjs(
                                issue.created_at
                              ).format("DD-MM-YYYY HH:mm")
                            : "-"}
                        </strong>
                      </p>
                      <p>
                        Rodzaj:{" "}
                        <strong>
                          {getIssueKind(issue.kind)}
                        </strong>
                      </p>
                      <Dropdown
                        menu={{
                          items: [
                            {
                              key: "1",
                              label: (
                                <Link
                                  to={`/issues/${issue.id}`}
                                >
                                  Przejdź do edycji
                                </Link>
                              ),
                            },
                            {
                              key: "2",
                              label: (
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={`http://maps.google.com/?q=${issue.client_address}`}
                                >
                                  Przejdź do mapy
                                </a>
                              ),
                            },
                            {
                              key: "3",
                              label: (
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={`tel:${issue.client_phone}`}
                                >
                                  Zadzwoń{" "}
                                  {formatPhone(
                                    issue.client_phone
                                  )}
                                </a>
                              ),
                            },
                          ],
                        }}
                        placement="bottomRight"
                        arrow
                      >
                        <Button
                          className="btn-dropdown"
                          htmlType="button"
                          icon={<SettingOutlined />}
                          onClick={(e) =>
                            e.stopPropagation()
                          }
                        />
                      </Dropdown>
                      <Button
                        className="btn-preview"
                        htmlType="button"
                        onClick={(e) => {
                          e.stopPropagation();
                          setPreviewId(issue?.id);
                        }}
                        icon={<FileSearchOutlined />}
                      />
                    </Card>
                  );
                })}
              </Col>
            ))}
          </Row>
        )}
      </Card>
    </div>
  );
};

export default WorkerList;
