import React, {
  useMemo,
  useReducer,
  useContext,
  useEffect,
} from "react";
import { useCookies } from "react-cookie";
import { notification } from "antd";
import {
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";

import {
  api,
  createAxiosAuthorization,
  handleErrors,
  removeAxiosAuthorization,
} from "helpers/api";

import reducer, {
  initialState,
  SET_LOGGED,
  SET_LOADING,
  SET_USER,
  SET_OTP_AUTH_NEEDED,
} from "../reducers/auth";
import ActivityProtect from "components/ActivityProtect";
import Loader from "components/Loader";
import LoginPanel from "components/LoginPanel";
import Main from "components/Main";
import WorkersProvider from "./workers";
import NotificationProvider from "./notifications";
import CableProvider from "./cable";
import OtpAuthPanel from "components/OtpAuthPanel";
import NotFound from "views/404";

const AuthContext = React.createContext();

function AuthProvider() {
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(
    reducer,
    initialState
  );

  const [
    { access_token, refresh_token },
    setCookie,
    removeCookie,
  ] = useCookies(["access_token", "refresh_token"]);

  useEffect(() => {
    if (Boolean(access_token)) {
      checkAuthorization(access_token, refresh_token);
    } else {
      setLogged(false);
      setLoading(false);
    }
    // eslint-disable-next-line
  }, []);

  const setLoading = (is_loading) =>
    dispatch({
      type: SET_LOADING,
      payload: { is_loading },
    });

  const setLogged = (is_logged) =>
    dispatch({ type: SET_LOGGED, payload: { is_logged } });

  const setUser = (user) =>
    dispatch({ type: SET_USER, payload: { user } });

  const checkAuthorization = async (
    access_token,
    refresh_token
  ) => {
    try {
      const { data } = await api.get("/users/me", {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });
      createAxiosAuthorization(access_token, refresh_token);
      setUser(data);
      setLogged(true);
      setLoading(false);
    } catch (error) {
      console.error(error);
      if (
        error.response.data.error_type ===
        "otp_verification_required"
      ) {
        dispatch({ type: SET_OTP_AUTH_NEEDED });
      } else {
        removeCookie("access_token");
        removeCookie("refresh_token");
        notification.error({
          message: handleErrors(error),
        });
        setLogged(false);
        setLoading(false);
      }
    }
  };

  const handleLogin = async ({ email, password }) => {
    try {
      const { access_token, refresh_token } =
        await api.post("/users/sign_in", {
          email,
          password,
        });

      setCookie("access_token", access_token, {
        path: "/",
        maxAge: 2 * 3600,
      });
      setCookie("refresh_token", refresh_token, {
        path: "/",
        maxAge: 2 * 3600,
      });
      navigate("/");
      checkAuthorization(access_token, refresh_token);
    } catch (error) {
      throw error;
    }
  };

  const handleLogout = async () => {
    try {
      await api.post("/users/sign_out");

      removeAxiosAuthorization();
      removeCookie("access_token");
      removeCookie("refresh_token");
      setLogged(false);
      setUser(null);
      navigate("/");
      notification.success({
        message: "Wylogowano pomyślnie",
      });
    } catch (error) {
      throw error;
    }
  };

  const verifyOtp = async (code) => {
    try {
      await api.post(
        "/users/verify_otp",
        {
          code,
        },
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      checkAuthorization(access_token, refresh_token);
    } catch (error) {
      throw error;
    }
  };

  const cancelOtp = async () => {
    try {
      await api.post(
        "/users/cancel_otp",
        {},
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      removeAxiosAuthorization();
      removeCookie("access_token");
      removeCookie("refresh_token");
      setLogged(false);
      setUser(null);
      navigate("/");
    } catch (error) {
      throw error;
    }
  };

  const verifyPin = async (pin) => {
    try {
      await api.get(`/users/verify_pin?pin=${pin}`);
    } catch (error) {
      throw error;
    }
  };

  const updatePin = async ({ old_pin, pin }) => {
    try {
      await api.put("/users/change_pin", { old_pin, pin });
      notification.success({
        message: "Pin zaktualizowany",
      });
    } catch (error) {
      notification.error({
        message: handleErrors(error),
      });
      throw error;
    }
  };

  const value = useMemo(() => {
    return {
      state,
      handleLogin,
      handleLogout,
      verifyOtp,
      cancelOtp,
      updatePin,
      verifyPin,
    };
    // eslint-disable-next-line
  }, [state]);

  if (state.is_loading) {
    return <Loader />;
  }

  return (
    <AuthContext.Provider value={value}>
      {state.otp_auth_needed ? (
        <Routes>
          <Route path="*" element={<OtpAuthPanel />} />
        </Routes>
      ) : !state.is_logged ? (
        <Routes>
          <Route path="/" element={<LoginPanel />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      ) : (
        <ActivityProtect>
          <CableProvider>
            <WorkersProvider>
              <NotificationProvider>
                <Main />
              </NotificationProvider>
            </WorkersProvider>
          </CableProvider>
        </ActivityProtect>
      )}
    </AuthContext.Provider>
  );
}

const useAuth = () => useContext(AuthContext);
export { useAuth };
export default AuthProvider;
