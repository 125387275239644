import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Modal, Select } from "antd";

import useGet from "hooks/useGet";
import useCreate from "hooks/useCreate";

const AddDeviceModal = ({ handleClose, refetch }) => {
  const [device_id, setDeviceId] = useState(null);
  const { id: worker_id } = useParams();
  const { is_loading, data } = useGet({
    path: "/devices",
    query: ["devices_list"],
    context: "list",
  });

  const { is_saving, create } = useCreate({
    path: "/worker_devices",
  });

  const handleOk = async () => {
    try {
      await create({
        worker_id,
        device_id,
      });
      await refetch();
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  const options = data?.map(({ id, name, imei }) => ({
    label: `${name} [${imei}]`,
    value: id,
  }));

  return (
    <Modal
      maskClosable={false}
      width={300}
      title="Przyznaj dostęp"
      open={true}
      onOk={handleOk}
      onCancel={handleClose}
      cancelText="Anuluj"
      okText="Dodaj"
      okButtonProps={{
        disabled: !device_id || is_saving,
        loading: is_saving,
      }}
    >
      <Select
        style={{ width: "100%" }}
        loading={is_loading}
        onChange={(value) => setDeviceId(value)}
        options={options || []}
      />
    </Modal>
  );
};

export default AddDeviceModal;
