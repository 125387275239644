const calendar_translations = {
  navigation: {
    month: "Miesiąc",
    week: "Tydzień",
    day: "Dzień",
    today: "Dzisiaj",
    agenda: "Agenda",
  },
  form: {
    addTitle: "Przypisz datę zgłoszenia",
    editTitle: "Edytuj date zgłoszenia",
    confirm: "Potwierdź",
    delete: "Usuń",
    cancel: "Anuluj",
  },
  event: {
    title: "Tytuł",
    start: "Rozpoczęcie",
    end: "Zakończenie",
    allDay: "Cały dzień",
  },
  validation: {
    required: "Wymagane",
    invalidEmail: "Niepoprawny e-mail",
    onlyNumbers: "Tylko cyfry",
    min: "Minimum {{min}} znaków",
    max: "Maximum {{max}} znaków",
  },
  moreEvents: "Więcej...",
  noDataToDisplay: "Brak danych",
  loading: "Trwa pobieranie...",
};

export default calendar_translations