import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import dayjs from "dayjs";

import queryString from "query-string";
const url =
  process.env.NODE_ENV === "production"
    ? "https://api.goleniewski-interline.pl"
    : "http://0.0.0.0:3002";

export const baseURL = `${url}/v1/admin`;

export const ws_url =
  process.env.NODE_ENV === "production"
    ? "wss://api.goleniewski-interline.pl:3334/cable"
    : "ws://localhost:3334/cable";

export const api = axios.create({ baseURL });

api.interceptors.response.use(function (response) {
  return response.data;
});

const refreshAuthLogic = (failedRequest) =>
  api
    .post("users/refresh")
    .then(({ access_token, refresh_token }) => {
      document.cookie = `access_token=${access_token}; path=/; max-age=${
        2 * 3600
      };`;
      document.cookie = `refresh_token=${refresh_token}; path=/; max-age=${
        2 * 3600
      };`;

      failedRequest.response.config.headers[
        "Authorization"
      ] = "Bearer " + access_token;

      failedRequest.response.config.headers[
        "Refresh-Token"
      ] = refresh_token;

      createAxiosAuthorization(access_token, refresh_token);

      return Promise.resolve();
    });

createAuthRefreshInterceptor(api, refreshAuthLogic, {
  pauseInstanceWhileRefreshing: true,
});

export const createAxiosAuthorization = (
  access_token,
  refresh_token
) => {
  api.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${access_token}`;
  api.defaults.headers.common["Refresh-Token"] =
    refresh_token;
};

export const removeAxiosAuthorization = () => {
  delete api.defaults.headers.common["Authorization"];
  delete api.defaults.headers.common["Refresh-Token"];
};

export const createQuery = ({ paginate, query }) => {
  const ransack = {};

  Object.keys(query).forEach((key) => {
    const item = query?.[key];
    if (
      item?.search_method === "matches" &&
      item?.value?.length > 0
    ) {
      ransack[`q[${key}_matches]`] = `%25${item.value}%25`;
    }

    if (item?.search_method === "in") {
      if (item.value === "nil") {
        ransack[`q[${key}_null]`] = true;
      } else {
        ransack[`q[${key}_in][]`] = item.value;
      }
    }
    if (item?.search_method === "eq" && item?.value) {
      ransack[`q[${key}_eq`] = item.value;
    }
    if (item?.search_method === "date" && item?.value) {
      const day_start = dayjs(item?.value)
        .startOf("date")
        .format("DD-MM-YYYY HH:mm");
      const day_end = dayjs(item?.value)
        .endOf("date")
        .format("DD-MM-YYYY HH:mm");
      ransack[`q[${key}_gteq]`] = day_start;
      ransack[`q[${key}_lteq]`] = day_end;
    }
  });
  return decodeURI(
    queryString.stringify({
      ...paginate,
      ...ransack,
    })
  );
};

export const handleErrors = (error) => {
  if (error?.response?.data?.errors?.length > 0) {
    return error.response.data.errors
      .map(
        ({ field_translate, message }) =>
          `${field_translate} - ${message}`
      )
      .join(". ");
  }
  if (error?.response?.data?.message) {
    return error.response.data.message;
  }
  return "Wystąpił błąd";
};
