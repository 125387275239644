import React from "react";
import {
  Card,
  Col,
  Row,
  Form,
  Input,
  Button,
  Space,
  Checkbox,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";

import MainLayout from "components/MainLayout";

import useGet from "hooks/useGet";
import useFormErrors from "hooks/useFormErrors";
import useUpdate from "hooks/useUpdate";
import useDelete from "hooks/useDelete";
import PageFailure from "components/PageFailure";
import Logs from "components/Logs";
import WorkerDevices from "./components/WorkerDevices";
import ModalDelete from "components/ModalDelete";
import { imei_regexp, name_regexp } from "helpers/regexp";

const DeviceUpdate = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [form] = Form.useForm();

  const path = `/devices/${id}`;

  const { is_loading, is_error, data, refetch } = useGet({
    path,
    query: ["devices", id],
  });

  const { pristine, has_error, onFieldsChange } =
    useFormErrors(data);

  const { is_saving, update } = useUpdate({
    path,
    form,
    onSuccess: refetch,
  });

  const { is_deleting, _delete } = useDelete({
    path,
    onSuccess: () => navigate("/devices"),
  });

  if (is_error) {
    return (
      <PageFailure
        title="Nie znaleziono urządzenia"
        button_text="Wróć do listy"
        path="/devices"
      />
    );
  }

  return (
    <MainLayout
      back_href="/devices"
      breadcrumbs={[
        { name: "Urządzenia", path: "/devices" },
        {
          name: data?.imei || "Trwa pobieranie...",
        },
      ]}
    >
      <Row gutter={[24, 24]}>
        <Col xs={24} md={24} lg={12} xl={8}>
          <Card
            title="Edytuj urządzenie"
            loading={is_loading}
          >
            <Form
              form={form}
              onFieldsChange={onFieldsChange}
              layout="vertical"
              preserve={true}
              initialValues={{
                imei: data?.imei || "",
                name: data?.name || "",
                is_active: data?.is_active || false,
              }}
              onFinish={(values) => update(values)}
            >
              <Form.Item
                label="Nazwa"
                name="name"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Nazwa jest wymagana!",
                  },
                  {
                    pattern: name_regexp,
                    message:
                      "Pole zawiera niedozwolone znaki!!",
                  },
                ]}
              >
                <Input placeholder="Nazwa" maxLength={30} />
              </Form.Item>
              <Form.Item
                name="imei"
                label="IMEI"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "IMEI jest wymagany!",
                  },
                  {
                    pattern: imei_regexp,
                    message:
                      "Pole zawiera niedozwolone znaki!!",
                  },
                ]}
              >
                <Input placeholder="IMEI" maxLength={30} />
              </Form.Item>
              <Form.Item
                name="is_active"
                valuePropName="checked"
              >
                <Checkbox>Aktywny</Checkbox>
              </Form.Item>
              <Row justify="end">
                <Space>
                  <ModalDelete
                    loading={is_deleting}
                    text="urządzenie"
                    handleDelete={_delete}
                  />
                  <Button
                    disabled={pristine || has_error}
                    loading={
                      is_deleting || is_loading || is_saving
                    }
                    type="primary"
                    htmlType="submit"
                  >
                    Zapisz
                  </Button>
                </Space>
              </Row>
            </Form>
          </Card>
          <WorkerDevices data={data?.worker_devices} />
        </Col>
        <Col xs={24} md={24} lg={12} xl={16}>
          <Card
            title="Logi urządzenia"
            loading={is_loading}
            bodyStyle={{
              maxHeight: 445,
              overflowY: "auto",
            }}
          >
            <Logs data={data?.logs || []} />
          </Card>
        </Col>
      </Row>
    </MainLayout>
  );
};

export default DeviceUpdate;
