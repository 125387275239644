import { useQuery } from "@tanstack/react-query";
import { notification } from "antd";

import { api } from "helpers/api";
import qs from "query-string";

const useGet = ({
  path,
  query,
  context,
  onSuccess,
  search = {},
  options = {},
}) => {
  const query_path = qs.stringify({ context, ...search });
  const {
    isLoading: is_loading,
    isError: is_error,
    data,
    refetch,
  } = useQuery(
    query,
    () => api.get(`${path}?${query_path}`),
    {
      retry: false,
      keepPreviousData: false,
      select: (res) => ({
        data: res?.data,
        meta: res?.meta,
      }),
      onSuccess: onSuccess,
      onError: (err) =>
        notification.error({
          message:
            err?.response?.data?.message || "Wystąpił błąd",
        }),
      ...options,
    }
  );
  return {
    is_loading,
    is_error,
    data: data?.data,
    meta: data?.meta,
    refetch,
  };
};

export default useGet;
