import React, { useMemo, useContext } from "react";

import Loader from "components/Loader";
import useGet from "hooks/useGet";
import { Avatar } from "antd";
import { useAuth } from "./auth";

const WorkersContext = React.createContext();

function WorkersProvider({ children }) {
  const {
    state: { user },
  } = useAuth();

  const { is_loading, data } = useGet({
    path: "/workers",
    query: ["workers_list"],
    context: "list",
    search: {
      per_page: 50,
      sort: "position__asc",
    },
    options: {
      refetchOnWindowFocus: false,
    },
  });

  const workers_list_options = data?.map(
    ({ id, full_name, initials, color }) => ({
      label: (
        <>
          <Avatar
            size="small"
            style={{
              backgroundColor: color,
              marginRight: 10,
            }}
          >
            {initials}
          </Avatar>
          {full_name}
        </>
      ),
      value: id,
    })
  );

  const user_list_options =
    workers_list_options?.length > 0
      ? [
          {
            label: (
              <>
                <Avatar
                  size="small"
                  style={{
                    backgroundColor: user.color,
                    marginRight: 10,
                  }}
                >
                  {user.initials}
                </Avatar>
                {user.full_name}
              </>
            ),
            value: user.id,
          },
          ...workers_list_options,
        ]
      : [];

  const value = useMemo(() => {
    return {
      data,
      workers_list_options,
      user_list_options,
    };
    // eslint-disable-next-line
  }, [is_loading, workers_list_options, data]);

  if (is_loading) {
    return <Loader />;
  }
  return (
    <WorkersContext.Provider value={value}>
      {children}
    </WorkersContext.Provider>
  );
}

const useWorkers = () => useContext(WorkersContext);
export { useWorkers };
export default WorkersProvider;
