import { Button, Modal } from "antd";
import { useState } from "react";
import { DeleteOutlined } from "@ant-design/icons";
const ModalDelete = ({
  loading,
  text,
  handleDelete,
  only_icon = false,
}) => {
  const [is_open, setOpen] = useState(false);

  const onDelete = async () => {
    await handleDelete();
    setOpen(false);
  };
  
  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        danger
        ghost
        icon={<DeleteOutlined />}
        loading={loading}
        type="primary"
        htmlType="button"
      >
        {only_icon ? null : "Usuń"}
      </Button>
      {is_open && (
        <Modal
          title="Potwierdzenie"
          open={is_open}
          onOk={onDelete}
          onCancel={() => setOpen(false)}
          cancelText="Anuluj"
          okText="Potwierdzam"
          okType="danger"
        >
          Czy na pewno chcesz usunąć {text}?
        </Modal>
      )}
    </>
  );
};

export default ModalDelete;
