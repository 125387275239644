import { useRef } from "react";
import dayjs from "dayjs";
import { Scheduler } from "@aldabil/react-scheduler";
import { pl } from "date-fns/locale";
import calendar_translations from "helpers/calendar_translations";
import { useCalendar } from "context/providers/calendar";
import { Select } from "antd";
import { useWorkers } from "context/providers/workers";

const IssueMonthCalendar = () => {
  const {
    update_count,
    holidays,
    setDayCalendarDate,
    getCalendarIssues,
    total_issues_count,
    total_tasks_count,
    calendar_worker_id,
    setCalendarWorkerId,
  } = useCalendar();
  const { workers_list_options } = useWorkers();
  const month_ref = useRef();
  const locale = {
    ...pl,
  };

  const day_name = month_ref?.current?.scheduler
    ?.selectedDate
    ? dayjs(
      month_ref.current.scheduler.selectedDate
    ).format("MMMM YYYY")
    : "-";

  return (
    <div className="calendar-issue-container month_calendar">
      <style
        dangerouslySetInnerHTML={{
          __html: `:root {
            --month-value: "${day_name}";
          }`,
        }}
      />
      <div className="calendar-issue-row">
        <div className="calendar-issue-select">
          <Select
            style={{ minWidth: 200 }}
            value={calendar_worker_id}
            placeholder="Wybierz pracownika"
            filterOption={false}
            onChange={(id) =>
              setCalendarWorkerId(id || null)
            }
            options={[
              { label: "Wszyscy", value: null },
              ...(workers_list_options || []),
            ]}
          />
        </div>
        <div className="calendar-issue-count">
          <p>Ilość zgłoszeń: {total_issues_count}</p>
          <p>Ilość zadań: {total_tasks_count}</p>
        </div>
      </div>
      <Scheduler
        ref={month_ref}
        key={[calendar_worker_id, update_count]}
        agenda={false}
        disableViewer={true}
        locale={locale}
        height={800}
        view="month"
        deletable={false}
        draggable={false}
        editable={false}
        hourFormat={24}
        month={{
          weekStartOn: 1,
          cellRenderer: ({ start, end, day, ...rest }) => {
            const is_today =
              dayjs().format("DD-MM") ===
              dayjs(start).format("DD-MM");
            const week_day = dayjs(start).format("dd");
            const is_weekend = ["So", "Nd"].includes(
              week_day
            );
            const is_same_month = dayjs(day).format("MM") ===
              dayjs(start).format("MM") || dayjs(day).format("MM") ===
              dayjs(end).format("MM")

            const day_holidays = holidays.filter(
              ({ date }) =>
                date === dayjs(start).format("YYYY-MM-DD")
            );
            const is_holiday = day_holidays?.length > 0;

            return (
              <div
                {...rest}
                className={`month-cell${is_today ? " today" : ""
                  }${is_holiday ? " holiday" : ""}${is_weekend ? " weekend" : ""
                  } ${!is_same_month ? 'disabled' : ''}`}
              >
                <span className="month-cell__day">
                  {dayjs(start).format("DD")}
                </span>
              </div>
            );
          },
        }}
        disableViewNavigator={true}
        getRemoteEvents={e => getCalendarIssues({
          ...e, day: month_ref?.current?.scheduler
            ?.selectedDate
        })}
        translations={calendar_translations}
        onCellClick={(cell_date) =>
          setDayCalendarDate(dayjs(cell_date).toDate())
        }
        onEventClick={({ start }) =>
          setDayCalendarDate(dayjs(start).toDate())
        }
      />
    </div>
  );
};

export default IssueMonthCalendar;
