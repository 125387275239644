import { Layout } from "antd";
import Router from "./components/Router";
import EmergencyModal from "./components/EmergencyModal";
import MainHeader from "./components/MainHeader";

const Main = () => (
  <Layout
    style={{
      minHeight: "100vh",
    }}
  >
    <MainHeader />
    <Layout.Content
      style={{
        overflow: "initial",
        padding: "0 12px 12px",
      }}
    >
      <Router />
    </Layout.Content>
    <Layout.Footer className="main-footer">
      <div className="box-left" />
      <div className="box-center">
        <svg
          width="165"
          height="30"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 165.13 30.07"
        >
          <g>
            <g>
              <path
                d="m48.35,4.35c-.24-.44-.58-.77-1.02-1-.44-.23-.97-.35-1.57-.35-1.04,0-1.87.34-2.5,1.02-.63.68-.94,1.59-.94,2.73,0,1.21.33,2.16.98,2.84s1.56,1.02,2.71,1.02c.79,0,1.45-.2,2-.6.54-.4.94-.98,1.19-1.73h-4.07v-2.36h6.98v2.98c-.24.8-.64,1.55-1.21,2.23s-1.29,1.24-2.17,1.67c-.88.43-1.86.64-2.96.64-1.3,0-2.46-.28-3.48-.85-1.02-.57-1.81-1.36-2.38-2.37s-.85-2.17-.85-3.47.28-2.46.85-3.48c.57-1.02,1.36-1.81,2.37-2.38,1.01-.57,2.17-.85,3.47-.85,1.58,0,2.91.38,3.99,1.14s1.8,1.82,2.15,3.17h-3.55Z"
                fill="#fff"
                stroke="0"
              />
              <path
                d="m56.73,12.63c-1.03-.58-1.85-1.38-2.46-2.41s-.91-2.19-.91-3.48.3-2.45.91-3.47c.61-1.03,1.43-1.83,2.46-2.4,1.03-.58,2.17-.86,3.41-.86s2.37.29,3.41.86c1.03.58,1.85,1.38,2.44,2.4.59,1.03.89,2.18.89,3.47s-.3,2.45-.9,3.48-1.41,1.84-2.44,2.41c-1.03.58-2.16.86-3.4.86s-2.37-.29-3.41-.86Zm5.93-3.12c.63-.7.95-1.63.95-2.78s-.32-2.09-.95-2.79c-.63-.69-1.47-1.04-2.52-1.04s-1.91.34-2.54,1.03c-.63.69-.95,1.62-.95,2.8s.32,2.09.95,2.79c.63.69,1.48,1.04,2.54,1.04s1.89-.35,2.52-1.05Z"
                fill="#fff"
                stroke="0"
              />
              <path
                d="m71.87,10.88h4.2v2.48h-7.41V.19h3.21v10.7Z"
                fill="#fff"
                stroke="0"
              />
              <path
                d="m80.82,2.76v2.66h4.3v2.48h-4.3v2.89h4.86v2.57h-8.07V.19h8.07v2.57h-4.86Z"
                fill="#fff"
                stroke="0"
              />
              <path
                d="m99.54,13.36h-3.21l-5.37-8.13v8.13h-3.21V.19h3.21l5.37,8.16V.19h3.21v13.17Z"
                fill="#fff"
                stroke="0"
              />
              <path
                d="m105.08.19v13.17h-3.21V.19h3.21Z"
                fill="#fff"
                stroke="0"
              />
              <path
                d="m110.62,2.76v2.66h4.3v2.48h-4.3v2.89h4.86v2.57h-8.07V.19h8.07v2.57h-4.86Z"
                fill="#fff"
                stroke="0"
              />
              <path
                d="m135.69.19l-3.43,13.17h-3.88l-2.1-8.67-2.18,8.67h-3.88L116.87.19h3.43l1.89,9.59L124.54.19h3.53l2.25,9.59,1.91-9.59h3.45Z"
                fill="#fff"
                stroke="0"
              />
              <path
                d="m139.48,13.02c-.76-.31-1.37-.78-1.83-1.39-.46-.61-.7-1.35-.72-2.21h3.41c.05.49.22.86.51,1.12.29.26.66.38,1.13.38s.85-.11,1.13-.33c.27-.22.41-.52.41-.91,0-.33-.11-.59-.33-.81-.22-.21-.49-.39-.81-.53-.32-.14-.77-.29-1.36-.47-.85-.26-1.55-.53-2.08-.79-.54-.26-1-.65-1.39-1.16-.39-.51-.58-1.18-.58-2.01,0-1.23.44-2.19,1.33-2.88.89-.69,2.04-1.04,3.47-1.04s2.62.35,3.51,1.04,1.36,1.66,1.43,2.9h-3.47c-.03-.43-.18-.76-.47-1-.29-.24-.66-.37-1.11-.37-.39,0-.7.1-.94.31-.24.21-.36.5-.36.89,0,.43.2.76.6.99.4.24,1.03.49,1.88.77.85.29,1.54.56,2.07.83.53.26.99.64,1.38,1.14.39.5.58,1.14.58,1.93s-.19,1.43-.57,2.05c-.38.61-.94,1.1-1.66,1.46-.73.36-1.58.54-2.57.54s-1.83-.16-2.59-.47Z"
                fill="#fff"
                stroke="0"
              />
              <path
                d="m156.48,13.36l-4.43-5.82v5.82h-3.21V.19h3.21v5.78l4.39-5.78h3.77l-5.1,6.46,5.29,6.72h-3.92Z"
                fill="#fff"
                stroke="0"
              />
              <path
                d="m165.13.19v13.17h-3.21V.19h3.21Z"
                fill="#fff"
                stroke="0"
              />
            </g>
            <g>
              <path
                d="m41.99,17.03v13.04h-2.14v-13.04h2.14Z"
                fill="#fff"
                stroke="0"
              />
              <path
                d="m165.13,24.33h-35.2v-1.74h35.2v1.74Z"
                fill="#fff"
                stroke="0"
              />
              <path
                d="m55.52,30.07h-2.14l-6.44-9.74v9.74h-2.14v-13.06h2.14l6.44,9.72v-9.72h2.14v13.06Z"
                fill="#fff"
                stroke="0"
              />
              <path
                d="m66.76,17.03v1.75h-3.47v11.3h-2.14v-11.3h-3.49v-1.75h9.1Z"
                fill="#fff"
                stroke="0"
              />
              <path
                d="m71.06,18.75v3.83h4.5v1.75h-4.5v4h5.07v1.75h-7.21v-13.06h7.21v1.75h-5.07Z"
                fill="#fff"
                stroke="0"
              />
              <path
                d="m85.53,30.07l-3-5.22h-1.63v5.22h-2.14v-13.04h4.5c1,0,1.85.18,2.54.53.69.35,1.21.82,1.56,1.41.34.59.52,1.25.52,1.97,0,.85-.25,1.62-.74,2.32s-1.25,1.17-2.28,1.42l3.23,5.4h-2.55Zm-4.64-6.92h2.36c.8,0,1.4-.2,1.81-.6.41-.4.61-.94.61-1.61s-.2-1.2-.6-1.59c-.4-.38-1.01-.57-1.82-.57h-2.36v4.37Z"
                fill="#fff"
                stroke="0"
              />
              <path
                d="m92.75,28.34h4.41v1.73h-6.55v-13.04h2.14v11.32Z"
                fill="#fff"
                stroke="0"
              />
              <path
                d="m101.08,17.03v13.04h-2.14v-13.04h2.14Z"
                fill="#fff"
                stroke="0"
              />
              <path
                d="m114.61,30.07h-2.14l-6.44-9.74v9.74h-2.14v-13.06h2.14l6.44,9.72v-9.72h2.14v13.06Z"
                fill="#fff"
                stroke="0"
              />
              <path
                d="m119.57,18.75v3.83h4.5v1.75h-4.5v4h5.07v1.75h-7.21v-13.06h7.21v1.75h-5.07Z"
                fill="#fff"
                stroke="0"
              />
            </g>
          </g>
          <g>
            <path
              d="m17.59,18.65v11.42H0V10.65c0,2.03.48,3.85,1.42,5.46.95,1.61,2.24,2.88,3.85,3.79,1.62.91,3.41,1.37,5.37,1.37,1.81,0,3.46-.41,4.95-1.22.73-.4,1.41-.87,2-1.4Z"
              fill="#f7a406"
              strokeWidth="0"
            />
            <polygon
              points="28.83 30.07 20.93 30.07 20.93 20.95 28.83 30.07"
              fill="#f7a406"
              strokeWidth="0"
            />
            <path
              d="m10.65,0C12.86,0,14.83.55,16.55,1.63c1.73,1.09,2.98,2.63,3.76,4.63h-4.11c-.53-1.04-1.28-1.82-2.24-2.35-.95-.53-2.07-.8-3.32-.8-1.38,0-2.61.31-3.69.93-1.08.62-1.92,1.5-2.54,2.63-.61,1.14-.91,2.46-.91,3.95s.3,2.83.91,3.97c.61,1.15,1.46,2.03,2.54,2.65,1.07.62,2.3.93,3.69.93,1.86,0,3.36-.52,4.51-1.55,1.16-1.04,1.87-2.45,2.13-4.23h-7.81v-2.72h11.46v9.3l7.93-9.3h4.19l-8.86,10.21,8.87,10.18V0H10.65Zm18.49,1.47h-.45v1.35s-.66,0-.66,0v-1.35s-.44,0-.44,0v-.53h1.55v.53Zm2.81,1.35h-.61l-.02-1.08-.44,1.08h-.45l-.44-1.13v1.13h-.58V.94h.89l.38,1.08.44-1.08h.82v1.89Z"
              fill="#f7a406"
              strokeWidth="0"
            />
          </g>
        </svg>
      </div>
      <div className="box-right">
        <EmergencyModal />
      </div>
    </Layout.Footer>
  </Layout>
);

export default Main;
