import range from "helpers/range";

const disabled_note_datetime = () => ({
  disabledHours: () => [...range(0, 6), ...range(21, 24)],
  disabledMinutes: () => [
    ...range(1, 5),
    ...range(6, 10),
    ...range(11, 15),
    ...range(16, 20),
    ...range(21, 25),
    ...range(26, 30),
    ...range(31, 35),
    ...range(36, 40),
    ...range(41, 45),
    ...range(46, 50),
    ...range(51, 55),
    ...range(56, 60),
  ],
});

export default disabled_note_datetime;
