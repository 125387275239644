import { Avatar } from "antd";
import EllipsisText from "components/EllipsisText";
import formatPhone from "helpers/phone_formatter";
import { Link } from "react-router-dom";

const columns = [
  {
    title: "Pozycja",
    dataIndex: "position",
    sortable: true,
    searchable: false,
  },
  {
    title: "Imię",
    dataIndex: "first_name",
    sortable: true,
    searchable: true,
    search_method: "matches",
    render: (_, { id, initials, first_name, color }) => (
      <>
        <Avatar
          style={{
            backgroundColor: color,
            marginRight: 10,
          }}
        >
          {initials}
        </Avatar>
        <Link to={`/workers/${id}`}>
          <EllipsisText text={first_name} limit={30} />
        </Link>
      </>
    ),
  },
  {
    title: "Nazwisko",
    dataIndex: "last_name",
    sortable: true,
    searchable: true,
    search_method: "matches",
    render: (_, { id, last_name }) => (
      <Link to={`/workers/${id}`}>
        <EllipsisText text={last_name} limit={30} />
      </Link>
    ),
  },
  {
    title: "E-mail",
    dataIndex: "email",
    sortable: true,
    searchable: true,
    search_method: "matches",
    render: (_, { id, email }) => (
      <Link to={`/workers/${id}`}>
        <EllipsisText text={email} limit={30} />
      </Link>
    ),
  },
  {
    title: "Telefon",
    dataIndex: "phone",
    sortable: true,
    searchable: true,
    search_method: "matches",
    search_type: 'number',
    render: (_, { phone }) => formatPhone(phone),
  },
  {
    title: "Status",
    dataIndex: "is_active",
    sortable: true,
    searchable: false,
    render: (_, { is_active }) =>
      is_active ? "Aktywny" : "Nieaktywny",
  },
];

export default columns;
