import React from "react";
import Breadcrumbs from "./components/Breadcrumbs";
import { Button, Row, Space } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const MainLayout = ({
  children,
  breadcrumbs = [],
  buttons,
  back_href,
}) => {
  const navigate = useNavigate();
  return (
    <>
      <Row justify="space-between" align="middle">
        <Space>
          {back_href && (
            <Button
              onClick={() => navigate(back_href)}
              style={{ backgroundColor: "#e8af46" }}
              type="primary"
              icon={<ArrowLeftOutlined />}
            >
              Wstecz
            </Button>
          )}
          <Breadcrumbs data={breadcrumbs} />
        </Space>
        {buttons}
      </Row>
      {children}
    </>
  );
};

export default MainLayout;
