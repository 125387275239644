import { useMutation } from "@tanstack/react-query";
import { notification } from "antd";

import { api, handleErrors } from "helpers/api";
import handleFormError from "helpers/handleFormError";

const useUpdate = ({ path, onSuccess, form }) => {
  const {
    isLoading: is_saving,
    mutate: update,
    mutateAsync: updateAsync,
  } = useMutation((values) => api.put(path, values), {
    onSuccess: ({ data }) => {
      notification.success({
        message: "Zaktualizowano pomyślnie",
      });
      onSuccess?.(data);
    },
    onError: (err) => {
      notification.error({ message: handleErrors(err) });
      const form_errors = handleFormError(
        err?.response?.data?.errors
      );
      form.setFields(form_errors);
    },
  });

  return { is_saving, update, updateAsync };
};

export default useUpdate;
