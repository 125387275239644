import React, { useState } from "react";

import { Button, Card, List } from "antd";

import AddDeviceModal from "./components/AddDeviceModal";
import Item from "./components/Item";

const WorkerDevices = ({ data = [], refetch, refs }) => {
  const [is_open_add_worker_modal, setOpenAddDeviceModal] =
    useState(false);

  const can_add_device = data?.length < 2;

  return (
    <Card
      style={{ marginBottom: 24 }}
      title="Dostępne urządzenia"
      bordered={false}
      bodyStyle={{ padding: 0 }}
      extra={
        can_add_device && (
          <Button
            onClick={() => setOpenAddDeviceModal(true)}
          >
            Przyznaj dostęp
          </Button>
        )
      }
    >
      {can_add_device && is_open_add_worker_modal && (
        <AddDeviceModal
          refetch={refetch}
          handleClose={() => setOpenAddDeviceModal(false)}
        />
      )}
      <List
        dataSource={data?.sort((a, b) => a.id - b.id)}
        renderItem={(props) => (
          <Item {...props} refetch={refetch} refs={refs} />
        )}
      />
    </Card>
  );
};

export default WorkerDevices;
