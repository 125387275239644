import React from "react";
import {
  Modal,
  Descriptions,
  Avatar,
  Card,
  Space,
  Tag,
  Divider,
} from "antd";

import useGet from "hooks/useGet";
import formatPhone from "helpers/phone_formatter";
import { getIssueStatus } from "helpers/options/issue_statuses";
import { getIssueKind } from "helpers/options/issue_kinds";
import dayjs from "dayjs";
import Comments from "components/Comments";
import { useIssues } from "context/providers/issues";
import TimeAgo from "react-timeago";
import polishStrings from "react-timeago/lib/language-strings/pl";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";

const formatter = buildFormatter(polishStrings);

const IssuePreviewModal = () => {
  const { preview_id, setPreviewId, refetchAll } =
    useIssues();

  const { data, refetch } = useGet({
    path: `/issues/${preview_id}`,
    query: ["issue", preview_id],
    options: {
      enabled: Boolean(preview_id),
    },
  });

  const handleClose = () => {
    setPreviewId(null);
    refetchAll();
  };

  return (
    <Modal
      width={800}
      maskClosable={false}
      title="Podgląd zgłoszenia"
      open={Boolean(preview_id)}
      onOk={(e) => {
        e.stopPropagation();
        handleClose();
      }}
      onCancel={(e) => {
        e.stopPropagation();
        handleClose();
      }}
      cancelButtonProps={{ style: { display: "none" } }}
      okText="Zamknij"
    >
      <Descriptions
        style={{ marginTop: 20 }}
        size="small"
        bordered
        items={[
          {
            label: "Identyfikator",
            children: <strong>{data?.identifier}</strong>,
            span: 3,
          },
          {
            label: "Pracownik",
            children: data?.worker?.id ? (
              <Space>
                <Avatar
                  size="small"
                  style={{
                    backgroundColor: data.worker.color,
                  }}
                >
                  {data.worker.initials}
                </Avatar>
                {data.worker.full_name}
              </Space>
            ) : (
              "-"
            ),
            span: 3,
          },

          {
            label: "Adres",
            children: data?.client_address,
            span: 3,
          },
          {
            label: "Telefon",
            children: (
              <a href={`tel:${data?.client_phone}`}>
                {formatPhone(data?.client_phone)}
              </a>
            ),
            span: 3,
          },
          {
            label: "Klient",
            children: data?.client_name,
            span: 3,
          },
          {
            label: "Status",
            children: getIssueStatus(data?.status),
            span: 3,
          },
          {
            label: "Rodzaj",
            children: getIssueKind(data?.kind),
            span: 3,
          },
          {
            label: "Pilne",
            children: data?.urgent ? (
              <Tag color="#cd201f">Tak</Tag>
            ) : (
              "Nie"
            ),
            span: 3,
          },
          {
            label: "Odwiedzający",
            children: data?.issue_visitors?.length > 0 && (
              <Avatar.Group>
                {data.issue_visitors.map(
                  ({ id, user: { initials, color } }) => (
                    <Avatar
                      key={id}
                      style={{
                        backgroundColor: color,
                      }}
                    >
                      {initials}
                    </Avatar>
                  )
                )}
              </Avatar.Group>
            ),
            span: 3,
          },
        ]}
      />
      <Divider
        style={{
          width: "50%",
          minWidth: "50%",
          margin: "24px auto",
        }}
      />
      <Descriptions
        size="small"
        bordered
        items={[
          {
            label: "Data zgłoszenia",
            children: data?.created_at
              ? dayjs(data.created_at).format(
                  "DD-MM-YYYY HH:mm"
                )
              : "-",
            span: 3,
          },
          {
            label: "Data umówienia",
            children: data?.service_date ? (
              <>
                {`${dayjs(data.service_date).format(
                  "DD-MM-YYYY HH:mm"
                )} - ${dayjs(
                  data.service_date_delayed
                ).format("HH:mm")}`}
                {" - "}
                <strong>
                  <TimeAgo
                    date={data.service_date}
                    formatter={formatter}
                  />
                </strong>
              </>
            ) : (
              "-"
            ),
            span: 3,
          },
          {
            label: "Data przypisana",
            children: data?.assigned_at
              ? dayjs(data.assigned_at).format(
                  "DD-MM-YYYY HH:mm"
                )
              : "-",
            span: 3,
          },
          {
            label: "Data rozpoczęcia",
            children: data?.started_at
              ? dayjs(data.started_at).format(
                  "DD-MM-YYYY HH:mm"
                )
              : "-",
            span: 3,
          },
        ]}
      />
      <Card
        style={{ margin: "24px 0" }}
        title="Opis zgłoszenia"
        headStyle={{
          backgroundColor: "rgba(0, 0, 0, 0.02)",
        }}
      >
        {data?.content}
      </Card>
      <Comments
        issue_id={data?.id}
        data={data?.issue_comments}
        refetch={refetch}
        gray={true}
      />
    </Modal>
  );
};

export default IssuePreviewModal;
