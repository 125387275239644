import React from "react";

import MainLayout from "components/MainLayout";
import MainList from "components/MainList";

import columns from "./columns";
import CreateNoteModal from "components/create_modals/CreateNoteModal";
import { useWorkers } from "context/providers/workers";

const NotesList = () => {
  const { workers_list_options } = useWorkers();
  const columns_data = columns(workers_list_options);
  return (
    <MainLayout
      breadcrumbs={[{ name: "Notatki" }]}
      buttons={<CreateNoteModal />}
    >
      <MainList
        columns={columns_data}
        query_name="notes"
        query_path="/notes"
        table_path="/notes"
        query_context="index"
      />
    </MainLayout>
  );
};

export default NotesList;
