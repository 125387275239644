import { useMutation } from "@tanstack/react-query";
import { notification } from "antd";

import { api, handleErrors } from "helpers/api";

const useDelete = ({ path, onSuccess }) => {
  const { isLoading: is_deleting, mutate: _delete } =
    useMutation(() => api.delete(path), {
      onSuccess: () => {
        notification.success({
          message: "Usunięto pomyślnie",
        });
        onSuccess?.();
      },
      onError: (err) => {
        notification.error({ message: handleErrors(err) });
      },
    });

  return { is_deleting, _delete };
};

export default useDelete;
