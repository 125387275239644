import React, { useState } from "react";
import { Modal, Form, Input, Button, Checkbox } from "antd";

import useCreate from "hooks/useCreate";
import useFormErrors from "hooks/useFormErrors";
import { useQueryClient } from "@tanstack/react-query";
import { imei_regexp, name_regexp } from "helpers/regexp";

const CreateDeviceModal = () => {
  const queryClient = useQueryClient();

  const [is_open_create_modal, setOpenCreateModal] =
    useState(false);

  const [form] = Form.useForm();

  const { pristine, has_error, onFieldsChange } =
    useFormErrors();

  const { is_saving, create } = useCreate({
    path: "/devices",
    form,
    onSuccess: () =>
      queryClient.refetchQueries({
        queryKey: ["devices"],
      }),
  });

  const handleOk = () => form.submit();
  const handleClose = () => setOpenCreateModal(false);

  return (
    <>
      <Button
        type="primary"
        htmlType="button"
        onClick={() => setOpenCreateModal(true)}
      >
        Dodaj urządzenie
      </Button>
      <Modal
        maskClosable={false}
        title="Dodaj urządzenie"
        open={is_open_create_modal}
        onOk={handleOk}
        onCancel={handleClose}
        cancelText="Anuluj"
        okText="Dodaj"
        okButtonProps={{
          disabled: pristine || has_error,
          loading: is_saving,
        }}
      >
        <Form
          form={form}
          layout="vertical"
          onFieldsChange={onFieldsChange}
          initialValues={{
            imei: "",
            name: "",
            is_active: true,
          }}
          onFinish={async (values) => {
            try {
              await create(values);
              form.resetFields();
              handleClose();
            } catch (error) {
              console.log(error);
            }
          }}
        >
          <Form.Item
            label="Nazwa"
            name="name"
            hasFeedback
            rules={[
              {
                required: true,
                message: "Nazwa jest wymagana!",
              },
              {
                pattern: name_regexp,
                message:
                  "Pole zawiera niedozwolone znaki!!",
              },
            ]}
          >
            <Input placeholder="Nazwa" maxLength={30} />
          </Form.Item>
          <Form.Item
            name="imei"
            label="IMEI"
            hasFeedback
            rules={[
              {
                required: true,
                message: "IMEI jest wymagany!",
              },
              {
                pattern: imei_regexp,
                message:
                  "Pole zawiera niedozwolone znaki!!",
              },
            ]}
          >
            <Input placeholder="IMEI" maxLength={30} />
          </Form.Item>
          <Form.Item
            name="is_active"
            valuePropName="checked"
          >
            <Checkbox>Aktywny</Checkbox>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default CreateDeviceModal;
