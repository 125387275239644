import React from "react";
import MainLayout from "components/MainLayout";
import MainList from "components/MainList";

import columns from "./columns";
import CreateDeviceModal from "components/create_modals/CreateDeviceModal";
import WorkerTabs from "components/WorkerTabs";

const DeviceList = () => {
  return (
    <MainLayout
      breadcrumbs={[{ name: "Urządzenia" }]}
      buttons={<CreateDeviceModal />}
    >
      <WorkerTabs current="devices" />
      <MainList
        columns={columns}
        query_name="devices"
        query_path="/devices"
        table_path="/devices"
        query_context="index"
      />
    </MainLayout>
  );
};

export default DeviceList;
