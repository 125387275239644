import useGet from "hooks/useGet";
import React, {
  useMemo,
  useContext,
  useState,
} from "react";
import {
  NumberParam,
  useQueryParam,
} from "use-query-params";

const SettlementContext = React.createContext();

function SettlementProvider({ children }) {
  const [issue_id, setIssueId] = useQueryParam("issue_id", NumberParam);

  const [page, setPage] = useState(1);
  const [worker_id, setWorkerId] = useState(null);
  const [has_more_issues, setHasMoreIssues] =
    useState(false);
  const [data, setData] = useState([]);
  const [is_loading, setLoading] = useState(false);

  const {
    is_loading: is_issue_loading,
    is_error: is_issue_error,
    data: issue_data,
    refetch: refetchIssue,
  } = useGet({
    path: `/issues/${issue_id}`,
    context: "settlement",
    query: ["issues", issue_id],
    options: {
      enabled: Boolean(issue_id),
    },
  });

  const updateListIssue = ({
    administration_billing_status,
    worker_billing_status,
    status
  }) => {
    const data_copy = [...data];
    const index = data_copy.indexOf(
      data_copy.find(({ id }) => id === issue_id)
    );
    if (index > -1) {
      Object.assign(data_copy[index], {
        ...data_copy[index],
        administration_billing_status,
        worker_billing_status,
        status
      });
      setData(data_copy);
    }
  };

  const value = useMemo(() => {
    return {
      page,
      worker_id,
      has_more_issues,
      data,
      is_loading,
      is_issue_error,
      is_issue_loading,
      issue_id,
      issue_data,
      refetchIssue,
      setPage,
      setWorkerId,
      setHasMoreIssues,
      setData,
      setLoading,
      updateListIssue,
      setIssueId
    };
    // eslint-disable-next-line
  }, [
    page,
    worker_id,
    issue_id,
    is_issue_loading,
    issue_data,
  ]);

  return (
    <SettlementContext.Provider value={value}>
      {children}
    </SettlementContext.Provider>
  );
}

const useSettlement = () => useContext(SettlementContext);
export { useSettlement };
export default SettlementProvider;
