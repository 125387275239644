import React from "react";

import MainLayout from "components/MainLayout";
import MainList from "components/MainList";

import CreateWorkerInvitationModal from "components/create_modals/CreateWorkerInvitationModal";
import columns from "./columns";
import WorkerTabs from "components/WorkerTabs";

const WorkerList = () => {
  return (
    <MainLayout
      breadcrumbs={[{ name: "Pracownicy" }]}
      buttons={<CreateWorkerInvitationModal />}
    >
      <WorkerTabs current="workers" />
      <MainList
        columns={columns}
        query_name="workers"
        query_path="/workers"
        table_path="/workers"
        query_context="index"
        default_sort="position__asc"
      />
    </MainLayout>
  );
};

export default WorkerList;
