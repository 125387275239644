import React from "react";

import { List, Space, Tag } from "antd";

const Item = ({ is_active, worker }) => (
  <List.Item style={{ padding: "12px 24px" }}>
    <Space>
      <Tag color={is_active ? "green" : "red"}>
        {is_active ? "Aktywny" : "Zablokowany"}
      </Tag>
      <p>{worker?.full_name}</p>
    </Space>
    
  </List.Item>
);

export default Item;
