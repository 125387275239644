import { Button, Input, Popconfirm } from "antd";
import useCreate from "hooks/useCreate";
import { useState } from "react";

const EmergencyModal = () => {
  const [is_open, setOpen] = useState(false);
  const [content, setContent] = useState("");

  const { is_saving, create } = useCreate({
    path: "/emergency",
  });

  const handleClose = () => {
    setContent("");
    setOpen(false);
  };

  const handleSend = async () => {
    await create({ content });
    handleClose();
  };
  return (
    <>
      <Popconfirm
        cancelText="Anuluj"
        placement="topLeft"
        okText="Wyślij"
        icon={null}
        title="Wyślij informację o awarii"
        description={
          <Input.TextArea
            style={{ width: 500, marginBottom: 20 }}
            placeholder="Wiadomość"
            value={content}
            showCount
            onChange={({ target: { value } }) =>
              setContent(value)
            }
            maxLength={400}
            rows={9}
          />
        }
        open={is_open}
        onConfirm={handleSend}
        okButtonProps={{
          disabled: !content,
          loading: is_saving,
        }}
        onCancel={handleClose}
      >
        <Button
          type="primary"
          onClick={() => setOpen(true)}
        >
          Zgłoś awarię aplikacji
        </Button>
      </Popconfirm>
    </>
  );
};

export default EmergencyModal;
