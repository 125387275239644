import { Tag } from "antd";

export const worker_billing_status_filter_options = [
  { label: 'Brak', value: 'nil' },
  { label: 'Do rozliczenia z pracownikiem', value: 0 },
  { label: 'Rozliczono z pracownikiem', value: 1 }
]

export const administration_billing_status_filter_options = [
  { label: 'Brak', value: 'nil' },
  { label: 'Do rozliczenia z administracją', value: 0 },
  { label: 'Rozliczono z administracją', value: 1 }
]

const getWorkerBillingStatuses = (show_in_two_lines) => [
  {
    label: <Tag bordered>Brak</Tag>,
    value: null,
  },
  {
    label: (
      <Tag bordered color="error">
        Do rozliczenia {show_in_two_lines && <br />} z pracownikiem
      </Tag>
    ),
    value: "to_bill",
  },
  {
    label: (
      <Tag bordered color="success">
        Rozliczono {show_in_two_lines && <br />} z pracownikiem
      </Tag>
    ),
    value: "billed",
  },
];

const getAdministratorBillingStatuses = (show_in_two_lines) => [
  {
    label: <Tag bordered>Brak</Tag>,
    value: null,
  },
  {
    label: (
      <Tag bordered color="error">
        Do rozliczenia {show_in_two_lines && <br />} z administracją
      </Tag>
    ),
    value: "to_bill",
  },
  {
    label: (
      <Tag bordered color="success">
        Rozliczono {show_in_two_lines && <br />} z administracją
      </Tag>
    ),
    value: "billed",
  },
];

export const worker_billing_statuses = getWorkerBillingStatuses(true)
export const worker_billing_status_options = getWorkerBillingStatuses(false)


export const administrator_billing_statuses = getAdministratorBillingStatuses(true)
export const administrator_billing_status_options = getAdministratorBillingStatuses(false)

export const getWorkerBillingStatus = (val) =>
  worker_billing_statuses.find(
    ({ value }) => value === val
  )?.label;

export const getAdministratorBillingStatus = (val) =>
  administrator_billing_statuses.find(
    ({ value }) => value === val
  )?.label;
