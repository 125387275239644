import { Input } from "antd";

const NumericInput = ({
  title,
  value,
  onChange,
  onPressEnter,
  onBlur,
}) => {
  const handleChange = (e) => {
    const { value: inputValue } = e.target;
    const reg = /^-?\d*(\.\d*)?$/;
    if (reg.test(inputValue) || inputValue === "") {
      onChange(inputValue);
    }
  };

  const handleBlur = () => {
    let valueTemp = value;
    if (
      value.charAt(value.length - 1) === "." ||
      value === "-"
    ) {
      valueTemp = value.slice(0, -1);
    }
    const val = valueTemp.replace(/0*(\d+)/, "$1");
    onChange(val);
    onBlur();
  };

  return (
    <Input
      value={value}
      placeholder={title}
      onChange={handleChange}
      onBlur={handleBlur}
      onPressEnter={onPressEnter}
      maxLength={10}
    />
  );
};

export default NumericInput;
