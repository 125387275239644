import React from "react";

import { Button, List, Space, Tag } from "antd";
import useUpdate from "hooks/useUpdate";
import useDelete from "hooks/useDelete";
import ModalConfirm from "components/ModalConfirm";

const Item = ({ refs, id, is_active, device, refetch }) => {
  const {
    is_saving_worker_device,
    update: updateWorkerDevice,
  } = useUpdate({
    path: `/worker_devices/${id}`,
    onSuccess: refetch,
  });

  const { is_saving_device, update: updateDevice } =
    useUpdate({
      path: `/devices/${device.id}`,
      onSuccess: refetch,
    });

  const { is_deleting_device, _delete: deleteDevice } =
    useDelete({
      path: `/worker_devices/${id}`,
      onSuccess: refetch,
    });

  return (
    <List.Item
      className="devices-row"
      style={{ padding: "12px 24px" }}
      split
      actions={[
        <Button
          type="primary"
          loading={is_saving_device}
          danger={device.is_active}
          ref={refs[0]}
          onClick={() =>
            updateDevice({ is_active: !device.is_active })
          }
        >
          {!device.is_active ? "Aktywuj" : "Dezaktywuj"}
        </Button>,
        <Button
          ref={refs[1]}
          type="primary"
          loading={is_saving_worker_device}
          danger={is_active}
          disabled={!device.is_active}
          onClick={() =>
            updateWorkerDevice({ is_active: !is_active })
          }
        >
          {is_active ? "Zablokuj" : "Odblokuj"}
        </Button>,
        <ModalConfirm
          button_text="Usuń"
          handleConfirm={deleteDevice}
          loading={is_deleting_device}
          text={
            <p>
              Czy na pewno chcesz usunąć urządzenie{" "}
              <strong>{device?.imei}</strong>
            </p>
          }
        />,
      ]}
    >
      <Space  className="devices-row__content">
        {device?.is_active ? (
          <Tag color={is_active ? "green" : "red"}>
            {is_active ? "Dostępne" : "Zablokowany"}
          </Tag>
        ) : (
          <Tag color="red">Urządzenie nieaktywne</Tag>
        )}
        <a
          href={`/devices/${device?.id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {device?.name || "brak nazwy"} [{device?.imei}]
        </a>
      </Space>
    </List.Item>
  );
};

export default Item;
