import { Link } from "react-router-dom";
import EllipsisText from "components/EllipsisText";
import formatPhone from "helpers/phone_formatter";

const columns = [
  {
    title: "Telefon",
    dataIndex: "phone",
    sortable: true,
    searchable: true,
    search_method: "matches",
    search_type: "number",
    render: (_, { phone }) => formatPhone(phone),
  },
  {
    title: "Adres",
    dataIndex: "street",
    sortable: false,
    searchable: true,
    search_method: "matches",
    render: (
      _,
      { street, building_number, apartment_number }
    ) => (
      <EllipsisText
        text={`${street} ${building_number}${
          apartment_number ? `/${apartment_number}` : ""
        }`}
        limit={40}
      />
    ),
  },
  {
    title: "Imię",
    dataIndex: "first_name",
    sortable: true,
    searchable: true,
    search_method: "matches",

    render: (_, { id, first_name }) => (
      <Link to={`/clients/${id}`}>
        <EllipsisText text={first_name} limit={20} />
      </Link>
    ),
  },
  {
    title: "Nazwisko",
    dataIndex: "last_name",
    sortable: true,
    searchable: true,
    search_method: "matches",
    render: (_, { id, last_name }) => (
      <Link to={`/clients/${id}`}>
        <EllipsisText text={last_name} limit={20} />
      </Link>
    ),
  },
  {
    title: "Miasto",
    dataIndex: "city",
    sortable: true,
    searchable: true,
    search_method: "matches",
    render: (_, { city }) => (
      <EllipsisText text={city} limit={20} />
    ),
  },
  {
    title: "Kod pocztowy",
    dataIndex: "post_code",
    sortable: true,
    searchable: true,
    search_method: "matches",
    render: (_, { post_code }) => (
      <EllipsisText text={post_code} limit={7} />
    ),
  },
];

export default columns;
