import dayjs from "dayjs";
import React, {
  useMemo,
  useContext,
  useState,
} from "react";
import { api } from "helpers/api";
import useGet from "hooks/useGet";

const IssueCalendarContext = React.createContext();

function IssueCalendarProvider({
  issue_id,
  issue_data,
  children,
  refetch,
}) {
  const [is_open_calendar, setOpenCalendar] =
    useState(false);
  const [date, setDate] = useState(false);
  const [holidays, setHolidays] = useState([]);
  const [calendar_worker_id, setCalendarWorkerId] =
    useState(null);

  const is_open_day_calendar = !!date;

  const getCalendarIssues = async ({ start, end }) => {
    const start_date = dayjs(start).format();
    const end_date = dayjs(end).format();
    try {
      const { data } = await api.get(
        `/issues/calendar?start_date=${start_date}&end_date=${end_date}&worker_id=${calendar_worker_id}`
      );
      setHolidays(data.holidays);
      return data?.data?.map(
        ({ date, issues_count, tasks_count }) => ({
          event_id: date,
          start: dayjs(date).toDate(),
          end: dayjs(date).toDate(),
          title: (
            <>
              {issues_count} - zgłoszenia <br />
              {tasks_count} - zadania
            </>
          ),
        })
      );
    } catch (error) {
      return [];
    }
  };

  const {
    is_loading: is_loading_day_events,
    data: day_events = [],
    refetch: refetchDayEvents,
  } = useGet({
    query: ["day_events", date, calendar_worker_id],
    path: "/issues",
    context: "calendar_day",
    search: {
      date,
      per_page: 20,
      ...(calendar_worker_id
        ? { "q[worker_id_eq]": calendar_worker_id }
        : {}),
      "q[service_date_gteq]": dayjs(date)
        .startOf("date")
        .format("DD-MM-YYYY HH:mm"),
      "q[service_date_lteq]": dayjs(date)
        .endOf("date")
        .format("DD-MM-YYYY HH:mm"),
    },
    options: {
      enabled: !!date,
      refetchOnWindowFocus: false,
    },
  });

  const {
    is_loading: is_loading_tasks,
    data: day_tasks = [],
    refetch: refetchDayTasks,
  } = useGet({
    query: ["day_tasks", date],
    path: "/tasks",
    search: {
      date: dayjs(date).format("DD-MM-YYYY HH:mm"),
    },
    options: {
      enabled: !!date,
      refetchOnWindowFocus: false,
    },
  });

  const { data: day_holidays = [] } = useGet({
    query: ["day_holidays", date],
    path: "/holidays",
    search: {
      date_start: dayjs(date)
        .startOf("date")
        .format("DD-MM-YYYY HH:mm"),

      date_end: dayjs(date)
        .endOf("date")
        .format("DD-MM-YYYY HH:mm"),
    },
    options: {
      enabled: !!date,
      refetchOnWindowFocus: false,
    },
  });

  const value = useMemo(() => {
    return {
      date,
      is_open_calendar,
      is_open_day_calendar,
      issue_id,
      issue_data,
      day_events,
      day_tasks,
      day_holidays,
      holidays,
      calendar_worker_id,
      getCalendarIssues,
      setOpenCalendar,
      refetchDayEvents,
      refetchDayTasks,
      setDate,
      refetch,
      setCalendarWorkerId,
    };
    // eslint-disable-next-line
  }, [
    issue_id,
    day_events,
    day_tasks,
    is_loading_tasks,
    is_loading_day_events,
    is_open_calendar,
    date,
    day_holidays,
    calendar_worker_id,
  ]);

  return (
    <IssueCalendarContext.Provider value={value}>
      {children}
    </IssueCalendarContext.Provider>
  );
}

const useIssueCalendar = () =>
  useContext(IssueCalendarContext);
export { useIssueCalendar };
export default IssueCalendarProvider;
