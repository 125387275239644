import React, { useState } from "react";
import { Col, Row, Modal, Form, Input, Button } from "antd";

import useCreate from "hooks/useCreate";

import { MaskedInput } from "antd-mask-input";
import useFormErrors from "hooks/useFormErrors";
import {
  name_regexp,
  street_regexp,
  building_number_regexp,
  nip_regexp,
} from "helpers/regexp";
import { useQueryClient } from "@tanstack/react-query";

const CreateClientModal = () => {
  const queryClient = useQueryClient();

  const [is_open_create_modal, setOpenCreateModal] =
    useState(false);

  const [form] = Form.useForm();

  const { pristine, has_error, onFieldsChange } =
    useFormErrors();
  const { is_saving, create } = useCreate({
    path: "/clients",
    form,
    onSuccess: () =>
      queryClient.refetchQueries({ queryKey: ["clients"] }),
  });

  const handleOk = () => form.submit();
  const handleClose = () => setOpenCreateModal(false);

  return (
    <>
      <Button
        type="primary"
        htmlType="button"
        onClick={() => setOpenCreateModal(true)}
      >
        Dodaj klienta
      </Button>
      <Modal
        maskClosable={false}
        title="Dodaj klienta"
        open={is_open_create_modal}
        onOk={handleOk}
        onCancel={handleClose}
        cancelText="Anuluj"
        okText="Dodaj"
        okButtonProps={{
          disabled: pristine || has_error,
          loading: is_saving,
        }}
      >
        <Form
          form={form}
          layout="vertical"
          onFieldsChange={onFieldsChange}
          initialValues={{
            first_name: "",
            last_name: "",
            phone: "",
            email: "",
            street: "",
            building_number: "",
            city: "",
            post_code: "",
          }}
          onFinish={async (values) => {
            try {
              await create(values);
              form.resetFields();
              handleClose();
            } catch (error) {
              console.log(error);
            }
          }}
        >
          <Form.Item
            label="Imię"
            name="first_name"
            hasFeedback
            rules={[
              {
                required: true,
                message: "Imię jest wymagane!",
              },
              {
                pattern: name_regexp,
                message:
                  "Pole zawiera niedozwolone znaki!!",
              },
            ]}
          >
            <Input placeholder="Imię" maxLength={60} />
          </Form.Item>
          <Form.Item
            label="Nazwisko"
            name="last_name"
            hasFeedback
            rules={[
              {
                required: true,
                message: "Nazwisko jest wymagane!",
              },
              {
                pattern: name_regexp,
                message:
                  "Pole zawiera niedozwolone znaki!!",
              },
            ]}
          >
            <Input placeholder="Nazwisko" maxLength={60} />
          </Form.Item>
          <Form.Item
            label="Telefon"
            name="phone"
            required
            hasFeedback
            rules={[
              () => ({
                validator(_, value) {
                  if (!value) {
                    return Promise.reject(
                      new Error("Numer telefonu wymagany!")
                    );
                  }
                  if (value.includes("_")) {
                    return Promise.reject(
                      new Error(
                        "Numer telefonu niepoprawny!"
                      )
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <MaskedInput mask="000000000" />
          </Form.Item>
          <Form.Item
            label="E-mail"
            name="email"
            hasFeedback
            rules={[
              {
                type: "email",
                message: "Niepoprawny e-mail",
              },
            ]}
          >
            <Input placeholder="E-mail" maxLength={60} />
          </Form.Item>
          <Row gutter={24}>
            <Col xs={12}>
              <Form.Item
                label="Ulica"
                name="street"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Ulica jest wymagana!",
                  },

                  {
                    pattern: street_regexp,
                    message:
                      "Pole zawiera niedozwolone znaki!!",
                  },
                ]}
              >
                <Input
                  placeholder="Ulica"
                  maxLength={100}
                />
              </Form.Item>
            </Col>
            <Col xs={6}>
              <Form.Item
                label="Nr bud."
                name="building_number"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Numer budynku jest wymagany!",
                  },
                  {
                    pattern: building_number_regexp,
                    message:
                      "Pole zawiera niedozwolone znaki!!",
                  },
                ]}
              >
                <Input
                  placeholder="Nr bud."
                  maxLength={10}
                />
              </Form.Item>
            </Col>
            <Col xs={6}>
              <Form.Item
                label="Nr miesz."
                name="apartment_number"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message:
                      "Numer mieszkania jest wymagany, w przypadku domu jednorodzinnego proszę wpisać numer budynku",
                  },
                  {
                    pattern: building_number_regexp,
                    message:
                      "Pole zawiera niedozwolone znaki!!",
                  },
                ]}
              >
                <Input
                  placeholder="Nr miesz."
                  maxLength={10}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={12}>
              <Form.Item
                label="Miasto"
                name="city"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Miasto jest wymagane!",
                  },
                  {
                    pattern: name_regexp,
                    message:
                      "Pole zawiera niedozolone znaki!",
                  },
                ]}
              >
                <Input
                  placeholder="Miasto"
                  maxLength={60}
                />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                label="Kod pocztowy"
                name="post_code"
                required
                hasFeedback
                rules={[
                  () => ({
                    validator(_, value) {
                      if (!value) {
                        return Promise.reject(
                          new Error(
                            "Kod pocztowy wymagany!"
                          )
                        );
                      }
                      if (value.includes("_")) {
                        return Promise.reject(
                          new Error(
                            "Kod pocztowy niepoprawny!"
                          )
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <MaskedInput mask="00-000" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={12}>
              <Form.Item
                label="NIP"
                name="nip"
                hasFeedback
                rules={[
                  {
                    pattern: nip_regexp,
                    message: "Niepoprawny NIP",
                  },
                ]}
              >
                <MaskedInput mask="0000000000" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default CreateClientModal;
