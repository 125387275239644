import EllipsisText from "components/EllipsisText";
import { Link } from "react-router-dom";

const columns = [
  {
    title: "Imię",
    dataIndex: "first_name",
    sortable: true,
    searchable: true,
    search_method: "matches",
    render: (_, { id, first_name }) => (
      <Link to={`/worker_invitations/${id}`}>
        <EllipsisText text={first_name} limit={20} />
      </Link>
    ),
  },
  {
    title: "Nazwisko",
    dataIndex: "last_name",
    sortable: true,
    searchable: true,
    search_method: "matches",
    render: (_, { id, last_name }) => (
      <Link to={`/worker_invitations/${id}`}>
        <EllipsisText text={last_name} limit={20} />
      </Link>
    ),
  },
  {
    title: "E-mail",
    dataIndex: "email",
    sortable: true,
    searchable: true,
    search_method: "matches",
    render: (_, { id, email }) => (
      <Link to={`/worker_invitations/${id}`}>
        <EllipsisText text={email} limit={50} />
      </Link>
    ),
  },
];

export default columns;
