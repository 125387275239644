import { notification } from "antd";
import { api } from "helpers/api";
import useGet from "hooks/useGet";
import React, { useMemo, useContext } from "react";

const NotificationContext = React.createContext();

function NotificationProvider({ children }) {
  const { is_loading, data, meta, refetch } = useGet({
    path: "/user_notifications",
    query: ["user_notifications"],
    search: {
      per_page: 100,
    },
  });

  const markAsRead = async (id) => {
    try {
      await api.put(`/user_notifications/${id}`, {
        read: true,
      });
      refetch();
    } catch (error) {
      notification.error({
        message: "Nie udało się potwierdzić powiadomienia",
      });
    }
  };

  const markAllAsRead = async () => {
    try {
      await api.post(
        `/user_notifications/mark_all_as_read`,
        {
          ids: data.map(({ id }) => id),
        }
      );
      refetch();
    } catch (error) {
      notification.error({
        message: "Nie udało się potwierdzić wszystkich powiadomień",
      });
    }
  };

  const value = useMemo(
    () => ({
      is_loading,
      data,
      meta,
      refetch,
      markAsRead,
      markAllAsRead,
    }),
    // eslint-disable-next-line
    [is_loading, data, meta]
  );

  return (
    <NotificationContext.Provider value={value}>
      {children}
    </NotificationContext.Provider>
  );
}

export const useNotification = () =>
  useContext(NotificationContext);

export default NotificationProvider;
