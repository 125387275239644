const alert_kinds = [
  {
    label: "Udana próba logowania",
    value: "sign_in_success",
  },
  {
    label: "Nieudana próba logowania",
    value: "sign_in_dailure",
  },
  { label: "Konto nieaktywne", value: "inactive_account" },
  {
    label: "Urządzenie nieaktywne",
    value: "inactive_device",
  },
  {
    label:
      "Próba rejestracji spoza listy zaproszonych użytkowników",
    value: "user_registration_without_invitation",
  },
  {
    label:
      "Próba logowania spoza listy userów. Dodano email na czarną liste",
    value: "blacklisted_email",
  },
];

export const filter_alert_kinds = [
  { label: "Udana próba logowania", value: 0 },
  { label: "Nieudana próba logowania", value: 1 },
  { label: "Konto nieaktywne", value: 2 },
  { label: "Urzadzenie nieaktywne", value: 3 },
  {
    label:
      "Próba rejestracji spoza listy zaproszonych użytkowników",
    value: 4,
  },
  {
    label:
      "Próba logowania spoza listy userów. Dodano email na czarną liste",
    value: 5,
  },
];

export const getAlertKind = (val) =>
  alert_kinds.find(({ value }) => value === val)?.label ||
  "-";

export default alert_kinds;
