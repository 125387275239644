import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";
import React from "react";

const PageFailure = ({ title, button_text, path = "/" }) => {
  const navigate = useNavigate();
  return (
    <Result
      status="error"
      title="Błąd"
      subTitle={title}
      extra={[
        <Button key="back" onClick={() => navigate(path)}>
          {button_text}
        </Button>,
      ]}
    />
  );
};

export default PageFailure;
