import CalendarProvider from "context/providers/calendar";
import IssueDayCalendar from "./components/IssueDayCalendar";
import IssueMonthCalendar from "./components/IssueMonthCalendar";

const Calendar = () => (
  <CalendarProvider>
    <IssueMonthCalendar />
    <IssueDayCalendar />
  </CalendarProvider>
);

export default Calendar;
