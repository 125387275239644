import React, { useState } from "react";
import { Modal, Input, Typography, Select } from "antd";
import { task_status_color_options } from "helpers/getTaskStatusColor";

import useUpdate from "hooks/useUpdate";

const EditTask = ({
  id,
  refetch,
  is_important,
  initial_values,
  handleClose,
}) => {
  const [content, setContent] = useState(
    initial_values.content
  );
  const [status_color, setStatusColor] = useState(
    initial_values.status_color
  );

  const { is_saving, updateAsync } = useUpdate({
    path: `/tasks/${id}`,
    onSuccess: refetch,
  });

  const is_dirty =
    initial_values.content !== content ||
    initial_values.status_color !== status_color;

  const handleOk = async () => {
    try {
      await updateAsync({
        content,
        status_color,
      });
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      maskClosable={false}
      width={700}
      title={`Edytuj ${
        is_important ? "priorytet" : "zadanie poboczne"
      }`}
      open={true}
      onOk={handleOk}
      onCancel={handleClose}
      cancelText="Anuluj"
      okText="Zapisz"
      okButtonProps={{
        disabled: !content || is_saving || !is_dirty,
        loading: is_saving,
      }}
    >
      <Input.TextArea
        value={content}
        style={{ marginBottom: 20 }}
        onChange={({ target: { value } }) =>
          setContent(value)
        }
        rows={7}
        maxLength={400}
        showCount={true}
      />
      <Typography style={{ marginBottom: 5 }}>
        <Typography.Text>Wybierz kolor</Typography.Text>
      </Typography>
      <Select
        options={task_status_color_options}
        value={status_color}
        onChange={(a) => setStatusColor(a)}
        style={{ width: 200 }}
      />
    </Modal>
  );
};

export default EditTask;
