import { Card, Descriptions } from "antd";
import dayjs from "dayjs";
import TimeAgo from "react-timeago";
import polishStrings from "react-timeago/lib/language-strings/pl";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";

const formatter = buildFormatter(polishStrings);

const calculateDuration = (duration) => {
  if (!duration) return "";

  if (duration > 86_400) {
    const round_duration_in_days = Math.ceil(
      duration / 86_400
    );
    return `${round_duration_in_days} dni`;
  }

  if (duration < 86_400 && duration >= 3_600) {
    //doba
    const round_duration_in_hours = Math.ceil(
      duration / 3_600
    );
    return `${round_duration_in_hours}h`;
  }

  if (duration < 3_600 && duration >= 60) {
    //godzina
    const round_duration_in_minutes = Math.ceil(
      duration / 60
    );
    return `${round_duration_in_minutes}min`;
  }

  if (duration < 60) {
    const round_duration_in_seconds = Math.ceil(duration);
    return `${round_duration_in_seconds}s`;
  }
};

const IssueInfo = ({ data }) => (
  <Card style={{ marginBottom: 24 }}>
    <Descriptions
      bordered
      size="small"
      items={[
        {
          label: "Idenryfikator",
          children: data?.identifier,
          span: 1,
        },
        {
          label: "Data utworzenia",
          children: data?.created_at
            ? dayjs(data.created_at).format(
                "DD-MM-YYYY HH:mm"
              )
            : "-",
          span: 1,
        },
        {
          label: "Data umówienia",
          children: data?.service_date ? (
            <>
              {`${dayjs(data.service_date).format(
                "DD-MM-YYYY HH:mm"
              )} - ${dayjs(
                data.service_date_delayed
              ).format("HH:mm")}`}
              {" - "}
              <strong>
                <TimeAgo
                  date={data.service_date}
                  formatter={formatter}
                />
              </strong>
            </>
          ) : (
            "-"
          ),
          span: 1,
        },
        {
          label: "Data przypisania do pracownika",
          children: data?.assigned_at
            ? dayjs(data.assigned_at).format(
                "DD-MM-YYYY HH:mm"
              )
            : "-",
          span: 1,
        },
        {
          label: "Data rozpoczęcia",
          children: data?.started_at
            ? dayjs(data.started_at).format(
                "DD-MM-YYYY HH:mm"
              )
            : "-",
          span: 1,
        },
        {
          label: "Data zakończenia (czas trwania)",
          children: data?.completed_at ? (
            <>
              {dayjs(data.completed_at).format(
                "DD-MM-YYYY HH:mm"
              )}{" "}
              -{" "}
              <strong>
                ({calculateDuration(data.duration)})
              </strong>
            </>
          ) : (
            "-"
          ),
          span: 1,
        },
      ]}
    />
  </Card>
);

export default IssueInfo;
