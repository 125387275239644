import { notification } from "antd";
import { api, handleErrors } from "helpers/api";
import useGet from "hooks/useGet";
import React, {
  useMemo,
  useContext,
  useState,
} from "react";
import { useWorkers } from "./workers";

const IssuesContext = React.createContext();

const getSearchKey = (search, search_type) => {
  if (!search) {
    return {};
  }
  if (!search_type) {
    return {
      "q[identifier_or_client_phone_or_client_first_name_or_client_last_name_or_client_city_or_client_post_code_or_client_street_matches]": `%${search}%`,
    };
  }

  return { [`q[${search_type}_matches]`]: `%${search}%` };
};

function IssuesProvider({ children }) {
  const [unassigned_page, setUnassignedPage] = useState(1);
  const [preview_id, setPreviewId] = useState(null);
  const [is_urgent, setIsUrgent] = useState(false);
  const [worker_id, setWorkerId] = useState(null);
  const [is_assigning, setAssigning] = useState(false);
  const [unassigned_issues_ids, setUnassignedIssuesIds] =
    useState([]);
  const [assigned_issues_ids, setAssignedIssues] = useState(
    []
  );

  const [unassigned_search, setUnassignedSearch] =
    useState("");
  const [unassigned_search_type, setUnassignedSearchType] =
    useState(null);

  const [assigned_search, setAssignedSearch] = useState("");
  const [assigned_search_type, setAssignedSearchType] =
    useState(null);

  const { data: workers_list, workers_list_options } =
    useWorkers();

  const toggleUnassignedIssuesIds = (id) => {
    if (unassigned_issues_ids.includes(id)) {
      setUnassignedIssuesIds(
        unassigned_issues_ids.filter((el) => el !== id)
      );
    } else {
      setUnassignedIssuesIds([
        ...unassigned_issues_ids,
        id,
      ]);
    }
  };

  const toggleAssignedIssuesIds = (id) => {
    if (assigned_issues_ids.includes(id)) {
      setAssignedIssues(
        assigned_issues_ids.filter((el) => el !== id)
      );
    } else {
      setAssignedIssues([...assigned_issues_ids, id]);
    }
  };

  const is_enabled = Boolean(worker_id);

  const {
    is_loading: is_loading_unassigned,
    data: unassigned = [],
    meta: unassigned_meta,
    refetch: refetchUnassigned,
  } = useGet({
    query: [
      "unassigned_issues",
      unassigned_search,
      unassigned_search_type,
      is_urgent,
      unassigned_page,
    ],
    path: "/issues/unassigned",
    onSuccess: () => setUnassignedIssuesIds([]),
    search: {
      sort: "created_at__desc",
      per_page: "20",
      page: unassigned_page,
      "q[urgent_eq]": is_urgent ? "true" : undefined,
      ...getSearchKey(
        unassigned_search,
        unassigned_search_type
      ),
    },
    options: {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    },
  });

  const {
    is_loading: is_loading_worker_issues,
    data: worker_issues = [],
    refetch: refetchWorkerIssues,
  } = useGet({
    query: [
      "worker_issues",
      worker_id,
      assigned_search,
      assigned_search_type,
    ],
    path: "/issues/assigned_to_worker",
    onSuccess: () => setAssignedIssues([]),
    search: {
      worker_id,
      ...getSearchKey(
        assigned_search,
        assigned_search_type
      ),
    },
    options: {
      enabled: is_enabled,
      refetchOnWindowFocus: false,
    },
  });

  const assignIssuesToWorker = async () => {
    try {
      setAssigning(true);
      await api.post("/issues/bulk_assign", {
        worker_id,
        ids: unassigned_issues_ids,
      });
      setUnassignedIssuesIds([]);
      refetchUnassigned();
      refetchWorkerIssues();
    } catch (error) {
      notification.error({
        message: handleErrors(error),
      });
    } finally {
      setAssigning(false);
    }
  };

  const cancelIssues = async () => {
    try {
      setAssigning(true);
      await api.post("/issues/bulk_cancel", {
        ids: unassigned_issues_ids,
      });
      setUnassignedIssuesIds([]);
      refetchUnassigned();
    } catch (error) {
      notification.error({
        message: handleErrors(error),
      });
    } finally {
      setAssigning(false);
    }
  };

  const unassignIssuesFromWorker = async () => {
    try {
      setAssigning(true);
      await api.post("/issues/bulk_unassign", {
        ids: assigned_issues_ids,
      });
      setAssignedIssues([]);
      refetchUnassigned();
      refetchWorkerIssues();
    } catch (error) {
      notification.error({
        message: error?.message || "Błąd",
      });
    } finally {
      setAssigning(false);
    }
  };

  const refetchAll = () => {
    refetchUnassigned();
    if (is_enabled) {
      refetchWorkerIssues();
    }
  };

  const unassigned_count = unassigned_meta?.total_count;

  const active_worker = workers_list?.find(
    ({ id }) => id === worker_id
  );

  const value = useMemo(() => {
    return {
      worker_id,
      is_assigning,
      is_loading_unassigned,
      is_loading_worker_issues,
      unassigned,
      unassigned_count,
      unassigned_meta,
      unassigned_page,
      setUnassignedPage,
      worker_issues,
      workers_list_options,
      is_enabled,
      setWorkerId,
      unassigned_issues_ids,
      assigned_issues_ids,
      toggleUnassignedIssuesIds,
      toggleAssignedIssuesIds,
      assignIssuesToWorker,
      unassignIssuesFromWorker,
      unassigned_search,
      assigned_search,
      setUnassignedSearch,
      setAssignedSearch,
      is_urgent,
      setIsUrgent,
      active_worker,
      setUnassignedIssuesIds,
      preview_id,
      setPreviewId,
      refetchAll,
      setUnassignedSearchType,
      unassigned_search_type,
      setAssignedSearchType,
      assigned_search_type,
      cancelIssues
    };
    // eslint-disable-next-line
  }, [
    worker_id,
    is_urgent,
    is_assigning,
    is_loading_unassigned,
    is_loading_worker_issues,
    unassigned,
    is_enabled,
    worker_issues,
    unassigned_issues_ids,
    assigned_issues_ids,
    unassigned_search,
    assigned_search,
    preview_id,
    unassigned_page,
    unassigned_search_type,
    assigned_search_type,
  ]);

  return (
    <IssuesContext.Provider value={value}>
      {children}
    </IssuesContext.Provider>
  );
}

const useIssues = () => useContext(IssuesContext);
export { useIssues };
export default IssuesProvider;
