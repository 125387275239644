import { Button, Card, Space, notification } from "antd";
import { useAuth } from "context/providers/auth";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useIdleTimer } from "react-idle-timer";
import OTPInput from "react-otp-input";

const timeout = 15 * 60 * 1_000;
const incorrect_limit = 3;

const ActivityProtect = ({ children }) => {
  const { handleLogout, verifyPin } = useAuth();

  const [
    {
      is_view_blocked,
      incorrect_count: incorrect_count_cookie,
    },
    setCookie,
  ] = useCookies(["is_view_blocked", "incorrect_count"]);

  const [code, setCode] = useState("");
  const [is_loading, setLoading] = useState(false);

  const [incorrect_count, setIncorrectCount] = useState(
    incorrect_count_cookie || 0
  );

  const [is_blocked, setBlocked] = useState(
    is_view_blocked || false
  );

  const onIdle = () => setBlocked(true);

  useEffect(() => {
    setCookie("is_view_blocked", is_blocked);
    // eslint-disable-next-line
  }, [is_blocked]);

  useEffect(() => {
    setCookie("incorrect_count", incorrect_count);
    // eslint-disable-next-line
  }, [incorrect_count]);

  const handleVerify = async () => {
    try {
      setLoading(true);
      await verifyPin(code);
      setCode("");
      setIncorrectCount(0);
      setBlocked(false);
    } catch (error) {
      const count = incorrect_count + 1;
      if (count >= incorrect_limit) {
        setBlocked(false);
        setIncorrectCount(0);
        handleLogout();
      } else {
        setIncorrectCount(count);
        notification.error({
          message: `Kod nieprawidłowy. Pozostało prób: ${
            incorrect_limit - count
          }`,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  useIdleTimer({
    onIdle,
    timeout,
    throttle: 1000,
    disabled: is_blocked,
  });

  if (is_blocked) {
    return (
      <div className="otp-panel">
        <Card
          title="Wprowadź kod blokady"
          style={{ width: 440, textAlign: "center" }}
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <Space direction="vertical" size="large">
              <OTPInput
                shouldAutoFocus
                value={code}
                onChange={setCode}
                numInputs={6}
                inputStyle={{
                  margin: "0 10px",
                  width: 40,
                  height: 40,
                  borderRadius: 5,
                  border: "1px solid #d9d9d9",
                  textAlign: "center",
                  fontSize: 20,
                  lineHeight: "30px",
                }}
                inputType="password"
                renderSeparator={<span>-</span>}
                renderInput={(props) => (
                  <input {...props} />
                )}
              />
              <Button
                disabled={code.length !== 6}
                loading={is_loading}
                type="primary"
                htmlType="submit"
                onClick={handleVerify}
              >
                Zweryfikuj kod
              </Button>
            </Space>
          </form>
        </Card>
      </div>
    );
  }

  return children;
};

export default ActivityProtect;
