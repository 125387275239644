import { useState } from "react";
import { Button, Card, List } from "antd";
import AddCommentModal from "./components/AddCommentModal";
import Item from "./components/Item";
import { PlusOutlined } from "@ant-design/icons";

const Comments = ({
  issue_id,
  data,
  refetch,
  gray = false,
}) => {
  const [
    is_open_add_comment_modal,
    setIsOpenAddCommentModal,
  ] = useState(false);

  return (
    <Card
      headStyle={
        gray
          ? {
              backgroundColor: "rgba(0, 0, 0, 0.02)",
            }
          : {}
      }
      title={`Komentarze zgłoszenia ${
        data?.length > 0 ? `(${data.length})` : ""
      }`}
      style={{ marginBottom: 24 }}
      bodyStyle={{
        padding: "0 24px",
        maxHeight: 320,
        overflowY: "scroll",
      }}
      extra={
        <Button
          type="primary"
          onClick={(e) => setIsOpenAddCommentModal(true)}
          icon={<PlusOutlined />}
        />
      }
    >
      {is_open_add_comment_modal && (
        <AddCommentModal
          issue_id={issue_id}
          refetch={refetch}
          handleClose={() =>
            setIsOpenAddCommentModal(false)
          }
        />
      )}
      <List
        itemLayout="horizontal"
        dataSource={data}
        renderItem={(item) => (
          <Item {...item} refetch={refetch} />
        )}
      />
    </Card>
  );
};

export default Comments;
