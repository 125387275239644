import { Input } from "antd";

const ListInput = ({
  title,
  value,
  onChange,
  onPressEnter,
  onBlur,
  search_type,
  regexp,
}) => {
  const handleChange = (e) => {
    const { value: inputValue } = e.target;
    if (regexp) {
      if (regexp.test(inputValue) || inputValue === "") {
        onChange(inputValue);
      }
    } else {
      onChange(inputValue);
    }
  };
  
  return (
    <Input
      placeholder={title}
      value={value}
      onChange={handleChange}
      onPressEnter={onPressEnter}
      onBlur={onBlur}
      type={search_type}
      maxLength={128}
    />
  );
};
export default ListInput;
