import React from "react";

import MainLayout from "components/MainLayout";
import MainList from "components/MainList";

import columns from "./columns";

const AlertsList = () => {
  return (
    <MainLayout breadcrumbs={[{ name: "Próby dostepu" }]}>
      <MainList
        columns={columns}
        query_name="alerts"
        query_path="/alerts"
        query_context="index"
        with_navigation={false}
      />
    </MainLayout>
  );
};

export default AlertsList;
