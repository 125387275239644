import { Card, List } from "antd";
import Item from "./components/Item";

const WorkerDevices = ({ data }) => (
  <Card
    style={{ marginTop: 24 }}
    title="Pracownicy z dostępem do urządzenia"
    bordered={false}
    bodyStyle={{ padding: 0 }}
  >
    <List
      dataSource={data?.sort((a, b) => a.id - b.id)}
      renderItem={(props) => <Item {...props} />}
    />
  </Card>
);

export default WorkerDevices;
