import React, { useMemo, useContext } from "react";
import { ActionCableProvider } from "react-actioncable-provider";
import { useCookies } from "react-cookie";
import ActionCable from "actioncable";
import { ws_url } from "helpers/api";
import NotificationsChannel from "components/NotificationsChannel";

const CableContext = React.createContext();

function CableProvider({ children }) {
  const [{ access_token }] = useCookies(["access_token"]);

  const cable = useMemo(() => {
    if (access_token) {
      return ActionCable.createConsumer(
        `${ws_url}?token=${access_token}`
      );
    }
  }, [access_token]);

  return (
    <ActionCableProvider cable={cable}>
      <NotificationsChannel />
      <CableContext.Provider value={cable}>
        {children}
      </CableContext.Provider>
    </ActionCableProvider>
  );
}

export const useCable = () => useContext(CableContext);

export default CableProvider;
