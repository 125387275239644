import { Select } from "antd";

const ListSelect = ({
  title,
  value,
  onChange,
  options,
}) => (
  <Select
    allowClear
    style={{
      width: "100%",
      display: "block",
    }}
    placeholder={title}
    value={value}
    onChange={onChange}
    options={options}
  />
);

export default ListSelect;
