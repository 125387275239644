import React, { useState } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  ColorPicker,
} from "antd";
import { MaskedInput } from "antd-mask-input";

import useCreate from "hooks/useCreate";
import useFormErrors from "hooks/useFormErrors";
import { useQueryClient } from "@tanstack/react-query";
import { name_regexp } from "helpers/regexp";
import { useNavigate } from "react-router-dom";

const CreateWorkerInvitationModal = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [is_open_create_modal, setOpenCreateModal] =
    useState(false);

  const [form] = Form.useForm();

  const { pristine, has_error, onFieldsChange } =
    useFormErrors();

  const { is_saving, create } = useCreate({
    path: "/worker_invitations",
    form,
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: ["worker_invitations"],
      });
      navigate("/worker_invitations");
    },
  });

  const handleOk = () => form.submit();
  const handleClose = () => setOpenCreateModal(false);

  return (
    <>
      <Button
        type="primary"
        htmlType="button"
        onClick={() => setOpenCreateModal(true)}
      >
        Dodaj pracownika
      </Button>
      <Modal
        maskClosable={false}
        title="Dodaj pracownika"
        open={is_open_create_modal}
        onOk={handleOk}
        onCancel={handleClose}
        cancelText="Anuluj"
        okText="Dodaj"
        okButtonProps={{
          disabled: pristine || has_error,
          loading: is_saving,
        }}
      >
        <Form
          form={form}
          layout="vertical"
          onFieldsChange={onFieldsChange}
          initialValues={{
            first_name: "",
            last_name: "",
            email: "",
            color: "#1677FF",
          }}
          onFinish={async (values) => {
            try {
              await create(values);
              form.resetFields();
              handleClose();
            } catch (error) {
              console.log(error);
            }
          }}
        >
          <Form.Item
            label="Imię"
            name="first_name"
            hasFeedback
            rules={[
              {
                required: true,
                message: "Imię jest wymagane!",
              },
              {
                pattern: name_regexp,
                message: "Pole zawiera niedowolone znaki!",
              },
            ]}
          >
            <Input placeholder="Imię" maxLength={30} />
          </Form.Item>
          <Form.Item
            label="Nazwisko"
            name="last_name"
            hasFeedback
            rules={[
              {
                required: true,
                message: "Nazwisko jest wymagane!",
              },
              {
                pattern: name_regexp,
                message: "Pole zawiera niedowolone znaki!",
              },
            ]}
          >
            <Input placeholder="Nazwisko" maxLength={30} />
          </Form.Item>
          <Form.Item
            label="E-mail"
            name="email"
            hasFeedback
            rules={[
              {
                required: true,
                type: "email",
                message: "E-mail jest niepoprawny!",
              },
            ]}
          >
            <Input placeholder="E-mail" maxLength={60} />
          </Form.Item>
          <Form.Item
            label="Telefon"
            name="phone"
            required
            hasFeedback
            rules={[
              () => ({
                validator(_, value) {
                  if (!value) {
                    return Promise.reject(
                      new Error("Numer telefonu wymagany!")
                    );
                  }
                  if (value.includes("_")) {
                    return Promise.reject(
                      new Error(
                        "Numer telefonu niepoprawny!"
                      )
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <MaskedInput mask="000000000" />
          </Form.Item>
          <Form.Item name="color" label="Wybierz kolor">
            <ColorPicker
              onChangeComplete={(value) =>
                form.setFieldValue(
                  "color",
                  value.toHexString()
                )
              }
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default CreateWorkerInvitationModal;
