import { useState } from "react";
import {
  Card,
  Col,
  Row,
  Space,
  Input,
  Switch,
  Typography,
  Tooltip,
  Tag,
  Button,
  Dropdown,
  Pagination,
  Divider,
  Empty,
  Select,
} from "antd";
import { useIssues } from "context/providers/issues";
import {
  CheckOutlined,
  CommentOutlined,
  SettingOutlined,
  FileSearchOutlined,
  CloseOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import { getIssueKind } from "helpers/options/issue_kinds";
import formatPhone from "helpers/phone_formatter";
import { Link } from "react-router-dom";
import { useAuth } from "context/providers/auth";
import ModalConfirm from "components/ModalConfirm";
import EllipsisText from "components/EllipsisText";
import TimeAgo from "react-timeago";
import polishStrings from "react-timeago/lib/language-strings/pl";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";

const formatter = buildFormatter(polishStrings);

const UnassignedList = () => {
  const {
    is_enabled,
    is_assigning,
    unassigned,
    assigned_issues_ids,
    unassigned_issues_ids,
    toggleUnassignedIssuesIds,
    assignIssuesToWorker,
    unassigned_count,
    setUnassignedSearch,
    is_urgent,
    setIsUrgent,
    is_loading_unassigned,
    active_worker,
    setUnassignedIssuesIds,
    setPreviewId,
    unassigned_meta,
    setUnassignedPage,
    unassigned_search_type,
    setUnassignedSearchType,
    cancelIssues,
  } = useIssues();

  const {
    state: {
      user: { id: current_user_id },
    },
  } = useAuth();

  const [search_value, setSearchValue] = useState("");

  const unassigned_ids =
    unassigned?.length > 0
      ? unassigned.map(({ id }) => id)
      : [];

  return (
    <div className="issue-management__col">
      <Card
        headStyle={{ padding: "22.5px 25px" }}
        title={
          <Space>
            {`Nowe zgłoszenia (${unassigned_count || 0})`}
            <Typography.Text
              type={is_urgent ? "primary" : "secondary"}
            >
              Pilne
            </Typography.Text>
            <Switch
              loading={is_loading_unassigned}
              checked={is_urgent}
              unCheckedChildren="Nie"
              checkedChildren="Tak"
              onChange={(val) => [
                setIsUrgent(val),
                setUnassignedPage(1),
              ]}
            />
          </Space>
        }
      >
        <div className="buttons-group">
          <ModalConfirm
            button_text="Anuluj zgłoszenia"
            button_props={{ danger: true }}
            handleConfirm={cancelIssues}
            disabled={!unassigned_issues_ids.length}
            loading={is_assigning}
            text={
              <>
                <p className="m-0 m-b-5">
                  Czy na pewno chcesz{" "}
                  <strong>anulować</strong> poniższe
                  zgłoszenia?
                </p>
                {unassigned
                  ?.filter(({ id }) =>
                    unassigned_issues_ids.includes(id)
                  )
                  ?.map(({ identifier }) => (
                    <p className="m-0">
                      <strong>{identifier}</strong>
                    </p>
                  ))}
                <div
                  style={{
                    textAlign: "right",
                    margin: "20px 40px 20px 0",
                  }}
                >
                  <CloseCircleOutlined
                    style={{ color: "red", fontSize: 40 }}
                  />
                </div>
              </>
            }
          />

          <Button
            disabled={
              unassigned_issues_ids.length ===
              unassigned_ids.length
            }
            onClick={() =>
              setUnassignedIssuesIds(unassigned_ids)
            }
            type="primary"
            htmlType="button"
          >
            Zaznacz wszystkie
          </Button>
          <Button
            disabled={!unassigned_issues_ids.length}
            onClick={() => setUnassignedIssuesIds([])}
            type="primary"
            htmlType="button"
          >
            Odznacz wszystkie
          </Button>
          <ModalConfirm
            button_text="Przypisz"
            button_icon={<CheckOutlined />}
            button_size="large"
            button_tooltip={
              !is_enabled
                ? "Wybierz pracownika aby przypisać"
                : null
            }
            button_style={{
              backgroundColor:
                is_enabled && unassigned_issues_ids.length
                  ? "#36b03b"
                  : null,
              height: 38,
              padding: "2px 14px",
            }}
            handleConfirm={assignIssuesToWorker}
            disabled={
              !is_enabled || !unassigned_issues_ids.length
            }
            loading={is_assigning}
            text={
              <>
                <p className="m-0 m-b-5">
                  Czy na pewno chcesz przypisać zgłoszenia:
                </p>
                {unassigned
                  ?.filter(({ id }) =>
                    unassigned_issues_ids.includes(id)
                  )
                  ?.map(({ identifier }) => (
                    <p className="m-0">
                      <strong>{identifier}</strong>
                    </p>
                  ))}
                <p className="m-0 m-b-5">do pracownika</p>
                <p className="m-0">
                  <strong>
                    {active_worker?.full_name}?
                  </strong>
                </p>
                <div
                  style={{
                    textAlign: "right",
                    margin: "20px 40px 20px 0",
                  }}
                >
                  <CheckCircleOutlined
                    style={{ color: "green", fontSize: 40 }}
                  />
                </div>
              </>
            }
          />
        </div>
        <Space.Compact
          style={{ width: "100%", marginBottom: 20 }}
        >
          <Select
            style={{ width: "200px" }}
            onChange={setUnassignedSearchType}
            value={unassigned_search_type}
          >
            <Select.Option value={null}>
              Wszystkie
            </Select.Option>
            <Select.Option value="identifier">
              Identyfikator
            </Select.Option>
            <Select.Option value="client_phone">
              Telefon
            </Select.Option>
            <Select.Option value="client_first_name">
              Imię
            </Select.Option>
            <Select.Option value="client_last_name">
              Nazwisko
            </Select.Option>
            <Select.Option value="client_street">
              Ulica
            </Select.Option>
            <Select.Option value="client_city">
              Miasto
            </Select.Option>
            <Select.Option value="client_post_code">
              Kod pocztowy
            </Select.Option>
          </Select>
          <Input.Search
            style={{ width: "100%" }}
            value={search_value}
            placeholder="Szukaj..."
            onChange={({ target: { value } }) =>
              setSearchValue(value)
            }
            onSearch={(value) => [
              setUnassignedSearch(value),
              setUnassignedPage(1),
            ]}
            maxLength={128}
          />
          <Button
            icon={<CloseOutlined />}
            onClick={() => {
              setUnassignedSearch("");
              setUnassignedPage(1);
              setSearchValue("");
            }}
          />
        </Space.Compact>
        {unassigned.length > 0 ? (
          <>
            <Row gutter={[12, 12]}>
              {unassigned.map((issue) => {
                const issue_visitors =
                  issue?.issue_visitors.map(
                    ({ user }) => user.id
                  ) || [];

                const visited =
                  issue_visitors.includes(current_user_id);
                return (
                  <Col
                    xs={24}
                    md={12}
                    lg={12}
                    xl={12}
                    key={issue?.id}
                  >
                    <Card
                      onClick={() =>
                        assigned_issues_ids.length === 0 &&
                        toggleUnassignedIssuesIds(issue.id)
                      }
                      className={`issue ${issue.status}  ${
                        unassigned_issues_ids.includes(
                          issue.id
                        )
                          ? "active"
                          : ""
                      }`}
                      extra={
                        <Space size={1}>
                          {issue?.urgent && (
                            <Tag color="#cd201f">Pilne</Tag>
                          )}
                          {issue?.issue_comments_count >
                            0 && (
                            <Tooltip
                              title={`${issue.issue_comments_count} komentarze`}
                            >
                              <CommentOutlined
                                style={{
                                  marginTop: 7,
                                  color: "#fff",
                                }}
                              />{" "}
                              <Typography.Text
                                style={{
                                  color: "#fff",
                                }}
                              >
                                (
                                {issue.issue_comments_count}
                                )
                              </Typography.Text>
                            </Tooltip>
                          )}
                          {visited && (
                            <Tooltip title="Odczytane">
                              <CheckOutlined
                                style={{
                                  color: "#fff",
                                }}
                              />
                            </Tooltip>
                          )}
                        </Space>
                      }
                      size="small"
                      hoverable={is_enabled}
                      type="inner"
                      title={
                        <EllipsisText
                          text={issue?.identifier}
                          limit={50}
                        />
                      }
                    >
                      <p
                        style={{
                          textTransform: "uppercase",
                        }}
                      >
                        <strong>
                          <EllipsisText
                            text={issue?.client_address}
                            limit={100}
                          />
                        </strong>
                      </p>
                      <p>
                        Tel:{" "}
                        <strong>
                          {formatPhone(issue?.client_phone)}
                        </strong>
                      </p>
                      <p>
                        <strong>
                          <EllipsisText
                            text={issue?.client_name}
                            limit={50}
                          />
                        </strong>
                      </p>
                      <p>
                        Data umówienia:{" "}
                        {issue?.service_date ? (
                          <strong>
                            {`${dayjs(
                              issue.service_date
                            ).format(
                              "DD-MM-YYYY HH:mm"
                            )} - ${dayjs(
                              issue.service_date_delayed
                            ).format("HH:mm")}`}
                            {" - "}

                            <TimeAgo
                              date={issue.service_date}
                              formatter={formatter}
                            />
                          </strong>
                        ) : (
                          "-"
                        )}
                      </p>
                      <p>
                        Data zgłoszania:{" "}
                        <strong>
                          {issue.created_at
                            ? dayjs(
                                issue.created_at
                              ).format("DD-MM-YYYY HH:mm")
                            : "-"}
                        </strong>
                      </p>
                      <p>
                        Rodzaj:{" "}
                        <strong>
                          {getIssueKind(issue.kind)}
                        </strong>
                      </p>
                      <Dropdown
                        menu={{
                          items: [
                            {
                              key: "1",
                              label: (
                                <Link
                                  to={`/issues/${issue.id}`}
                                >
                                  Przejdź do edycji
                                </Link>
                              ),
                            },
                            {
                              key: "2",
                              label: (
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={`http://maps.google.com/?q=${issue.client_address}`}
                                >
                                  Przejdź do mapy
                                </a>
                              ),
                            },
                            {
                              key: "3",
                              label: (
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={`tel:${issue.client_phone}`}
                                >
                                  Zadzwoń{" "}
                                  {formatPhone(
                                    issue.client_phone
                                  )}
                                </a>
                              ),
                            },
                          ],
                        }}
                        placement="bottomRight"
                        arrow
                      >
                        <Button
                          className="btn-dropdown"
                          htmlType="button"
                          icon={<SettingOutlined />}
                          onClick={(e) =>
                            e.stopPropagation()
                          }
                        />
                      </Dropdown>
                      <Button
                        className="btn-preview"
                        htmlType="button"
                        onClick={(e) => {
                          e.stopPropagation();
                          setPreviewId(issue?.id);
                        }}
                        icon={<FileSearchOutlined />}
                      />
                    </Card>
                  </Col>
                );
              })}
            </Row>
            <Divider />
            <Row justify="center">
              <Pagination
                className="custom-pagination"
                simple
                onChange={(page) => {
                  setUnassignedPage(page);
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
                current={unassigned_meta?.current_page || 1}
                pageSize={unassigned_meta?.per_page || 15}
                total={unassigned_meta?.total_count || 0}
              />
            </Row>
          </>
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="Brak danych"
          />
        )}
      </Card>
    </div>
  );
};

export default UnassignedList;
