import React, { useState } from "react";
import { Modal, Input } from "antd";

import useUpdate from "hooks/useUpdate";

const EditCommentModal = ({
  handleClose,
  refetch,
  issue_comment_id,
  default_content,
}) => {
  const [content, setContent] = useState(default_content);

  const { is_saving, update } = useUpdate({
    path: `/issue_comments/${issue_comment_id}`,
    onSuccess: refetch,
  });

  const handleOk = async () => {
    try {
      await update({
        content,
      });
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      maskClosable={false}
      width={700}
      title="Aktualizuj komentarz"
      open={true}
      onOk={handleOk}
      onCancel={handleClose}
      cancelText="Anuluj"
      okText="Zapisz"
      okButtonProps={{
        disabled: !content || is_saving,
        loading: is_saving,
      }}
    >
      <Input.TextArea
        style={{ marginBottom: 20 }}
        defaultValue={content}
        onChange={({ target: { value } }) =>
          setContent(value)
        }
        rows={7}
        maxLength={400}
        showCount={true}
      />
    </Modal>
  );
};

export default EditCommentModal;
