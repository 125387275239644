import React from "react";
import { Timeline } from "antd";
import dayjs from "dayjs";

const Logs = ({ data = [] }) => (
  <Timeline
    style={{ maxWidth: "90%", margin: "0 auto" }}
    mode="left"
    items={data
      ?.sort(
        (a, b) =>
          new Date(b.created_at) - new Date(a.created_at)
      )
      ?.map(
        ({ content, is_important, user, created_at }) => ({
          color: is_important ? "red" : "blue",
          label: created_at
            ? dayjs(created_at).format("DD-MM-YYYY HH:mm")
            : "-",
          children: (
            <>
              <strong>{user?.full_name || "-"}</strong>
              <p>{content}</p>
            </>
          ),
        })
      )}
  />
);

export default Logs;
