import React from "react";

import MainLayout from "components/MainLayout";
import MainList from "components/MainList";

import columns from "./columns";
import WorkerTabs from "components/WorkerTabs";

const WorkerInvitationList = () => {
  return (
    <MainLayout
      breadcrumbs={[{ name: "Pracownicy zaproszeni" }]}
    >
      <WorkerTabs current="worker_invitations" />
      <MainList
        columns={columns}
        query_name="worker_invitations"
        query_path="/worker_invitations"
        table_path="/worker_invitations"
        query_context="index"
      />
    </MainLayout>
  );
};

export default WorkerInvitationList;
