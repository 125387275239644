import React, { useState } from "react";
import {
  Avatar,
  Badge,
  Button,
  List,
  Popover,
  Row,
  Tooltip,
  Typography,
} from "antd";
import {
  BellFilled,
  CheckOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { useNotification } from "context/providers/notifications";
import ModalConfirm from "components/ModalConfirm";

const getNotificationColor = (kind) => {
  switch (kind) {
    case "info":
      return "#1677ff";
    case "success":
      return "#52c41a";
    case "danger":
      return "#ff4d4f";
    default:
      return "";
  }
};

const getNotificationLink = (notification) => {
  switch (notification.notifiable_type) {
    case "Issue":
      return `/issues/${notification?.issue_id}`;
    case "User::Worker":
    case "WorkerPasswordReset":
      return `/workers/${notification?.worker_id}`;
    case "Note":
      return `/notes/${notification?.note_id}`;
    default:
      return "/";
  }
};

const Notifications = () => {
  const [open, setOpen] = useState(false);
  const { data, meta, markAsRead, markAllAsRead } =
    useNotification();

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  return (
    <Popover
      title={
        <Row justify="space-between">
          <Typography.Text>
            Powiadomienia ({meta?.total_count})
          </Typography.Text>
          {data?.length > 0 && (
            <ModalConfirm
              button_type="default"
              button_text="Usuń wyszystkie"
              onOpen={() => setOpen(false)}
              handleConfirm={markAllAsRead}
              text="Czy na pewno chcesz potwierdzić wszystkie notyfikacje?"
            />
          )}
        </Row>
      }
      content={
        <div
          style={{
            height: 400,
            width: 500,
            overflow: "auto",
          }}
        >
          <List
            dataSource={data}
            renderItem={({
              id,
              created_at,
              notification,
            }) => {
              const notification_link =
                getNotificationLink(notification);
              return (
                <List.Item
                  key={id}
                  className="notification-item"
                >
                  <Link
                    style={{ width: "100%" }}
                    to={notification_link}
                    onClick={() => setOpen(false)}
                  >
                    <List.Item.Meta
                      avatar={
                        <Avatar
                          icon={<BellFilled />}
                          style={{
                            backgroundColor:
                              getNotificationColor(
                                notification.kind
                              ),
                          }}
                        />
                      }
                      title={notification.content}
                      description={dayjs(created_at).format(
                        "DD-MM-YYYY HH:mm"
                      )}
                    />
                  </Link>
                  <Button
                    onClick={() => markAsRead(id)}
                    className="m-l-10 m-r-10"
                    icon={<CheckOutlined />}
                  />
                </List.Item>
              );
            }}
          />
        </div>
      }
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
    >
      <Badge
        size="small"
        count={data?.length}
        style={{
          borderColor: "transparent",
          lineHeight: " 20px",
          fontSize: "14px",
          height: 20,
          minWidth: 20,
        }}
      >
        <Tooltip title="Powiadomiena">
          <Button type="primary">
            <BellFilled />
          </Button>
        </Tooltip>
      </Badge>
    </Popover>
  );
};

export default Notifications;
