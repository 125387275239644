const note_kinds = [
  { label: "Notatka", value: "note" },
  { label: "Urlop", value: "holiday" },
];

export const filter_note_kinds = [
  { label: "Notatka", value: 0 },
  { label: "Urlop", value: 1 },
];

export const getNoteKind = (val) =>
  note_kinds.find(({ value }) => value === val)?.label ||
  "-";

export default note_kinds;
