import { useState } from "react";
import {
  Avatar,
  Card,
  Modal,
  Select,
  Space,
  Typography,
} from "antd";
import useUpdate from "hooks/useUpdate";
import ModalDelete from "components/ModalDelete";
import useCreate from "hooks/useCreate";
import { useWorkers } from "context/providers/workers";
import EllipsisText from "components/EllipsisText";

const WorkerAvatar = ({ worker }) => (
  <Space>
    <Avatar
      style={{
        backgroundColor: worker.color,
      }}
    >
      {worker.initials}
    </Avatar>
    <Typography.Text delete={worker.is_deleted}>
      <EllipsisText text={worker.full_name} limit={30} />
    </Typography.Text>
  </Space>
);

const IssueWorker = ({ data, refetch }) => {
  const [is_open, setOpen] = useState(null);
  const [worker_id, setWorkerId] = useState(null);

  const { data: workers_list, workers_list_options } =
    useWorkers();

  const { is_saving, update } = useUpdate({
    path: `/issues/${data?.id}`,
    onSuccess: refetch,
  });

  const {
    is_saving: is_saving_assign,
    create: assignWorker,
  } = useCreate({
    path: `/issues/${data?.id}/assign_to_worker`,
    onSuccess: refetch,
  });

  const is_ready = [
    undefined,
    "completed",
    "cancelled",
  ].includes(data?.status);

  return (
    <Card
      style={{ marginBottom: 24 }}
      title="Pracownik"
      extra={
        !is_ready && data?.worker?.id ? (
          <ModalDelete
            only_icon={true}
            text="przypisanie pracownika?"
            handleDelete={() => update({ worker_id: null })}
          />
        ) : null
      }
    >
      {is_open && (
        <Modal
          title="Potwierdzenie"
          open={is_open}
          onOk={() => {
            assignWorker({ worker_id });
            setWorkerId(null);
            setOpen(false);
          }}
          onCancel={() => {
            setWorkerId(null);
            setOpen(false);
          }}
          cancelText="Anuluj"
          okText="Potwierdzam"
        >
          Czy chcesz przypisać pracownika (
          {
            workers_list.find(({ id }) => id === worker_id)
              ?.full_name
          }
          ) do zgłoszenia?
        </Modal>
      )}
      {is_ready ? (
        data?.worker?.id ? (
          <WorkerAvatar worker={data.worker} />
        ) : (
          <Typography.Text>Brak</Typography.Text>
        )
      ) : data?.worker?.id ? (
        <WorkerAvatar worker={data.worker} />
      ) : (
        <Select
          style={{ minWidth: "200px" }}
          size="large"
          loading={is_saving_assign || is_saving}
          placeholder="Wybierz pracownika"
          optionFilterProp="children"
          onChange={(val) => {
            setWorkerId(val);
            setOpen(true);
          }}
          options={workers_list_options || []}
        />
      )}
    </Card>
  );
};

export default IssueWorker;
